import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef } from 'react';
import { api } from "../../../services/http.service";
import { env } from "../../../constants/global.constants";
import { GameInfo } from "../game.info";
import { gtmViewItem, useForceUpdate } from "../../../services/misc.functions";
import { auth, authSubject } from "../../../store/auth.store";
import { GameLogin } from "../game.login";
function RockPaperScissors() {
    var iframeContainerRef = useRef(null);
    var forceUpdate = useForceUpdate();
    useEffect(function () {
        var auth_subscriber = authSubject.subscribe(function () {
            forceUpdate();
        });
        return function () {
            auth_subscriber.unsubscribe();
        };
    }, []);
    useEffect(function () {
        if (auth.authentication && auth.member && auth.member.id) {
            gtmViewItem([{ code: "taskagitmakas", name: "Taş Kağıt Makas" }], 'Oyunlar');
            api.start("get", env.accounting_api + "/games/ert/vendor/auth?game_code=taskagitmakas", null, true)
                .then(function (res) {
                if (res && res.status && iframeContainerRef.current) {
                    iframeContainerRef.current.src = res.data.url;
                }
            });
        }
    }, [iframeContainerRef.current]);
    return _jsxs("div", { children: [auth.authentication && auth.member && auth.member.id ?
                _jsx("iframe", { ref: iframeContainerRef, className: "iframeContainer" }) :
                _jsx(GameLogin, {}), _jsx(GameInfo, { code: "taskagitmakas" })] });
}
export default RockPaperScissors;
