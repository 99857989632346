var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./notFound.css";
export function PageNotFound() {
    return (_jsx("header", __assign({ className: 'not-found-header' }, { children: _jsx("div", __assign({ className: "page-not-found-container" }, { children: _jsxs("div", __assign({ className: "centered-text" }, { children: [_jsx("h1", __assign({ className: "centered-text" }, { children: " Arad\u0131\u011F\u0131n\u0131z Sayfa Bulunamad\u0131 " })), _jsx("p", { children: " Ziyaret edilen sayfa sistemden kald\u0131r\u0131lm\u0131\u015F ya da hatal\u0131 bir link i\u00E7eriyor." }), _jsx("p", { children: "Hatay\u0131 almaya devam ediyorsan\u0131z l\u00FCtfen" }), _jsx("a", __assign({ href: "mailto:info@e-sans.com.tr" }, { children: "info@e-sans.com.tr" })), " adresinden bize iletiniz."] })) })) })));
}
