var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { env, passwordModalStyles } from "../../constants/global.constants";
import Modal from "react-modal";
import { api } from "../../services/http.service";
import { modalServiceSubject } from "../../services/modal.service";
import InputMask from "react-input-mask";
import iconClose from "../../assets/icons/feather/x-circle.svg";
import paths from "../../paths";
var s;
var resendButton;
export default function ForgetPassword(props) {
    var _a = useState(true), showTCKN = _a[0], setShowTCKN = _a[1];
    var _b = useState(null), showSelection = _b[0], setShowSelection = _b[1];
    var _c = useState(false), showCodeSection = _c[0], setShowCodeSection = _c[1];
    var _d = useState(''), selectedType = _d[0], setSelectedType = _d[1];
    var _e = useState(''), showNewPassword = _e[0], setShowNewPassword = _e[1];
    var _f = useState(''), code = _f[0], setCode = _f[1];
    var passwordModal = props.passwordModal;
    var closePasswordModal = props.closePasswordModal;
    var _g = useForm(), register = _g.register, handleSubmit = _g.handleSubmit, errors = _g.formState.errors;
    var senderTimer = function (s) {
        if (s === void 0) { s = null; }
        var resendTimer = document.getElementById("id_resendTimer");
        if (resendTimer) {
            var secondLeft = Number(Number(resendTimer.innerHTML.split(":")[0]) * 60 +
                Number(resendTimer.innerHTML.split(":")[1]) -
                1);
            if (secondLeft < 0) {
                clearInterval(s);
                resendButton = document.getElementById("id_resendCode");
                if (resendButton) {
                    resendButton.classList.remove("hide");
                }
            }
            else {
                resendTimer.innerHTML =
                    Math.floor(secondLeft / 60) +
                        ":" +
                        ("00" + (secondLeft % 60)).slice(-2);
            }
        }
    };
    var onSubmit = function (data) {
        api
            .start("post", env.accounting_api + "/member/api/member-contact-information/", data, false)
            .then(function (res) {
            if (res.status) {
                setShowSelection(res.data);
                setShowTCKN(false);
            }
            else {
                modalServiceSubject.next({
                    case: "danger",
                    title: "Hata!",
                    content: "<p>".concat(res.message, "</p>"),
                    timeout: 5000,
                    confirm: {
                        cancel: {
                            class: "btn btn-danger",
                            label: "Kapat",
                        },
                    },
                });
            }
            // console.log(res)
        })
            .catch(function (e) {
            // console.log(e)
        });
        // callChangePasswordApi(data);
    };
    var onSubmitCode = function (data) {
        data.code = data.code.replaceAll(' ', '');
        api
            .start("post", env.accounting_api + "/member/api/password-code-validate/", data, false)
            .then(function (res) {
            if (res.status) {
                setCode(data.code);
                setShowCodeSection(false);
                setShowNewPassword(true);
            }
            else {
                modalServiceSubject.next({
                    case: "danger",
                    title: "Hata!",
                    content: "<p>".concat(res.message, "</p>"),
                    timeout: 5000,
                    confirm: {
                        cancel: {
                            class: "btn btn-danger",
                            label: "Kapat",
                        },
                    },
                });
            }
            // console.log(res)
        })
            .catch(function (e) {
            // console.log(e)
        });
        // callChangePasswordApi(data);
    };
    var onSubmitPassword = function (data) {
        data.code = code.replaceAll(' ', '');
        api
            .start("post", env.accounting_api + "/member/api/password-renew-with-code/", data, false)
            .then(function (res) {
            if (res.status) {
                modalServiceSubject.next({
                    case: "success",
                    title: "İşleminiz Başarılı",
                    content: "<p>".concat(res.message, "</p>"),
                    timeout: 5000,
                    confirm: {
                        cancel: {
                            class: "btn btn-success",
                            label: "Kapat",
                        },
                    },
                });
                closePasswordModal();
            }
            else {
                modalServiceSubject.next({
                    case: "danger",
                    title: "Hata!",
                    content: "<p>".concat(res.message, "</p>"),
                    timeout: 5000,
                    confirm: {
                        cancel: {
                            class: "btn btn-danger",
                            label: "Kapat",
                        },
                    },
                });
            }
            // console.log(res)
        })
            .catch(function (e) {
            // console.log(e)
        });
        // callChangePasswordApi(data);
    };
    var callChangePasswordApi = function (type) {
        if (type === void 0) { type = null; }
        var data = {
            return_address: "".concat(window.location.protocol, "//").concat(window.location.host).concat(paths.resetPassword.url),
            messenger_type: type !== null && type !== void 0 ? type : selectedType,
            user_id: showSelection.user_id,
        };
        api
            .start("post", env.accounting_api + "/member/api/password-reminder/", data, false)
            .then(function (res) {
            if (res.status) {
                setShowCodeSection(true);
                s = setInterval(senderTimer, 1000);
                setShowSelection(false);
            }
            else {
                modalServiceSubject.next({
                    case: "danger",
                    title: "Hata!",
                    content: "<p>".concat(res.message, "</p>"),
                    timeout: 5000,
                    confirm: {
                        cancel: {
                            class: "btn btn-danger",
                            label: "Kapat",
                        },
                    },
                });
            }
            // console.log(res)
        })
            .catch(function (e) {
            // console.log(e)
        });
    };
    return (_jsx(_Fragment, { children: _jsxs(Modal, __assign({ isOpen: passwordModal, onRequestClose: closePasswordModal, style: passwordModalStyles, contentLabel: "" }, { children: [_jsx("div", __assign({ className: "modal-close" }, { children: _jsx("img", { src: iconClose, width: 24, className: "float-right cursor-p ", onClick: closePasswordModal }) })), _jsxs("div", __assign({ className: "forget-password-modal" }, { children: [_jsx("h2", { children: "\u015Eifremi Unuttum" }), showTCKN &&
                            _jsxs(_Fragment, { children: [_jsx("p", { children: "Size ait TC Kimlik numaras\u0131n\u0131 girin.Kay\u0131tlar\u0131m\u0131zla e\u015Fle\u015Firlerse, size \u015Fifrenizi nas\u0131l s\u0131f\u0131rlayaca\u011F\u0131n\u0131z\u0131 a\u00E7\u0131klayan bir e-posta g\u00F6nderece\u011Fiz." }), _jsxs("div", { children: [_jsx("label", { children: "TC Kimlik Numaras\u0131" }), _jsxs("form", __assign({ onSubmit: handleSubmit(onSubmit) }, { children: [_jsx("input", __assign({ className: "form-control", type: "number", min: "1000000000", max: "99999999999" }, register("tc_id", {
                                                        required: true,
                                                        minLength: 11,
                                                        maxLength: 11,
                                                    }), { placeholder: "Hesabınıza kayıtlı TCKN" })), _jsxs("div", __assign({ className: "mt-2" }, { children: [errors.tc_id && errors.tc_id.type === "required" && (_jsx("span", __assign({ className: "text-validation text-danger" }, { children: "TCKN bilgisini girmelisiniz." }))), errors.tc_id && errors.tc_id.type === "minLength" && (_jsx("span", __assign({ className: "text-validation text-danger" }, { children: "TCKN bilgisi hatal\u0131" })))] })), _jsx("input", { className: "btn btn-primary float-right mt-3", type: "submit", value: "G\u00F6nder" })] }))] })] }), showSelection &&
                            _jsxs(_Fragment, { children: [_jsx("p", { children: "\u015Eifre S\u0131f\u0131rlama linkini g\u00F6nderim y\u00F6ntemini se\u00E7iniz" }), showSelection.mobile && (_jsx("div", __assign({ className: "card forget-password-card pointer", onClick: function () {
                                            setSelectedType('sms');
                                            callChangePasswordApi('sms');
                                        } }, { children: _jsx("div", __assign({ className: "card-body" }, { children: _jsx("div", __assign({ className: "row" }, { children: _jsxs("div", __assign({ className: "col-12" }, { children: [_jsx("h3", { children: "Telefon \u0130le s\u0131f\u0131rlama" }), _jsxs("p", __assign({ className: "m-0" }, { children: [showSelection.mobile, " numaral\u0131 telefonunuza s\u0131f\u0131rlama linki g\u00F6nderilecektir"] }))] })) })) })) }))), showSelection.email && (_jsx("div", __assign({ className: "card forget-password-card", onClick: function () {
                                            setSelectedType('mail');
                                            callChangePasswordApi('mail');
                                        } }, { children: _jsx("div", __assign({ className: "card-body" }, { children: _jsx("div", __assign({ className: "row" }, { children: _jsxs("div", __assign({ className: "col-12" }, { children: [_jsx("h3", { children: "E-posta \u0130le s\u0131f\u0131rlama" }), _jsxs("p", __assign({ className: "m-0" }, { children: [showSelection.email, " e-posta adresinize s\u0131f\u0131rlama linki g\u00F6nderilecektir"] }))] })) })) })) })))] }), showCodeSection &&
                            _jsxs("form", __assign({ onSubmit: handleSubmit(onSubmitCode) }, { children: [_jsx("label", { children: "Do\u011Frulama Kodu" }), _jsx("div", __assign({ id: "emailHelp", style: { right: 10, marginTop: 10, marginRight: 25 }, className: "form-text position-absolute" }, { children: _jsx("span", __assign({ id: "id_resendTimer" }, { children: "2:00" })) })), _jsx(InputMask, __assign({ mask: "999 999", className: "form-control", placeholder: "___ ___" }, register("code", { required: true }))), _jsxs("div", __assign({ className: "d-flex justify-content-between align-items-center mt-2" }, { children: [_jsx("button", __assign({ className: "btn btn-primary" }, { children: "G\u00F6nder" })), _jsx("button", __assign({ type: "button", onClick: function () {
                                                    clearInterval(s);
                                                    callChangePasswordApi();
                                                    if (resendButton) {
                                                        resendButton.classList.add("hide");
                                                    }
                                                    var resendTimer = document.getElementById("id_resendTimer");
                                                    if (resendTimer) {
                                                        resendTimer.innerHTML = "2:00";
                                                    }
                                                    s = setInterval(senderTimer, 1000);
                                                }, style: {
                                                    fontSize: 13,
                                                    padding: "0px 5px",
                                                }, className: "btn hide btn-sm  btn-warning", id: "id_resendCode" }, { children: "Tekrar G\u00F6nder" }))] }))] })), showNewPassword &&
                            _jsx(_Fragment, { children: _jsxs("form", __assign({ onSubmit: handleSubmit(onSubmitPassword) }, { children: [_jsx("div", __assign({ className: "row" }, { children: _jsxs("div", __assign({ className: "col-12" }, { children: [_jsx("label", __assign({ htmlFor: "id_password2", className: "form-label" }, { children: "\u015Eifre (Tekrar)" })), _jsx("input", __assign({ type: "password", className: "form-control", id: "id_password2" }, register("password", { required: true })))] })) })), _jsx("div", __assign({ className: "row" }, { children: _jsxs("div", __assign({ className: "col-12" }, { children: [_jsx("label", __assign({ htmlFor: "id_password2", className: "form-label" }, { children: "\u015Eifre (Tekrar)" })), _jsx("input", __assign({ type: "password", className: "form-control", id: "id_password2" }, register("password2", { required: true })))] })) })), _jsx("button", __assign({ className: "btn btn-primary float-right mt-3" }, { children: "G\u00F6nder" }))] })) })] }))] })) }));
}
