var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import couponCover from "../../../assets/images/win-point-scracth.png";
import couponBg from "../../../assets/images/inner-scratch.png";
// @ts-ignore
import loadingGif from "../../../assets/images/loading-loader.gif";
import ScratchCard from "react-scratchcard";
import iconClose from "../../../assets/icons/feather/x-circle-white.svg";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useNavigate, useParams } from "react-router";
import Confetti from "react-confetti";
import { exceptionModal, loadingAnimation, loginRequired, useForceUpdate } from "../../../services/misc.functions";
import { api } from "../../../services/http.service";
import { env } from "../../../constants/global.constants";
import { auth } from "../../../store/auth.store";
import { LoginRequired } from "../../../shared/auth/login.required";
import { isMobile } from "../../../App";
import emptyImage from "../../../assets/icons/empty_screen.svg";
import paths from "../../../paths";
var timerSliderChanger = null;
var xhrMetaData = {};
export function ScratchCardMyCards() {
    var _a;
    var _b = useState(null), dataList = _b[0], setDataList = _b[1];
    var _c = useState(null), showDrawModal = _c[0], setShowDrawModal = _c[1];
    var _d = useState(0), dataFilterTab = _d[0], setDataFilterTab = _d[1];
    var _e = useState(null), sliderRef = _e[0], setSliderRef = _e[1];
    var _f = useState(false), loading = _f[0], setLoading = _f[1];
    var _g = useState(0), scratchedCardCount = _g[0], setScratchedCardCount = _g[1];
    var _h = useState(false), showTotalWin = _h[0], setShowTotalWin = _h[1];
    var _j = useState(0), totalPayout = _j[0], setTotalPayout = _j[1];
    var forceUpdate = useForceUpdate();
    var navigate = useNavigate();
    var params = useParams();
    var _k = useState(false), confetti = _k[0], setConfetti = _k[1];
    useEffect(function () {
        getMyCards();
    }, [dataFilterTab]);
    useEffect(function () {
        // console.log(params.draw_id);
        if (params.draw_id) {
            showCards(params.draw_id);
        }
    }, []);
    var getMyCards = function () {
        var payload = {
            has_scratch: false,
        };
        if (dataFilterTab === 0) {
            payload.has_scratch = false;
            payload.status = null;
        }
        if (dataFilterTab === 2) {
            payload.has_scratch = true;
            payload.status = 2;
        }
        if (dataFilterTab === 3) {
            payload.has_scratch = true;
            payload.status = 3;
        }
        api
            .start("post", env.accounting_api + "/somos/api/kazi-kazan/kuponlarim/", payload, true)
            .then(function (res) {
            setDataList(res.data);
            // console.log(res)
        });
    };
    var showCards = function (drawId) {
        loadingAnimation(true);
        // console.log(drawId)
        var payload = {
            // has_scratch: false,
            type: "get_draw",
            draw_id: drawId,
        };
        if (dataFilterTab === 0) {
            payload.has_scratch = false;
            setConfetti(false);
        }
        if (dataFilterTab === 2) {
            payload.has_scratch = true;
            payload.status = 2;
            setConfetti(false);
        }
        if (dataFilterTab === 3) {
            payload.has_scratch = true;
            payload.status = 3;
            setConfetti(false);
        }
        api
            .start("post", env.accounting_api + "/somos/api/kazi-kazan/kuponlarim/", payload, true)
            .then(function (res) {
            if (res.data.length > 0) {
                setShowDrawModal(res.data);
            }
            else {
                window.history.pushState(null, "", paths.scratch.url + "/kartlarim");
            }
        }).finally(function () {
            loadingAnimation(false);
        });
    };
    var screenWidth = window.innerWidth;
    if (screenWidth > 768) {
        screenWidth = 375;
    }
    var cardHeight = ((screenWidth - 40) / 335) * 406;
    var swiper = useSwiper();
    var handleScratchComplete = function (c) {
        var payload = {
            type: "scratch",
            coupons: String(c.id),
        };
        api
            .start("post", env.accounting_api + "/somos/api/kazi-kazan/kazi/", payload, true)
            .then(function (res) {
            if (res && res.status) {
                var d = dataList.find(function (e) { return e.draw_id === c.draw_id; });
                if (d) {
                    d.not_scratched_count -= 1;
                    setConfetti(false);
                }
                c.has_scratched = true;
                // timerSliderChanger = setTimeout(() => {
                //     sliderRef.slideNext();
                // }, 3000);
                c.selections.map(function (s, skey) {
                    var nonUnique = c.selections.filter(function (o) {
                        if (o.value === s.value) {
                            return o;
                        }
                    }).length;
                    if (nonUnique === 3) {
                        setTotalPayout(totalPayout + Number(s.value));
                        c.is_won = true;
                        c.won_amount = s.value;
                        setConfetti(true);
                        setTimeout(function () {
                            setConfetti(false);
                        }, 6000);
                    }
                });
                forceUpdate();
            }
        });
        setScratchedCardCount(scratchedCardCount + 1);
        setTimeout(function () {
            if (scratchedCardCount + 1 == showDrawModal.length) {
                setShowTotalWin(true);
            }
        }, 3000);
    };
    var scratchAll = function () {
        setLoading(true);
        var couponIds = showDrawModal.map(function (el, key) {
            return el.id;
        });
        var totalWon = 0;
        var payload = {
            type: "scratch",
            coupons: couponIds.join(","),
        };
        api
            .start("post", env.accounting_api + "/somos/api/kazi-kazan/kazi/", payload, true)
            .then(function (res) {
            if (res && res.status) {
                showDrawModal.map(function (c, key) {
                    c.has_scratched = true;
                    c.selections.map(function (s, skey) {
                        var nonUnique = c.selections.filter(function (o) {
                            if (o.value === s.value) {
                                return o;
                            }
                        }).length;
                        if (nonUnique === 3) {
                            c.is_won = true;
                            c.won_amount = s.value;
                        }
                    });
                    if (c.is_won) {
                        totalWon += Number(c.won_amount);
                    }
                });
                setScratchedCardCount(showDrawModal.length);
                setShowTotalWin(true);
                setTotalPayout(totalWon);
                if (totalWon > 0) {
                    setConfetti(true);
                    setTimeout(function () {
                        setConfetti(false);
                    }, 6000);
                }
                forceUpdate();
                setLoading(false);
            }
        })
            .catch(function (e) {
            exceptionModal(e);
        });
    };
    if (!((_a = auth.member) === null || _a === void 0 ? void 0 : _a.id)) {
        loginRequired(navigate);
        return _jsx(LoginRequired, {});
    }
    return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ id: "id_loaderAnimation", className: "overlay" }, { children: _jsx("div", __assign({ className: "overlay__inner" }, { children: _jsx("div", __assign({ className: "overlay__content" }, { children: _jsx("span", { className: "big-loader" }) })) })) })), _jsx("div", __assign({ style: { zIndex: 99999 } }, { children: confetti ? _jsx(Confetti, {}) : _jsx(_Fragment, {}) })), _jsx("div", __assign({ className: "col-lg-12 " }, { children: _jsxs("div", __assign({ className: "draw-filter-wrapper" }, { children: [!isMobile && _jsx("div", __assign({ className: "float-start" }, { children: _jsxs("h4", { children: ["Kaz\u0131 Kazan Kartlar\u0131m", _jsxs("span", { children: [xhrMetaData === null || xhrMetaData === void 0 ? void 0 : xhrMetaData.count, " \u00E7ekili\u015F, ", dataList === null || dataList === void 0 ? void 0 : dataList.length, " adet g\u00F6sterilen."] })] }) })), _jsxs("div", __assign({ className: "float-lg-end" }, { children: [_jsx("button", __assign({ className: "btn btn-sm me-1 btn-outline-primary ".concat(dataFilterTab === 0 && "active"), onClick: function () {
                                        setDataFilterTab(0);
                                    } }, { children: "Kaz\u0131nmam\u0131\u015F" })), _jsx("button", __assign({ className: "btn btn-sm me-1 btn-outline-primary ".concat(dataFilterTab === 2 && "active", " "), onClick: function () {
                                        setDataFilterTab(2);
                                    } }, { children: "Kazanan" })), _jsx("button", __assign({ className: "btn btn-sm me-1 btn-outline-primary ".concat(dataFilterTab === 3 && "active", " "), onClick: function () {
                                        setDataFilterTab(3);
                                    } }, { children: "Kaybeden" }))] }))] })) })), dataList &&
                dataList.map(function (draw, key) {
                    var _a;
                    return (_jsx("div", __assign({ className: "col-6 col-mobile-12 mb-3 mobile-row" }, { children: _jsxs("div", __assign({ className: "scratch-card" }, { children: [_jsxs("div", __assign({ className: "head" }, { children: [dataFilterTab === 0 && (_jsx(_Fragment, { children: _jsx("button", __assign({ className: "btn btn-success fw-bold float-end", onClick: function () {
                                                    showCards(draw.draw_id);
                                                } }, { children: "Kaz\u0131" })) })), dataFilterTab === 2 && (_jsx(_Fragment, { children: _jsx("button", __assign({ className: "btn btn-warning fw-bold float-end", onClick: function () {
                                                    showCards(draw.draw_id);
                                                } }, { children: "Kartlar\u0131 G\u00F6ster" })) })), dataFilterTab === 3 && (_jsx(_Fragment, { children: _jsx("button", __assign({ className: "btn btn-warning fw-bold float-end", onClick: function () {
                                                    showCards(draw.draw_id);
                                                } }, { children: "Kartlar\u0131 G\u00F6ster" })) })), _jsx("span", { children: draw.draw != null
                                                ? (_a = draw.draw) === null || _a === void 0 ? void 0 : _a.title_abbreviated
                                                : "Kazı Kazan" }), _jsxs("h2", { children: ["B\u00FCy\u00FCk \u00D6d\u00FCl: ", draw.big_prize || "-"] })] })), _jsx("div", __assign({ className: "foot" }, { children: _jsx("div", __assign({ className: "row text-secondary" }, { children: _jsxs("div", __assign({ className: "col-12 text-end" }, { children: [dataFilterTab === 3 && (_jsxs(_Fragment, { children: ["Sat\u0131n Al\u0131nan Bilet: ", draw.total_count, " adet"] })), dataFilterTab === 2 && (_jsxs(_Fragment, { children: ["Toplam Kazan\u00E7:", _jsxs("span", __assign({ className: "text-success fw-bold" }, { children: [draw.total_won_amount, "\u20BA"] }))] })), dataFilterTab === 0 && (_jsxs(_Fragment, { children: ["Kaz\u0131nmam\u0131\u015F Bilet:", _jsxs("span", __assign({ className: "text-success fw-bolder" }, { children: [draw.not_scratched_count, " adet"] }))] }))] })) })) }))] })) }), "my-scratch-card-".concat(key)));
                }), dataList && dataList.length === 0 && (_jsxs("div", __assign({ className: "d-flex flex-column justify-content-center align-items-center mt-5" }, { children: [_jsx("img", { className: "h-25", src: emptyImage }), _jsxs("div", __assign({ className: "text-center py-5 fw-bold" }, { children: [dataFilterTab === 0 && 'Kazınmamış ', dataFilterTab === 1 && 'Kazanan ', dataFilterTab === 2 && 'Kaybeden ', "kart\u0131n\u0131z bulunmamaktad\u0131r."] })), _jsx("div", __assign({ className: "btn btn-primary", onClick: function () { navigate(paths.scratch.url); } }, { children: "Oynamaya Devam Et" }))] }))), _jsxs(Modal, __assign({ isOpen: showDrawModal !== null, onAfterOpen: function () {
                    document.body.style.overflow = "hidden";
                }, onRequestClose: function () {
                    document.body.style.overflow = "unset";
                    getMyCards();
                    setLoading(false);
                    setShowDrawModal(null);
                    setShowTotalWin(false);
                    setScratchedCardCount(0);
                    window.history.pushState(null, "", paths.scratch.url + "/kartlarim");
                }, className: "scratch-card-modal", style: {
                    content: {
                        marginLeft: isMobile ? "0" : "calc(50% - 275px)",
                        width: isMobile ? "100%" : screenWidth + 40,
                        height: "calc(100%)",
                        paddingTop: "10px",
                        maxHeight: "525px",
                        paddingBottom: "10px",
                    },
                }, contentLabel: "" }, { children: [_jsx("a", __assign({ className: "modal-close", onClick: function () {
                            document.body.style.overflow = "unset";
                            getMyCards();
                            setShowDrawModal(null);
                            setShowTotalWin(false);
                            window.history.pushState(null, "", paths.scratch.url + "/kartlarim");
                            setScratchedCardCount(0);
                            setLoading(false);
                        } }, { children: _jsx("img", { src: iconClose }) })), loading ? (_jsx("div", __assign({ style: {
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        } }, { children: _jsx("div", { children: _jsxs("div", __assign({ className: "card rounded-4 mt-2", style: { backgroundColor: "transparent" } }, { children: [_jsx("div", __assign({ className: "card-header" }, { children: _jsx("h2", __assign({ className: "text-center fs-4", style: { color: "#ffffff" } }, { children: "L\u00FCtfen Bekleyin Biletleriniz Kaz\u0131n\u0131yor" })) })), _jsx("div", __assign({ className: "card-body d-flex justify-content-center" }, { children: _jsx("img", { src: loadingGif, style: { width: "50%" } }) }))] })) }) }))) : (_jsx(_Fragment, { children: showTotalWin ? (_jsxs(_Fragment, { children: [totalPayout > 0 ?
                                    _jsx("h2", __assign({ className: "text-center fw-bold fs-4" }, { children: "Tebrikler" }))
                                    : _jsx("h2", __assign({ className: "text-center fw-bold fs-4" }, { children: "Tekrar Dene" })), _jsx("div", __assign({ className: "scratch-card winner" }, { children: _jsx("div", __assign({ className: "body p-4" }, { children: totalPayout > 0 ?
                                            _jsxs("div", __assign({ className: "desciption" }, { children: [_jsx("span", { children: "Toplam" }), _jsxs("h2", { children: [totalPayout, " \u20BA"] }), _jsx("span", { children: "\u015EP Puan " }), _jsx("span", { children: "Kazand\u0131n\u0131z" })] })) : _jsx("div", __assign({ className: "desciption" }, { children: _jsx("h2", { children: "Biletinize ikramiye \u00E7\u0131kmad\u0131" }) })) })) })), totalPayout > 0 && _jsxs("div", __assign({ className: "card rounded-4 mt-2" }, { children: [_jsx("div", __assign({ className: "card-header" }, { children: _jsx("h2", __assign({ className: "won-card-header" }, { children: "Kazanan Kart Listesi" })) })), _jsx("div", __assign({ className: "card-body won-tickets-area" }, { children: showDrawModal &&
                                                showDrawModal
                                                    .filter(function (el) { return el.is_won == true; })
                                                    .map(function (el, key) {
                                                    return (_jsxs("div", __assign({ className: "won-ticket-row" }, { children: [_jsxs("span", { children: ["#", el.id] }), _jsxs("span", { children: ["#", el.won_amount] })] }), "won-tickets-" + key.toString()));
                                                }) }))] })), _jsx("button", __assign({ className: "btn btn-success w-100 mt-3 fw-bold", onClick: function () {
                                        navigate(paths.scratch.url);
                                        setShowTotalWin(false);
                                    }, id: "btn_submit" }, { children: "Ba\u015Fka Kart Al" })), _jsx("button", __assign({ className: "btn btn-custom w-100 mt-3", onClick: function () {
                                        document.body.style.overflow = "unset";
                                        setShowDrawModal(null);
                                        setShowTotalWin(false);
                                        getMyCards();
                                        setScratchedCardCount(0);
                                        setLoading(false);
                                        setConfetti(false);
                                        window.history.pushState(null, "", paths.scratch.url + "/kartlarim");
                                    }, id: "btn_submit" }, { children: "Kartlar\u0131m" }))] })) : (_jsxs(_Fragment, { children: [showDrawModal && (_jsxs("h2", __assign({ className: "text-center fw-bold fs-6" }, { children: ["B\u00FCy\u00FCk \u00D6d\u00FCl:", showDrawModal[0].draw != null
                                            ? showDrawModal[0].draw.big_prize || "-"
                                            : ""] }))), showDrawModal && dataFilterTab === 0 && (_jsxs("h2", __assign({ className: "text-center fw-bold fs-6" }, { children: [scratchedCardCount, " / ", showDrawModal.length] }))), showDrawModal && (_jsx(Swiper, __assign({ spaceBetween: 30, id: "card-slider", allowTouchMove: false, onSlideChange: function (e) {
                                        clearTimeout(timerSliderChanger);
                                        var ex = document.querySelectorAll("#card-slider");
                                        var wrapper = document.querySelectorAll("#card-slider .swiper-wrapper");
                                        wrapper[0].style.marginLeft = "unset";
                                        wrapper[0].style.transform = "translate3d(".concat(ex[0].offsetWidth * -1 * e.activeIndex, "px, 0px, 0px);");
                                        setTimeout(function () {
                                            // console.log(ex[0].offsetWidth)
                                            wrapper[0].style.marginLeft = "".concat(ex[0].offsetWidth * -1 * e.activeIndex, "px");
                                            wrapper[0].style.transform = "unset";
                                        }, 400);
                                    }, breakpoints: {
                                        0: {
                                            slidesPerView: 1,
                                            spaceBetween: 0,
                                        },
                                    }, onInit: function (ev) {
                                        // console.log(ev)
                                        setSliderRef(ev);
                                    } }, { children: showDrawModal &&
                                        showDrawModal.map(function (el, key) {
                                            var nonUnique = 0;
                                            var prizePayout = 0;
                                            if (el.has_scratched === false || dataFilterTab === 0) {
                                                return (_jsx(SwiperSlide, __assign({ id: "card-slider-items-".concat(key) }, { children: _jsxs("div", __assign({ className: "px-3 d-flex justify-content-center flex-column align-items-center" }, { children: [_jsx(ScratchCard, __assign({}, {
                                                                width: isMobile ? screenWidth - 77 : screenWidth - 40,
                                                                height: isMobile ? cardHeight - 110 : cardHeight - 90,
                                                                image: el.draw__big_prize_photo !== null ||
                                                                    el.draw__big_prize_photo !== ""
                                                                    ? env.accounting_api + "/media/" +
                                                                        el.draw__big_prize_photo
                                                                    : couponCover,
                                                                finishPercent: 70,
                                                                onComplete: function () { return handleScratchComplete(el); },
                                                            }, { children: _jsxs("div", __assign({ className: "scratch-off-card" }, { children: [_jsx("img", { style: { height: cardHeight - 90 }, src: couponBg }), _jsx("div", __assign({ className: "row" }, { children: el.selections.map(function (s, skey) {
                                                                                var nonUnique = el.selections.filter(function (o) {
                                                                                    if (o.value === s.value) {
                                                                                        return o;
                                                                                    }
                                                                                }).length;
                                                                                if (nonUnique === 3) {
                                                                                    prizePayout = Number(s.value);
                                                                                }
                                                                                return (_jsxs("div", __assign({ className: "col-6 ".concat(nonUnique === 3 &&
                                                                                        el.has_scratched === true
                                                                                        ? "won"
                                                                                        : el.has_scratched === true &&
                                                                                            nonUnique == 2 &&
                                                                                            prizePayout == 0
                                                                                            ? "semi-won"
                                                                                            : el.has_scratched ===
                                                                                                false &&
                                                                                                s.has_scratched !== true
                                                                                                ? "non-scratched"
                                                                                                : ""), style: {
                                                                                        height: (cardHeight - 90) / 3,
                                                                                    } }, { children: [Number(s.value).toFixed(0), "\u20BA"] }), "".concat(skey, "-selection-").concat(key)));
                                                                            }) }))] })) })), showDrawModal &&
                                                                scratchedCardCount != showDrawModal.length &&
                                                                dataFilterTab === 0 && (_jsx("button", __assign({ className: "btn btn-success w-100 mt-2 fw-bold", onClick: function () {
                                                                    if (el.has_scratched == false) {
                                                                        scratchAll();
                                                                    }
                                                                    else {
                                                                        sliderRef.slideNext();
                                                                        setConfetti(false);
                                                                    }
                                                                }, id: "btn_submit" }, { children: el.has_scratched == false
                                                                    ? "Tümünü Kazı"
                                                                    : "Sıradaki Karta Geç" })))] })) }), "card-slider-items-".concat(key)));
                                            }
                                            else {
                                                return (_jsxs(SwiperSlide, __assign({ id: "card-slider-items-".concat(key) }, { children: [_jsxs("h2", __assign({ className: "text-center fw-bold fs-6" }, { children: [key + 1, " / ", showDrawModal.length] })), _jsx("div", __assign({ className: "px-3 d-flex justify-content-center flex-column align-items-center" }, { children: _jsxs("div", __assign({ className: "scratch-off-card", style: { height: cardHeight - 90 } }, { children: [_jsx("img", { src: couponBg, style: { height: cardHeight - 90 } }), _jsx("div", __assign({ className: "row" }, { children: el.selections.map(function (s, skey) {
                                                                            var nonUnique = el.selections.filter(function (o) {
                                                                                if (o.value === s.value) {
                                                                                    return o;
                                                                                }
                                                                            }).length;
                                                                            if (nonUnique === 3) {
                                                                                prizePayout = Number(s.value);
                                                                            }
                                                                            return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "col-6 ".concat(nonUnique, "  ").concat(el.status === 3 &&
                                                                                        el.has_scratched === true &&
                                                                                        nonUnique !== 3 &&
                                                                                        "lost", " ").concat(el.status === 3 &&
                                                                                        el.has_scratched === true &&
                                                                                        nonUnique === 2 &&
                                                                                        "semi-won", " ").concat(nonUnique === 3 &&
                                                                                        el.has_scratched === true
                                                                                        ? "won"
                                                                                        : el.has_scratched ===
                                                                                            false &&
                                                                                            s.has_scratched !== true
                                                                                            ? "non-scratched"
                                                                                            : ""), style: {
                                                                                        height: (cardHeight - 90) / 3,
                                                                                    } }, { children: [Number(s.value).toFixed(0), "\u20BA"] }), "".concat(skey, "-selection-").concat(key)) }));
                                                                        }) }))] })) })), _jsx("button", __assign({ type: "button", className: "btn btn-success w-100 mt-2 fw-bold", onClick: function () {
                                                                sliderRef.slideNext();
                                                                setConfetti(false);
                                                                forceUpdate();
                                                            } }, { children: "S\u0131radaki Karta Ge\u00E7" }))] }), "card-slider-items-".concat(key)));
                                            }
                                        }) }))), showDrawModal && (_jsx("h2", { className: "text-center fw-bold fs-5 mt-3" }))] })) }))] }))] }));
}
