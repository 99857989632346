import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from "react";
import { api } from "../../services/http.service";
import { env } from "../../constants/global.constants";
import { modalServiceSubject } from "../../services/modal.service";
import { exceptionModal } from "../../services/misc.functions";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import paths from "../../paths";
export function VerifyEmail() {
    var search = window.location.search;
    var params = new URLSearchParams(search);
    var hash = params.get('hash');
    var navigate = useNavigate();
    var pathname = useLocation().pathname;
    useEffect(function () {
        if (hash && pathname.includes(paths.verifyEmail.url)) {
            api.start('get', env.accounting_api + '/member/email-verify/?hash=' + hash, null, true)
                .then(function (res) {
                if (res) {
                    modalServiceSubject.next({
                        case: res.status ? 'success' : 'danger',
                        title: res.status ? 'Teşekkürler' : 'Üzgünüz!',
                        content: "<p class=\"text-center\">".concat(res.message, "</p>"),
                        confirm: {
                            cancel: {
                                label: 'Kapat',
                                action: function () {
                                    navigate(paths.home.url);
                                }
                            }
                        }
                    });
                }
            })
                .catch(function (e) {
                exceptionModal(e);
            });
        }
    }, [hash]);
    return (_jsx(_Fragment, {}));
}
