var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import "./games.css";
import { Footer } from "../../shared/footer/footer";
import { useEffect, useState } from "react";
import { getGameItem } from "../../services/misc.functions";
export function GameInfo(props) {
    var _a;
    var code = props.code;
    var _b = useState(null), game = _b[0], setGame = _b[1];
    useEffect(function () {
        console.log(code);
        var game = getGameItem(code);
        if (game != null) {
            setGame(game);
        }
    }, [code]);
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "card game-info" }, { children: [_jsx("div", __assign({ className: "card-header" }, { children: _jsxs("h1", __assign({ className: "text-start fw-bold fs-5" }, { children: ["E-\u015Eans | Online ", (_a = game === null || game === void 0 ? void 0 : game.name) !== null && _a !== void 0 ? _a : 'Şans', " Oyunu"] })) })), _jsx("div", __assign({ className: "card-body white-space-normal" }, { children: game && (game === null || game === void 0 ? void 0 : game.description) ? _jsx("p", { children: game === null || game === void 0 ? void 0 : game.description }) :
                            _jsx("p", { children: "\u015Eans Oyunlar\u0131, Milli Piyango \u0130daresince belirlenen usul ve esaslar dahilinde \u00FCyelerimize sunulan oyunlard\u0131r. Milli Piyango \u0130daresi g\u00FCvencesiyle d\u00FCzenlenmektedir. 320 say\u0131l\u0131 Kanun H\u00FCkm\u00FCnde Kararname h\u00FCk\u00FCmleri uyar\u0131nca, idare taraf\u0131ndan tertiplenen Kar\u015F\u0131l\u0131\u011F\u0131 Nakit Olmayan Oyunlar i\u00E7in taahh\u00FCt edilen ikramiyeler, devlet garantisi alt\u0131ndad\u0131r. \u015Eans Oyunlar\u0131 men\u00FCs\u00FCnden oynayaca\u011F\u0131n\u0131z t\u00FCm oyunlar, kar\u015F\u0131l\u0131\u011F\u0131 nakit olmayan oyunlard\u0131r: \u00C7ekilemez bakiye \u00F6d\u00FClleri... ( \u00C7ekilemez bakiyelerinizi E\u015Fya Piyangosu, \u015Eans Oyunlar\u0131nda ve \u00D6d\u00FCl Katalo\u011Funda kullanabilirsiniz.)" }) }))] })), _jsx(Footer, {})] }));
}
