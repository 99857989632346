var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { NotificationMenu } from "./account";
import { useEffect, useState } from "react";
import { auth, authSubject } from "../../store/auth.store";
import "./account.css";
import { api } from "../../services/http.service";
import { env } from "../../constants/global.constants";
import { wssProvider } from "../../services/websocket.service";
import { useForceUpdate } from "../../services/misc.functions";
import Modal from "react-modal";
import { Storage } from "../../services/localstorege.service";
export function CookieSettings() {
    var _a = useState(false), showCookieModal = _a[0], setShowCookieModal = _a[1];
    var _b = useState(null), agreementObject = _b[0], setAgreementObject = _b[1];
    var _c = useState(false), functionalCookie = _c[0], setFunctionalCookie = _c[1];
    var _d = useState(false), targetingCookie = _d[0], setTargetingCookie = _d[1];
    var _e = useState(true), mandatoryCookie = _e[0], setMandatoryCookie = _e[1];
    var forceUpdate = useForceUpdate();
    var cookies = {
        "zorunlu": {
            title: "Zorunlu",
            content: "Bu çerezler, web sitesinin doğru bir şekilde çalışması için gereklidir ve kapatılamaz. Bu çerezler, kişisel olarak tanımlanabilir herhangi bir bilgi saklamaz."
        },
        "hedefleme": {
            title: "Hedefleme Amaçlı Tanımlama Bilgileri",
            content: "Bu çerezler ilgi alanlarınızın profilini oluşturmak ve size bu doğrultuda reklamlar göstermek için kullanılır."
        },
        "islevsel": {
            title: "İşlevsel",
            content: "Bu tanımlama bilgileri, videolar ile canlı sohbet gibi gelişmiş işlevler ve kişiselleştirme olanağı sunabilmemizi sağlar. Bunlar, bizim tarafımızdan veya sayfalarımızda hizmetlerinden faydalandığımız üçüncü taraf sağlayıcılarca ayarlanabilir."
        },
    };
    var screenWidth = window.innerWidth;
    if (screenWidth > 768) {
        screenWidth = 720;
    }
    var updateCookieSettingsFunctional = function (obj) {
        api
            .start("post", env.accounting_api + "/member/api/update-account-info/", { "functional": obj }, true)
            .then(function (res) {
            wssProvider.checkauth();
        })
            .finally(function () {
        });
    };
    var updateCookieSettingsTargeting = function (obj) {
        api
            .start("post", env.accounting_api + "/member/api/update-account-info/", { "targeting_cookies": obj }, true)
            .then(function (res) {
            wssProvider.checkauth();
        })
            .finally(function () {
        });
    };
    var updateCookieSettingsMandatory = function (obj) {
        api
            .start("post", env.accounting_api + "/member/api/update-account-info/", { 'mandatory': obj }, true)
            .then(function (res) {
            wssProvider.checkauth();
        })
            .finally(function () {
        });
    };
    useEffect(function () {
        var auth_subscriber = authSubject.subscribe(function () {
            if (auth.member) {
                setFunctionalCookie(auth.member.functional);
                setTargetingCookie(auth.member.targeting_cookies);
                setMandatoryCookie(auth.member.mandatory);
            }
            forceUpdate();
        });
        return function () {
            auth_subscriber.unsubscribe();
        };
    }, []);
    useEffect(function () {
        var isAccepted = Storage.get("cookies-policy");
        if (isAccepted) {
            setFunctionalCookie(true);
            setTargetingCookie(true);
            setMandatoryCookie(true);
        }
    }, []);
    var getSelectedCookieAgreement = function (title) {
        setAgreementObject(cookies[title]);
        setShowCookieModal(true);
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "row" }, { children: [auth.member &&
                        _jsx("div", __assign({ className: "col-3 col-mobile-12" }, { children: _jsx(NotificationMenu, {}) })), _jsx("div", __assign({ className: " col-mobile-12 ".concat(auth.member ? "col-9" : "col-12") }, { children: _jsxs("div", __assign({ className: "card mb-4 rounded-4 notification-settings" }, { children: [_jsx("div", __assign({ className: "card-header" }, { children: _jsx("h2", { children: "\u00C7erez Tercihlerini Y\u00F6net" }) })), _jsxs("div", __assign({ className: "card-body" }, { children: [_jsxs("div", __assign({ className: "form-check form-switch" }, { children: [_jsxs("label", __assign({ onClick: function () {
                                                        getSelectedCookieAgreement('islevsel');
                                                    }, className: "form-check-label pointer", htmlFor: "id_switch_mail_notification" }, { children: [_jsx("h3", __assign({ className: "cookie-form-label" }, { children: "\u0130\u015Flevsel" })), _jsx("span", __assign({ style: { whiteSpace: "normal" } }, { children: cookies['islevsel']['content'] }))] })), _jsx("input", { checked: functionalCookie, className: "form-check-input float-end mt-3", type: "checkbox", role: "switch", id: "functional_cookies", onChange: function (e) {
                                                        if (auth.member) {
                                                            updateCookieSettingsFunctional(!functionalCookie);
                                                        }
                                                        else {
                                                            setFunctionalCookie(!functionalCookie);
                                                        }
                                                    } })] })), _jsxs("div", __assign({ className: "form-check form-switch" }, { children: [_jsxs("label", __assign({ onClick: function () {
                                                        getSelectedCookieAgreement('hedefleme');
                                                    }, className: "form-check-label  pointer", htmlFor: "id_switch_sms_notification" }, { children: [_jsx("h3", __assign({ className: "cookie-form-label" }, { children: "Hedefleme Ama\u00E7l\u0131 Tan\u0131mlama Bilgileri" })), _jsx("span", __assign({ style: { whiteSpace: "normal" } }, { children: cookies['hedefleme']['content'] }))] })), _jsx("input", { checked: targetingCookie, className: "form-check-input float-end mt-3", type: "checkbox", role: "switch", id: "target_cookies", onChange: function (e) {
                                                        if (auth.member) {
                                                            updateCookieSettingsTargeting(!targetingCookie);
                                                        }
                                                        else {
                                                            setTargetingCookie(!targetingCookie);
                                                        }
                                                    } })] })), _jsxs("div", __assign({ className: "form-check form-switch" }, { children: [_jsxs("label", __assign({ onClick: function () {
                                                        getSelectedCookieAgreement('zorunlu');
                                                    }, className: "form-check-label  pointer", htmlFor: "is_switch_call_notification" }, { children: [_jsx("h3", __assign({ className: "cookie-form-label" }, { children: "Zorunlu" })), _jsx("span", __assign({ style: { whiteSpace: "normal" } }, { children: cookies['zorunlu']['content'] }))] })), _jsx("input", { checked: mandatoryCookie, className: "form-check-input float-end mt-3", type: "checkbox", role: "switch", disabled: true, id: "must_cookies", onChange: function (e) {
                                                        if (auth.member) {
                                                            updateCookieSettingsMandatory(!mandatoryCookie);
                                                        }
                                                        else {
                                                            setMandatoryCookie(!mandatoryCookie);
                                                        }
                                                    } })] }))] }))] })) }))] })), _jsx(Modal, __assign({ isOpen: showCookieModal, className: "default", onRequestClose: function () {
                    setShowCookieModal(false);
                }, style: { content: {
                        height: '275px',
                    }, }, contentLabel: "" }, { children: _jsxs(_Fragment, { children: [agreementObject &&
                            _jsx("div", __assign({ className: "head" }, { children: _jsx("h2", __assign({ className: "text-center" }, { children: agreementObject.title })) })), agreementObject &&
                            _jsx("div", __assign({ className: "modal-body" }, { children: _jsx("p", { dangerouslySetInnerHTML: { __html: agreementObject.content.replaceAll('\n\r\n', '<br/>') } }) })), _jsxs("div", __assign({ className: "modal-ticket-footer text-end" }, { children: [_jsx("div", { className: "wave" }), _jsx("div", __assign({ className: "body-row row" }, { children: _jsx("div", __assign({ className: "col-12" }, { children: _jsx("button", __assign({ type: "button", onClick: function () { return setShowCookieModal(false); }, className: "btn w-100 custom-btn-danger me-2" }, { children: "Kapat" })) })) }))] }))] }) }))] }));
}
