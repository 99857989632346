import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef } from 'react';
import "./bingo-card.css";
import { api } from "../../../services/http.service";
import { env } from "../../../constants/global.constants";
import { GameInfo } from "../game.info";
import { gtmViewItem, useForceUpdate } from "../../../services/misc.functions";
import { auth, authSubject } from "../../../store/auth.store";
import { GameLogin } from "../game.login";
var iframeHtmlString = function (config) { return "\n<body>\n  <style>\n    html,\n    body {\n      width: 100% !important;\n      height: 100% !important;\n      margin: 0;\n      padding: 0;\n    }\n    #gameIFrame {\n      width: 100% !important;\n      height: 100% !important;\n      border: none;\n      text-align: center;\n      background-color: Black;\n    }\n    #iframeContainer {\n      width: 100%;\n    }\n  </style>\n  <div id=\"iframeContainer\"></div>\n  <script\n    src=\"https://gameapi.mobilproses.com/js/GameStarter.js\"\n    type=\"text/javascript\"\n  ></script>\n  <script type=\"text/javascript\">\n    var config = {\n        \"Token\": \"".concat(config.public_token, "\",\n        \"MerchantId\": 6,\n        \"Lang\": \"tr-TR\",\n        \"GameId\": 1000,\n        \"ProductId\": 3,\n        \"IsFreeplay\": 0,\n        \"Platform\": \"mobile\",\n        \"IframeContainerId\": \"iframeContainer\",\n        \"IframeId\": \"gameIFrame\",\n        \"GameOpenType\": \"iframe\",\n        \"AuthUrl\": \"https://gameapi.mobilproses.com/auth/auth/\",\n        \"WindowWidth\": 1280,\n        \"ResizeWindow\": true\n    };\n    // @ts-ignore\n    MobilProses.GameStarter.startGame(config);\n  </script>\n</body>\n"); };
function SuperBingoCards() {
    var iframeContainerRef = useRef(null);
    var forceUpdate = useForceUpdate();
    useEffect(function () {
        var auth_subscriber = authSubject.subscribe(function () {
            forceUpdate();
        });
        return function () {
            auth_subscriber.unsubscribe();
        };
    }, []);
    useEffect(function () {
        if (auth.authentication && auth.member && auth.member.id) {
            gtmViewItem([{ code: "super_tombala", name: "Süper Tombala" }], 'Oyunlar');
            api.start("get", env.accounting_api + "/games/mobilproses/api/get_member_token/", null, true)
                .then(function (res) {
                if (res && res.status && iframeContainerRef.current) {
                    var blobContent = new Blob([iframeHtmlString(res.data)], { type: "text/html" });
                    iframeContainerRef.current.src = URL.createObjectURL(blobContent);
                }
            });
        }
    }, [iframeContainerRef.current]);
    return _jsxs("div", { children: [auth.authentication && auth.member && auth.member.id ?
                _jsx("iframe", { ref: iframeContainerRef, className: "iframeContainer" }) : _jsx(GameLogin, {}), _jsx(GameInfo, { code: "super_tombala" })] });
}
export default SuperBingoCards;
