var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { api } from "../../services/http.service";
import { env } from "../../constants/global.constants";
import { useEffect, useState } from "react";
import "./cart.mobile.dropdown.css";
import { dateFormat } from "../../services/misc.functions";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { CouponCodeWidget } from "./coupon.code";
import { useNavigate } from "react-router";
import { cartToggleSubject } from "../../store/cart.store";
import paths from "../../paths";
export function CouponMobileCodeWidget() {
    var _a = useState([]), couponList = _a[0], setCouponList = _a[1];
    var navigate = useNavigate();
    useEffect(function () {
        // getCoupons()
        //mount olduğunda 1 kere çalışır
    }, []);
    var getCoupons = function () {
        api.start("get", env.accounting_api + "/misc/api/list-discount-coupons/", null, true)
            .then(function (res) {
            if (res.status) {
                setCouponList(res.data);
            }
        });
    };
    return (_jsxs("div", __assign({ className: "coupon-slider" }, { children: [_jsx("div", { className: "clearfix" }), couponList.length > 0 ? _jsx("div", __assign({ className: "slider-container" }, { children: _jsx(Swiper, __assign({ spaceBetween: 0, breakpoints: {
                        0: {
                            slidesPerView: 1,
                            spaceBetween: 0,
                        },
                    }, navigation: true, modules: [Navigation] }, { children: couponList.map(function (cp, key) { return (_jsxs(SwiperSlide, { children: [_jsx("div", { className: "line-tall" }), _jsx("p", { children: cp.code }), _jsxs("p", __assign({ className: "nowrap" }, { children: ["Son Ge\u00E7erlilik Tarihi : ", _jsx("span", __assign({ style: {
                                            color: "var(--bs-blue)",
                                            marginLeft: "10px"
                                        } }, { children: dateFormat(cp.expire_date) }))] })), _jsxs("div", __assign({ className: "coupon-summary" }, { children: [_jsxs("button", __assign({ className: "border-primary-subtle rounded-start-5 coupon-summary-total " }, { children: [" ", Number(cp.amount).toFixed(2), "\u20BA"] })), _jsx("label", __assign({ className: "coupon-summary-btn" }, { children: _jsx(CouponCodeWidget, { buttonText: "Kullan" }) }))] }))] }, "coupon-widget-".concat(key))); }) })) })) : _jsx(_Fragment, { children: _jsx("div", __assign({ className: "align-items-center d-flex justify-content-center mt-2 w-100" }, { children: _jsx("button", __assign({ className: "btn", onClick: function () {
                            if (window.location.pathname == paths.campaign.url) {
                                document.body.style.overflow = "unset";
                                cartToggleSubject.next({ action: 'visibleToggleCart', show: false });
                            }
                            else {
                                navigate(paths.campaign.url);
                            }
                        } }, { children: "Kampanyalar\u0131 \u0130ncele" })) })) })] })));
}
