var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
export function LoginRequired() {
    return (_jsx(_Fragment, { children: _jsxs("p", __assign({ className: "p-4 text-center" }, { children: ["\u00DCye giri\u015Fi yapmadan bu sayfay\u0131 g\u00F6r\u00FCnt\u00FCleyemezsiniz. ", _jsx("br", {}), "E\u011Fer \u00FCye de\u011Filseniz hemen \u00FCye olunuz , yada oturum a\u00E7mak i\u00E7in \u00FCye giri\u015Fi yap\u0131n\u0131z"] })) }));
}
