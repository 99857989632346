var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import "./liveStream.css";
import { useEffect, useState } from "react";
import { api } from "../../services/http.service";
import { env, months } from "../../constants/global.constants";
import { auth } from "../../store/auth.store";
import { dateFormat, loadingAnimation, loginRequired, useForceUpdate, } from "../../services/misc.functions";
import iconYoutube from "../../assets/images/youtube.svg";
import iconInfo from "../../assets/icons/feather/info.svg";
import { Link, useLocation } from "react-router-dom";
import { modalServiceSubject } from "../../services/modal.service";
import { useNavigate } from "react-router";
import { isMobile } from "../../App";
import paths from "../../paths";
var years = [2021, 2022, 2023, 2024, 2025, 2026, 2027];
var selectedYear = new Date().getFullYear();
var selectedMonth = new Date().getMonth();
export function LiveStream() {
    var _this = this;
    var _a;
    var forceUpdate = useForceUpdate();
    var _b = useState([]), dataList = _b[0], setDataList = _b[1];
    var pathname = useLocation().pathname;
    var navigate = useNavigate();
    useEffect(function () {
        loadData(0);
    }, [pathname, selectedYear, selectedMonth]);
    var loadData = function (forcePage) {
        if (forcePage === void 0) { forcePage = null; }
        return __awaiter(_this, void 0, void 0, function () {
            var payload;
            return __generator(this, function (_a) {
                loadingAnimation(true);
                payload = {
                    year: selectedYear,
                    month: selectedMonth + 1,
                };
                api
                    .start("post", env.accounting_api + "/misc/api/list-broadcast/", payload, true)
                    .then(function (res) {
                    loadingAnimation(false);
                    setDataList(res.data);
                });
                return [2 /*return*/];
            });
        });
    };
    var yearChangeHandler = function (year) {
        // console.log(year);
        selectedYear = Number(year);
        forceUpdate();
    };
    var monthChangeHandler = function (month) {
        selectedMonth = Number(month);
        forceUpdate();
        // console.log(month);
    };
    var loadMore = function (e) {
        e.target.disabled = true;
        loadData().finally(function () {
            e.target.disabled = false;
        });
    };
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "livestream-page" }, { children: [_jsx("div", __assign({ className: "".concat(isMobile ? "mt-3" : "", " livestream-filter ") }, { children: _jsxs("div", __assign({ className: "row" }, { children: [!isMobile && (_jsxs("div", __assign({ className: "col-4" }, { children: [_jsx("h4", __assign({ className: "m-0", style: { fontSize: 16, fontWeight: 700 } }, { children: "Tarih filtresi:" })), _jsx("p", __assign({ className: "m-0", style: { fontSize: 15 } }, { children: "Ge\u00E7mi\u015F tarihli \u00E7ekili\u015Fleri izleyebilirsiniz." }))] }))), _jsx("div", __assign({ className: "col-4 col-mobile-6" }, { children: _jsx("select", __assign({ className: "form-control", value: selectedYear, onChange: function (e) {
                                        yearChangeHandler(e.target.value);
                                    } }, { children: years
                                        .filter(function (el) { return el <= Number(new Date().getFullYear()); })
                                        .map(function (el) {
                                        return (_jsx("option", __assign({ value: el }, { children: el }), el));
                                    }) })) })), _jsx("div", __assign({ className: "col-4 col-mobile-6" }, { children: _jsx("select", __assign({ value: selectedMonth, className: "form-control", onChange: function (e) {
                                        monthChangeHandler(e.target.value);
                                    } }, { children: months
                                        .filter(function (el) {
                                        if (Number(selectedYear) === Number(new Date().getFullYear())) {
                                            if (Number(el.val) - 1 <= new Date().getMonth()) {
                                                return el;
                                            }
                                        }
                                        else if (Number(selectedYear) !== Number(new Date().getFullYear())) {
                                            return el;
                                        }
                                    })
                                        .map(function (el, i) {
                                        return (_jsx("option", __assign({ value: i }, { children: el.name }), "months-select-".concat(i)));
                                    }) })) }))] })) })), ((_a = auth.member) === null || _a === void 0 ? void 0 : _a.id) && auth.member.live_draw_notification === false && (_jsxs("div", __assign({ className: "".concat(isMobile ? "mt-5" : "", " card mb-3 rounded-4") }, { children: [_jsx("div", __assign({ className: "card-header" }, { children: _jsx("h2", { children: "Bildirimlerinizi aktif edin." }) })), _jsxs("div", __assign({ className: "card-body" }, { children: [_jsxs("div", __assign({ className: "row" }, { children: [_jsx("div", __assign({ className: "col-md-1 col-sm-12 d-flex justify-content-center" }, { children: _jsx("img", { src: iconInfo, className: "float-start", style: { width: "3rem" } }) })), _jsx("div", __assign({ className: "col-md-11 col-sm-12 d-flex align-items-center" }, { children: _jsx("span", __assign({ style: { whiteSpace: "normal" } }, { children: "Kat\u0131ld\u0131\u011F\u0131n\u0131z e\u015Fya piyango \u00E7ekili\u015Fi sonu\u00E7lar\u0131n\u0131, canl\u0131 izlemeniz i\u00E7in \u00E7ekili\u015F zamanlar\u0131n\u0131 size hat\u0131rlatmak istiyoruz." })) }))] })), _jsx("div", __assign({ className: "d-flex align-items-center justify-content-center" }, { children: _jsx(Link, __assign({ to: paths.account.url + "/bildirim-tercihlerim", className: "btn btn-success fw-bold bg-success-subtle text-success mt-2" }, { children: "Bildirim Ayarlar\u0131m" })) }))] }))] }))), _jsxs("div", __assign({ className: " livestream-item" }, { children: [dataList &&
                            dataList.map(function (el, key) {
                                return (_jsxs("div", __assign({ className: "row item py-2" }, { children: [!isMobile && (_jsx("div", __assign({ className: "col-2", style: {
                                                justifyContent: "center",
                                                alignItems: "center",
                                                display: "flex",
                                            } }, { children: _jsx(Link, __assign({ to: el.url, target: "_blank", className: "youtube" }, { children: _jsx("img", { src: iconYoutube, alt: "icon" }) })) }))), _jsx("div", __assign({ className: "col-4 col-mobile-4", style: {
                                                fontSize: 14,
                                                lineHeight: 1.2,
                                                whiteSpace: "normal",
                                            } }, { children: _jsxs("b", { children: ["\u00C7ekili\u015F Tarihi: ", dateFormat(el.broadcast_date)] }) })), _jsx("div", __assign({ className: "col-3 col-mobile-4", style: {
                                                justifyContent: "center",
                                                alignItems: "center",
                                                display: "flex",
                                            } }, { children: _jsx("button", __assign({ className: "btn btn-warning bg-warning-subtle text-warning fw-bold btn-sm-mobile", onClick: function () {
                                                    if (!loginRequired(navigate)) {
                                                        return false;
                                                    }
                                                    var payload = {
                                                        "draw-date": el.broadcast_date.split("T")[0],
                                                        "draw_date": el.broadcast_date.split("T")[0],
                                                    };
                                                    api
                                                        .start("post", env.accounting_api +
                                                        "/somos/api/esya-piyangosu/approve-codes/", payload, true)
                                                        .then(function (res) {
                                                        if (res && res.status) {
                                                            var groupByDraws_1 = {};
                                                            if (res.data.length > 0) {
                                                                groupByDraws_1 = res.data.reduce(function (group, draw) {
                                                                    var _a;
                                                                    group[draw[1]] =
                                                                        (_a = group[draw[1]]) !== null && _a !== void 0 ? _a : [];
                                                                    group[draw[1]].push(draw);
                                                                    return group;
                                                                }, {});
                                                            }
                                                            modalServiceSubject.next({
                                                                title: "\u00C7ekili\u015F Tarihi: ".concat(dateFormat(el.broadcast_date)),
                                                                content: res.data.length > 0
                                                                    ? "<p>\n                                                                  <div class=\"row mx-0\">\n                                                                    ".concat(Object.keys(groupByDraws_1)
                                                                        .map(function (drawKey) {
                                                                        return "\n                                                                                <div class=\"col-12\">\n                                                                                    <div class=\"row group-header\"> ".concat(drawKey, " numaral\u0131 \u00E7ekili\u015F</div>\n                                                                                </div>\n                                                                                \n                                                                                ").concat(groupByDraws_1[drawKey].map(function (c) {
                                                                            return "<div class=\"col-3 p-1 text-center\">\n                                                                                        <div class=\"border  rounded-2 bg-secondary-subtle border-secondary\">".concat(c[0], "</div>\n                                                                                    </div>");
                                                                        }).join(""));
                                                                    })
                                                                        .join(""), "\n                                                                              </div>\n                                                                            </p>")
                                                                    : "<p>".concat(dateFormat(el.broadcast_date), " tarihli biletiniz bulunmamaktad\u0131r.</p>"),
                                                                confirm: {
                                                                    cancel: {
                                                                        label: "Kapat",
                                                                        class: "btn btn-danger w-100",
                                                                    },
                                                                },
                                                            });
                                                        }
                                                        else if (res && res.error === "re-login") {
                                                            loginRequired(navigate);
                                                        }
                                                    });
                                                } }, { children: _jsx("span", __assign({ style: { whiteSpace: "normal" } }, { children: "Biletlerimi G\u00F6r" })) })) })), _jsx("div", __assign({ className: "col-3 col-mobile-4", style: {
                                                justifyContent: "center",
                                                alignItems: "center",
                                                display: "flex",
                                            } }, { children: _jsx("button", __assign({ className: "btn btn-danger bg-danger-subtle text-danger fw-bold btn-sm-mobile", style: { whiteSpace: "normal" }, onClick: function () {
                                                    modalServiceSubject.next({
                                                        title: "Çekilişi İzle",
                                                        class: "stream-modal",
                                                        content: "<div>\n                  <iframe src=\"".concat(el.url.split("watch")[0] +
                                                            "embed/" +
                                                            el.url.split("watch")[1].replace("?v=", "") +
                                                            "?rel=0", "\" frameborder=\"0\"\n                  allow=\"accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture\"\n                  allowfullscreen></iframe>\n                  <div class=\"container\" style=\"height: 175px;overflow: scroll\">\n                       ").concat(el.draws
                                                            .map(function (d) {
                                                            return "<p>- ".concat(d.name, "</p>");
                                                        })
                                                            .join(""), "\n                  </div>\n                </div>"),
                                                        confirm: {
                                                            cancel: {
                                                                label: "Kapat",
                                                                class: "btn btn-danger w-100",
                                                            },
                                                        },
                                                    });
                                                } }, { children: _jsx("span", __assign({ style: { whiteSpace: "normal" } }, { children: "\u00C7ekili\u015Fi \u0130zle" })) })) }))] }), "raffle-result-".concat(key)));
                            }), dataList.length == 0 &&
                            _jsx("div", __assign({ className: "text-center py-5 text-wrap" }, { children: "Belirtilen tarihlerde yay\u0131n bulunmamaktad\u0131r." }))] })), _jsxs("div", __assign({ className: "text-center" }, { children: [_jsx("div", { id: "id_loaderAnimation", className: "loader" }), _jsx("button", __assign({ id: "id_datalist_loadmore", onClick: loadMore, type: "button", className: "btn rounded-pill btn-outline-primary" }, { children: "Devam\u0131n\u0131 Y\u00FCkle" }))] }))] })) }));
}
