var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import iconPlus from "../../assets/icons/feather/plus-circle.svg";
import { useForm } from "react-hook-form";
import { api } from "../../services/http.service";
import { env } from "../../constants/global.constants";
import { exceptionModal } from "../../services/misc.functions";
import { toast } from "react-toastify";
import Modal from "react-modal";
import { AccountInfoMenu } from "./account";
import { modalServiceSubject } from "../../services/modal.service";
import InputMask from "react-input-mask";
export function BankAccounts() {
    var _a = useState(null), accountList = _a[0], setAccountList = _a[1];
    var _b = useState(false), newAccountModal = _b[0], setNewAccountModal = _b[1];
    useEffect(function () {
        getListAccount();
    }, []);
    var getListAccount = function () {
        api
            .start("get", env.accounting_api + "/member/api/list-account/", null, true)
            .then(function (res) {
            // console.log(res)
            if (res && res.status) {
                setAccountList(res.data);
            }
            else {
                setAccountList([]);
            }
        })
            .catch(function (e) {
            exceptionModal(e);
            setAccountList([]);
        });
    };
    var deleteAccount = function (bankAccount, index) {
        api
            .start("post", env.accounting_api + "/member/api/create-member-account/", {
            id: bankAccount.id,
            delete: true,
        }, true)
            .then(function (res) {
            // console.log(res)
            if (res && res.status) {
                accountList.splice(index, 1);
                setAccountList(__spreadArray([], accountList, true));
            }
        });
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "row" }, { children: [_jsx("div", __assign({ className: "col-3 col-mobile-12" }, { children: _jsx(AccountInfoMenu, {}) })), _jsxs("div", __assign({ className: "col-9 col-mobile-12" }, { children: [_jsxs("div", __assign({ className: "card rounded-4 mb-4" }, { children: [_jsx("div", __assign({ className: "card-header" }, { children: _jsx("h2", { children: "Kay\u0131tl\u0131 Banka Hesaplar\u0131m" }) })), _jsxs("div", __assign({ className: "card-body mobile-scroll" }, { children: [_jsx("p", __assign({ className: "white-space-normal" }, { children: "Kay\u0131tl\u0131 olan banka hesaplar\u0131n\u0131z\u0131 a\u015Fa\u011F\u0131da g\u00F6rebilirsiniz. Para \u00E7ekim talebi olu\u015Fturabilmek i\u00E7in l\u00FCtfen en az bir adet kendi ad\u0131n\u0131za a\u00E7\u0131lm\u0131\u015F banka hesab\u0131 ekleyiniz." })), !accountList && _jsx("div", { className: "loader" }), accountList && (_jsxs("table", __assign({ className: "table table-striped" }, { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("td", { children: "Banka" }), _jsx("td", { children: "IBAN" }), _jsx("td", {})] }) }), _jsx("tbody", { children: accountList.map(function (el, key) {
                                                            return (_jsxs("tr", { children: [_jsx("td", __assign({ className: "nowrap" }, { children: el.bank_name })), _jsx("td", { children: el.iban }), _jsx("td", __assign({ className: "text-end" }, { children: _jsx("button", __assign({ className: "btn btn-sm btn-danger", onClick: function () {
                                                                                modalServiceSubject.next({
                                                                                    class: "info",
                                                                                    title: "Hesap Sil",
                                                                                    content: "<p>Kay\u0131tl\u0131 banka hesab\u0131n\u0131z\u0131 silmek istedi\u011Finizden emin misiniz?</p>",
                                                                                    confirm: {
                                                                                        cancel: { label: "Vazgeç" },
                                                                                        sure: {
                                                                                            class: "btn-danger",
                                                                                            label: "Sil",
                                                                                            action: function () {
                                                                                                deleteAccount(el, key);
                                                                                            },
                                                                                        },
                                                                                    },
                                                                                });
                                                                            } }, { children: "Sil" })) }))] }, "account-activity-".concat(key)));
                                                        }) })] }))), _jsx("div", { children: _jsxs("button", __assign({ className: "btn btn-primary d-flex align-items-center", onClick: function () {
                                                        setNewAccountModal(true);
                                                    } }, { children: [_jsx("img", { src: iconPlus, width: 18 }), "Banka Hesab\u0131 Tan\u0131mla"] })) })] }))] })), _jsxs("div", __assign({ className: "card rounded-4 mt-4" }, { children: [_jsx("div", __assign({ className: "card-header" }, { children: _jsx("h2", { children: "Hesap G\u00FCvenli\u011Fi" }) })), _jsx("div", __assign({ className: "card-body" }, { children: _jsxs("p", __assign({ className: "white-space-normal" }, { children: ["Hesab\u0131n\u0131z\u0131n g\u00FCvenli\u011Fi bizim i\u00E7in \u00F6nemlidir. E\u011Fer hesab\u0131n\u0131z ile ilgili \u015F\u00FCpheli bir durum varsa bize ", _jsx("b", { children: "0850 241 90 99 " }), "numaral\u0131 telefon numaras\u0131ndan ula\u015Fabilirsiniz."] })) }))] }))] }))] })), _jsx(NewBankAccount, { setNewAccountModal: setNewAccountModal, getListAccount: getListAccount, setAccountList: setAccountList, newAccountModal: newAccountModal })] }));
}
export function NewBankAccount(props) {
    var newAccountModal = props.newAccountModal;
    var setNewAccountModal = props.setNewAccountModal;
    var setAccountList = props.setAccountList;
    var getListAccount = props.getListAccount;
    var _a = useForm(), register = _a.register, handleSubmit = _a.handleSubmit, reset = _a.reset, errors = _a.formState.errors;
    var onSubmit = function (payload) {
        payload.iban = payload.iban.replaceAll(" ", "");
        api
            .start("post", env.accounting_api + "/member/api/create-member-account/", payload, true)
            .then(function (res) {
            if (res && res.status) {
                setNewAccountModal(false);
                toast.success("Hesap başarıyla eklendi.");
                // reload page
                window.location.reload();
                // setAccountList(res.data);
                getListAccount();
                reset({ iban: "", name: "" });
            }
            else {
                toast.error(res.message);
            }
        });
    };
    return (_jsx(Modal, __assign({ isOpen: newAccountModal, className: "default", onRequestClose: function () {
            setNewAccountModal(false);
        }, contentLabel: "" }, { children: _jsxs("form", __assign({ onSubmit: handleSubmit(onSubmit) }, { children: [_jsxs("div", __assign({ className: "modal-body pb-5" }, { children: [_jsx("h2", { children: "Banka Hesab\u0131 Ekle" }), _jsx("p", __assign({ className: "white-space-normal" }, { children: "Sadece kendi ad\u0131n\u0131za a\u00E7\u0131lm\u0131\u015F bir banka hesab\u0131 tan\u0131mlay\u0131n\u0131z." })), _jsxs("div", __assign({ className: "mt-2" }, { children: [_jsx("label", { children: "IBAN Numaran\u0131z" }), _jsx(InputMask, __assign({ mask: "TR99 9999 9999 9999 9999 9999 99", defaultValue: "TR", className: "form-control", placeholder: "TRXX XXXX XXXX XXXX XXXX XXXX XX" }, register("iban", { required: true }))), errors.iban && (_jsx("div", __assign({ className: "invalid-feedback d-block" }, { children: "IBAN numaran\u0131z\u0131 giriniz." })))] })), _jsxs("div", __assign({ className: "mt-2" }, { children: [_jsx("label", { children: "Hesap Tan\u0131m\u0131" }), _jsx("input", __assign({ className: "form-control", placeholder: "Hesabım" }, register("name", { required: false })))] }))] })), _jsxs("div", __assign({ className: "modal-ticket-footer text-end" }, { children: [_jsx("div", { className: "wave" }), _jsx("div", __assign({ className: "body-row row" }, { children: _jsx("div", __assign({ className: "col-12" }, { children: _jsx("button", __assign({ className: "btn w-100 btn-success" }, { children: "Kaydet" })) })) }))] }))] })) })));
}
