var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { env } from "../../constants/global.constants";
import { api } from "../../services/http.service";
import "./products.css";
import { modalServiceSubject } from "../../services/modal.service";
import { useNavigate, useParams } from "react-router";
import { ProductDetail } from "./detail";
import Modal from "react-modal";
import { CurrencyFormat, dateTimeFormat, gtmSelectItem, gtmViewItem, gtmViewItemList } from "../../services/misc.functions";
import { isMobile } from "../../App";
import { auth } from "../../store/auth.store";
import paths from "../../paths";
var requestStatusMap = {
    0: {
        class: "warning",
        label: "Beklemede",
    },
    1: {
        class: "success",
        label: "Onaylandı",
    },
    2: {
        class: "danger",
        label: "Stokta Yok",
    },
    3: {
        class: "info",
        label: "Gönderildi",
    },
};
export function Products() {
    var _a = useState([]), dataList = _a[0], setDataList = _a[1];
    var _b = useState([]), requestList = _b[0], setRequestList = _b[1];
    var _c = useState(0), selectedTab = _c[0], setSelectedTab = _c[1];
    var params = useParams();
    var navigate = useNavigate();
    useEffect(function () {
        getRequests();
        api
            .start("get", env.accounting_api + "/product/api/get-products/", null, false)
            .then(function (res) {
            // console.log(res)
            setDataList(res.data);
            gtmViewItemList(res.data, 'Ödüller');
        });
    }, []);
    var getRequests = function () {
        api
            .start("get", env.accounting_api + "/product/api/list-prize-request/", null, true)
            .then(function (res) {
            // console.log(res)
            if (res && res.status) {
                setRequestList(res.data);
            }
        });
    };
    if ((params === null || params === void 0 ? void 0 : params.id) && dataList && dataList.length > 0) {
        var product = dataList.find(function (el) { return el.id === Number(params.id); });
        return _jsx(ProductDetail, { product: product });
    }
    return (_jsxs(_Fragment, { children: [isMobile && (_jsx("div", __assign({ className: "row inner-page-top-tabs mb-4" }, { children: _jsxs("div", __assign({ className: "liner-tabs", style: { "--tab-count": auth.member ? "2" : "1" } }, { children: [_jsx("input", { type: "radio", value: "0", checked: selectedTab === 0, "data-rel": "tab1", id: "raffle-id_tab-1", onChange: function () {
                                setSelectedTab(0);
                            }, name: "tabs" }), _jsx("label", __assign({ className: "tab text-center", htmlFor: "raffle-id_tab-1" }, { children: _jsx("h1", __assign({ className: "fs-6 fw-bold mb-0" }, { children: "\u00D6d\u00FCller" })) })), auth.member && _jsxs(_Fragment, { children: [_jsx("input", { type: "radio", value: "1", "data-rel": "tab2", checked: selectedTab === 1, id: "raffle-id_tab-2", onChange: function () {
                                        setSelectedTab(1);
                                    }, name: "tabs" }), _jsx("label", __assign({ className: "tab text-center", htmlFor: "raffle-id_tab-2" }, { children: "Taleplerim" }))] }), _jsx("span", { className: "glider" })] })) }))), !isMobile && (_jsx("div", __assign({ className: "row" }, { children: _jsx("div", __assign({ className: " mb-3" }, { children: _jsxs("div", __assign({ className: "slider-tabs", style: { "--tab-count": auth.member ? "2" : "1" } }, { children: [_jsx("input", { type: "radio", value: "0", checked: selectedTab === 0, "data-rel": "tab1", id: "raffle-id_tab-1", onChange: function () {
                                    setSelectedTab(0);
                                }, name: "tabs" }), _jsx("label", __assign({ className: "tab", htmlFor: "raffle-id_tab-1" }, { children: "\u00D6d\u00FCller" })), auth.member && _jsxs(_Fragment, { children: [_jsx("input", { type: "radio", checked: selectedTab === 1, value: "1", "data-rel": "tab2", id: "raffle-id_tab-2", onChange: function () {
                                            setSelectedTab(1);
                                        }, name: "tabs" }), _jsx("label", __assign({ className: "tab", htmlFor: "raffle-id_tab-2" }, { children: "Taleplerim" }))] }), _jsx("span", { className: "glider" })] })) })) }))), _jsxs("div", __assign({ className: "row" }, { children: [selectedTab == 0 && (_jsx(_Fragment, { children: dataList &&
                            dataList.map(function (el, key) {
                                return (_jsx("div", __assign({ className: "col-6 col-md-4 col-lg-3 my-2" }, { children: _jsx(Product, { product: el }) }), "".concat(key)));
                            }) })), selectedTab == 1 && (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "prize-request" }, { children: [requestList.length == 0 && _jsx(_Fragment, { children: _jsxs("div", __assign({ className: "w-100" }, { children: [_jsx("p", __assign({ className: "text-center" }, { children: "Hen\u00FCz bir talebiniz bulunmamaktad\u0131r." })), _jsx("div", __assign({ className: "d-flex justify-content-center" }, { children: _jsx("button", __assign({ className: "mx-auto btn btn-primary w-50 mt-5", onClick: function () {
                                                        setSelectedTab(0);
                                                    } }, { children: "\u00D6d\u00FClleri \u0130ncele" })) }))] })) }), requestList.map(function (el) {
                                    return (_jsxs("div", __assign({ className: "row item" }, { children: [_jsx("div", __assign({ className: "col-auto d-flex justify-content-center align-items-center" }, { children: _jsx("img", { className: "img-thumbnail", src: "".concat(env.cdn_host, "/media/").concat(el.product__ProductPicture) }) })), _jsxs("div", __assign({ className: "col-8" }, { children: [_jsx("div", __assign({ className: "status ".concat(requestStatusMap[el.status].class, " mb-2") }, { children: requestStatusMap[el.status].label })), _jsx("h3", { children: el.name }), _jsxs("span", __assign({ className: "d-block" }, { children: ["Talep Tarihi: ", dateTimeFormat(el.create_date)] })), _jsxs("span", __assign({ className: "d-block" }, { children: ["Talep Adedi: ", Number(el.amount).toFixed(0)] }))] }))] }), "request-item-" + el.id));
                                })] })) }))] }))] }));
}
var errorMessagesMap = {
    member_error: "Üye girişi yapmalısınız.",
    product_error: "Ürün bulunamadı.",
    balance_point_available_error: "Yetersiz Bakiye",
};
export function Product(props) {
    var _a = useState(false), added = _a[0], setAdded = _a[1];
    var product = props.product;
    var _b = useState(null), itemModal = _b[0], setItemModal = _b[1];
    var navigate = useNavigate();
    var createRequest = function (product) {
        if (auth.member) {
            if (added && added > 0) {
                api
                    .start("post", env.accounting_api + "/product/api/create-prize-request/", {
                    product_id: product.id,
                    amount: added,
                }, true)
                    .then(function (res) {
                    if (res && res.status) {
                        setAdded(false);
                        modalServiceSubject.next({
                            case: "success",
                            title: "Talep Aldık!",
                            content: "<p class=\"text-center\">Talebiniz olu\u015Fturuldu. Talep kontrol\u00FC sonras\u0131nda sizleri eposta ve sms yolu ile bilgilendirece\u011Fiz. \n               E\u011Fer eposta yada SMS alm\u0131yorsan\u0131z l\u00FCtfen ileti\u015Fim ayarlar\u0131n\u0131z\u0131 kontrol edin ve operat\u00F6re\u00FCn\u00FCzden gerekli izinleri talep edin.\n              </p>",
                            confirm: {
                                cancel: {
                                    label: "Tamam",
                                    class: "btn-success w-100",
                                },
                            },
                        });
                    }
                    else {
                        modalServiceSubject.next({
                            case: "danger",
                            title: errorMessagesMap[res.error] || "Bakiyeniz Yetersiz 😞",
                            content: "<p class=\"text-center\">\n                ".concat(res.message, "\n                \n                <br />\u00D6d\u00FCl katalo\u011Fundan sadece oyunlarda kazand\u0131\u011F\u0131n\u0131z \u015EP puanlar\u0131n\u0131z ile \u00F6d\u00FCl talep edebilirsiniz.<br /><br />\n                \u015Eans oyunlar\u0131m\u0131zdan oynayarak \u015EP Puan kazanabilir ve \u00F6d\u00FCl\u00FCn\u00FCz\u00FC talep edebilirsiniz.  \n               \n              </p>"),
                            confirm: {
                                cancel: {
                                    label: "Tamam",
                                    class: "btn-danger w-100",
                                },
                            },
                        });
                    }
                });
            }
        }
        else {
            if (isMobile) {
                navigate(paths.landing.url);
            }
            else {
                modalServiceSubject.next({
                    class: "success",
                    title: "Üye Girişi",
                    content: "<p>Sat\u0131nalma yapabilmeniz i\u00E7in \u00F6nce oturum a\u00E7man\u0131z gerekli. \u00DCye de\u011Filseniz hemen \u00FCye olabilir yada giri\u015F yaparak al\u0131\u015Fveri\u015Finizi tamamlaya bilirsiniz.</p>",
                    confirm: {
                        sure: {
                            label: "Giriş Yap",
                            class: "btn btn-success",
                            action: function () {
                                navigate(paths.login.url);
                            },
                        },
                        cancel: {
                            label: "Üye Ol",
                            class: "btn btn-info",
                            action: function () {
                                navigate(paths.register.url);
                            },
                        },
                    },
                });
            }
        }
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "product-widget" }, { children: [_jsx("div", __assign({ className: "head pointer", onClick: function () {
                            setItemModal(product);
                            gtmSelectItem([product], 'Ödüller');
                        } }, { children: _jsx("h2", { children: product.name }) })), _jsx("div", __assign({ className: "body pointer", onClick: function () {
                            setItemModal(product);
                            gtmSelectItem([product], 'Ödüller');
                        } }, { children: _jsx("img", { src: env.cdn_host + "/media/" + product.ProductPicture, alt: product.code }) })), _jsxs("div", __assign({ className: "foot" }, { children: [added !== false && (_jsxs("div", __assign({ className: "add" }, { children: [_jsxs("div", __assign({ className: "input w-50" }, { children: [_jsx("i", __assign({ onClick: function () {
                                                    if (added > 1) {
                                                        setAdded(added - 1);
                                                    }
                                                    else {
                                                        setAdded(false);
                                                    }
                                                } }, { children: "-" })), _jsx("input", { disabled: true, className: "no-arrows", type: "number", value: added || "", onChange: function (e) {
                                                    setAdded(e.target.value);
                                                }, onBlur: function (e) {
                                                    if (e.target.value === "") {
                                                        setAdded(false);
                                                    }
                                                }, max: 100, step: 1, min: 1 }), _jsx("i", __assign({ onClick: function () {
                                                    setAdded(added + 1);
                                                } }, { children: "+" }))] })), _jsx("div", __assign({ className: "price w-50" }, { children: _jsxs("span", { children: [CurrencyFormat(product.price, 0), "\u20BA"] }) }))] }))), _jsxs("button", __assign({ className: added !== false ? "added" : "", onClick: function () {
                                    if (added === false) {
                                        setAdded(1);
                                    }
                                    else {
                                        modalServiceSubject.next({
                                            case: "info",
                                            title: "Talep Onayı",
                                            content: "<p><b>".concat(CurrencyFormat(product.price, 0), " \u015EP</b> kar\u015F\u0131l\u0131\u011F\u0131nda <b>").concat(added, " adet</b> ").concat(product.name, " talebini onayl\u0131yor musunuz?</p>"),
                                            confirm: {
                                                cancel: {
                                                    class: "btn btn-outline-danger",
                                                    label: "Vazgeç",
                                                },
                                                sure: {
                                                    class: "btn btn-success",
                                                    label: "Onayla",
                                                    action: function () {
                                                        createRequest(product);
                                                    },
                                                },
                                            },
                                        });
                                    }
                                } }, { children: ["Talep Olu\u015Ftur ", _jsxs("span", __assign({ className: "price" }, { children: [CurrencyFormat(product.price, 0), " \u015EP"] }))] }))] }))] })), _jsx(Modal, __assign({ isOpen: itemModal !== null, className: "default product-detail-modal", onRequestClose: function () {
                    setItemModal(null);
                }, onAfterOpen: function () {
                    gtmViewItem([itemModal], 'Ödüller');
                }, contentLabel: "" }, { children: itemModal && (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "head" }, { children: _jsx("h2", { children: itemModal.name }) })), _jsxs("div", __assign({ className: "modal-body" }, { children: [_jsx("img", { className: "picture", src: env.cdn_host + "/media/" + itemModal.ProductPicture, alt: product.code }), _jsx("div", { className: "info", dangerouslySetInnerHTML: { __html: itemModal.description.replaceAll('\n', '<br/>') } })] })), _jsxs("div", __assign({ className: "modal-ticket-footer text-end" }, { children: [_jsx("div", { className: "wave" }), _jsx("div", __assign({ className: "body-row row" }, { children: _jsx("div", __assign({ className: "col-12" }, { children: _jsx("button", __assign({ type: "button", onClick: function () {
                                                setItemModal(null);
                                            }, className: "btn w-100 custom-btn-danger me-2" }, { children: "Kapat" })) })) }))] }))] })) }))] }));
}
