var Member = /** @class */ (function () {
    function Member(obj) {
        this.address = obj.address;
        this.birthday = obj.birthday;
        this.city = obj.city;
        this.county = obj.county;
        this.create_date = obj.create_date;
        this.date_joined = obj.date_joined;
        this.balance_debit = obj.balance_debit;
        this.balance_debit_available = obj.balance_debit_available;
        this.display_label = obj.display_label;
        this.email = obj.email;
        this.father_name = obj.father_name;
        this.favorite_team_id = obj.favorite_team_id;
        this.first_name = obj.first_name;
        this.gender = obj.gender;
        this.get_gender_display = obj.get_gender_display;
        this.get_status_display = obj.get_status_display;
        this.groups = obj.groups;
        this.id = obj.id;
        this.id_no = obj.id_no;
        this.is_editor = obj.is_editor;
        this.is_tipster = obj.is_tipster;
        this.id_serial = obj.id_serial;
        this.is_active = obj.is_active;
        this.is_partner = obj.is_partner;
        this.is_staff = obj.is_staff;
        this.is_superuser = obj.is_superuser;
        this.last_login = obj.last_login;
        this.last_name = obj.last_name;
        this.mail_notification = obj.mail_notification;
        this.mobile = obj.mobile;
        this.mother_name = obj.mother_name;
        this.nick_name = obj.nick_name;
        this.balance_point = obj.balance_point;
        this.balance_point_available = obj.balance_point_available;
        this.push_notification = obj.push_notification;
        this.call_notification = obj.call_notification;
        this.referer = obj.referer;
        this.secure_answer = obj.secure_answer;
        this.secure_question = obj.secure_question;
        this.sms_notification = obj.sms_notification;
        this.status = obj.status;
        this.subscribed = obj.subscribed;
        this.tc_id = obj.tc_id;
        this.update_date = obj.update_date;
        this.username = obj.username;
        this.max_rank = obj.max_rank;
        this.email_validation = obj.email_validation;
        this.mobile_validation = obj.mobile_validation;
        this.campaign_agreement = obj.campaign_agreement;
        this.game_start_notification = obj.game_start_notification;
        this.live_draw_notification = obj.live_draw_notification;
        this.new_draw_notification = obj.new_draw_notification;
        this.new_game_notification = obj.new_game_notification;
        this.satisfaction_survey_notification = obj.satisfaction_survey_notification;
        this.notication_count = obj.notication_count;
        this.password = obj.password;
    }
    Member.prototype.getFullName = function () {
        return this.first_name + " " + this.last_name;
    };
    Member.prototype.getAvailableBalance = function () {
        return Number(this.balance_debit) + (Number(this.balance_point) / 100);
    };
    return Member;
}());
export { Member };
