var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useParams } from "react-router";
import { SupportTicket } from "./support.ticket";
import { Link, useLocation } from "react-router-dom";
import "./support.css";
import { SupportTicketList } from "./support.ticket.list";
import { SupportFAQ } from "./support.faq";
import { SupportContact } from "./support.contact";
import { isMobile } from "../../App";
import { auth } from "../../store/auth.store";
import paths from "../../paths";
var subPages = {
    'cozum-merkezi': _jsx(SupportTicket, {}),
    'destek-taleplerim': _jsx(SupportTicketList, {}),
    'sikca-sorulan-sorular': _jsx(SupportFAQ, {}),
    'iletisim-bilgileri': _jsx(SupportContact, {}),
};
export function Support() {
    var param = useParams();
    if (param.page && subPages[param.page]) {
        return subPages[param.page];
    }
    return (_jsx(_Fragment, {}));
}
export function SupportMenu() {
    var pathname = useLocation().pathname;
    return (_jsxs("div", __assign({ className: "subpage-menu ".concat(isMobile ? 'liner-tabs' : '') }, { children: [!isMobile && _jsx("h3", { children: "YARDIM" }), _jsx(Link, __assign({ to: "".concat(paths.support.url, "/cozum-merkezi/"), className: pathname.includes('cozum-merkezi') ? 'active' : '' }, { children: "\u00C7\u00F6z\u00FCm Merkezi" })), auth.member && auth.member.id &&
                _jsx(Link, __assign({ to: "".concat(paths.support.url, "/destek-taleplerim/"), className: pathname.includes('destek-taleplerim') ? 'active' : '' }, { children: "Destek Taleplerim" })), _jsx(Link, __assign({ to: "".concat(paths.support.url, "/sikca-sorulan-sorular/"), className: pathname.includes('sikca-sorulan-sorular') ? 'active' : '' }, { children: "S\u0131k\u00E7a Sorulan Sorular" })), _jsx(Link, __assign({ to: "".concat(paths.support.url, "/iletisim-bilgileri/"), className: pathname.includes('iletisim-bilgileri') ? 'active' : '' }, { children: "\u0130leti\u015Fim Bilgileri" }))] })));
}
