var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import "./ticket.css";
import { useEffect } from "react";
import { openRaffleDetail } from "../../services/game.service";
import { addToCart, removeFromCart, updateCart, } from "../../services/cart.service";
import { cartStore, cartToggleSubject } from "../../store/cart.store";
import { useForceUpdate } from "../../services/misc.functions";
import { isMobile } from "../../App";
import { raffleDrawSubject } from "../../store/games.store";
export function Ticket(props) {
    var _a;
    var forceUpdate = useForceUpdate();
    var maskColor = props.maskColor;
    useEffect(function () {
        var cartToggleSubject_subscriber = cartToggleSubject.subscribe(function (res) {
            if ((res &&
                res.item &&
                res.item.item &&
                res.item.item.id === props.item.id) ||
                (res.list && res.list.includes(props.item.id))) {
                forceUpdate();
            }
        });
        return function () {
            cartToggleSubject_subscriber.unsubscribe();
        };
    }, []);
    useEffect(function () {
        var cartToggleSubject_subscriber = raffleDrawSubject.subscribe(function (res) {
            if (res.action == "update" && res.id == props.item.id) {
                forceUpdate();
            }
        });
        return function () {
            cartToggleSubject_subscriber.unsubscribe();
        };
    }, []);
    var ticket = props.item;
    return (_jsxs("div", __assign({ className: "ticket-card pointer", style: { "--ticket-mask-color": maskColor } }, { children: [_jsxs("div", __assign({ className: "head" }, { children: [_jsx("div", __assign({ className: "flags py-2" }, { children: !ticket.title_abbreviated.toLowerCase().includes('çoklu eşya') ?
                            _jsxs(_Fragment, { children: [ticket.draw_price && ticket.draw_price.length > 0 ? ticket.draw_price[0].price_count : '0', _jsx("br", {}), "Adet"] }) : _jsxs(_Fragment, { children: [" ", _jsx("br", {}), " "] }) })), _jsx("div", __assign({ className: "circle-sold" }, { children: _jsx("div", { children: _jsxs("div", __assign({ className: "pie animate", style: {
                                    "--w": "60px",
                                    "--p": ticket.leftTicketPerc(),
                                    "--b": "7px",
                                    "--c": "".concat(ticket.leftTicketPercColor()),
                                } }, { children: [ticket.leftTicketPerc(), "%", _jsx("span", { children: "SATILAN" })] })) }) })), _jsx("div", __assign({ className: "timer" }, { children: _jsxs("span", { children: [ticket.leftDays(), " ", _jsx("br", {}), ticket.leftDays() != 'Bugün' && 'Gün'] }) }))] })), _jsxs("div", __assign({ className: "body ".concat(isMobile && "mobile-ticket"), onClick: function () {
                    openRaffleDetail(ticket);
                } }, { children: [_jsxs("div", __assign({ className: "flags" }, { children: [ticket.is_new && (_jsx("span", __assign({ className: "px-2 text-white badge rounded-pill bg-primary" }, { children: "\u2B50 Yeni" }))), ticket.is_popular && (_jsx("span", __assign({ className: "px-2 text-white badge rounded-pill bg-warning" }, { children: "\uD83D\uDD25 Pop\u00FCler" }))), ticket.best_selling && (_jsx("span", __assign({ className: "px-2 text-white badge rounded-pill bg-warning" }, { children: "\u00C7ok Satan" }))), ticket.second_hand && (_jsx("span", __assign({ className: "px-2 text-white badge rounded-pill bg-secondary" }, { children: "2.El" }))), ticket.is_running_out && (_jsx("span", __assign({ className: "px-2 text-white badge rounded-pill bg-success" }, { children: "\uD83D\uDCA5 T\u00FCkeniyor" })))] })), _jsx("img", { src: ticket.photo }), _jsx("h3", __assign({ className: "mt-2" }, { children: ticket.title_abbreviated }))] })), _jsxs("div", __assign({ className: "foot" }, { children: [ticket.is_added && (_jsxs("div", __assign({ className: "add" }, { children: [_jsxs("div", __assign({ className: "input w-60" }, { children: [_jsx("i", __assign({ onClick: function () {
                                            var _a, _b;
                                            if (cartStore.items[ticket.cartIndex] &&
                                                ((_a = cartStore.items[ticket.cartIndex]) === null || _a === void 0 ? void 0 : _a.quantity) > 0) {
                                                updateCart(cartStore.items[ticket.cartIndex], ticket.cartIndex, Number((_b = cartStore.items[ticket.cartIndex]) === null || _b === void 0 ? void 0 : _b.quantity) - 1);
                                            }
                                        } }, { children: "-" })), _jsx("input", { className: "no-arrows", type: "number", value: ((_a = cartStore.items[ticket.cartIndex]) === null || _a === void 0 ? void 0 : _a.quantity) || "", onChange: function (e) {
                                            updateCart(cartStore.items[ticket.cartIndex], ticket.cartIndex, e.target.value);
                                        }, onBlur: function (e) {
                                            if (e.target.value === "" || e.target.value === "0") {
                                                removeFromCart(cartStore.items[ticket.cartIndex], ticket.cartIndex);
                                            }
                                        }, max: 100, step: 1, min: 1 }), _jsx("i", __assign({ onClick: function () {
                                            var _a, _b;
                                            if (cartStore.items[ticket.cartIndex] &&
                                                ((_a = cartStore.items[ticket.cartIndex]) === null || _a === void 0 ? void 0 : _a.quantity) < 100 &&
                                                ((_b = cartStore.items[ticket.cartIndex]) === null || _b === void 0 ? void 0 : _b.quantity) <
                                                    ticket.remaining_tickets) {
                                                updateCart(cartStore.items[ticket.cartIndex], ticket.cartIndex, Number(cartStore.items[ticket.cartIndex].quantity) + 1);
                                            }
                                        } }, { children: "+" }))] })), _jsx("div", __assign({ className: "price w-40" }, { children: cartStore.items[ticket.cartIndex] &&
                                    _jsxs("span", { children: [Number(ticket.ticket_price *
                                                (Number(cartStore.items[ticket.cartIndex].quantity) != 0
                                                    ? Number(cartStore.items[ticket.cartIndex].quantity)
                                                    : 1)).toFixed(2), "\u20BA"] }) }))] }))), _jsxs(_Fragment, { children: [ticket.is_added !== true && ticket.remaining_tickets > 0 && (_jsxs("button", __assign({ onClick: function () {
                                    addToCart(ticket, 1, "raffle");
                                }, className: "btn btn-add-to-cart w-100 btn-success rounded-pill" }, { children: ["Bilet Al", _jsxs("span", __assign({ className: "price" }, { children: [Number(ticket.ticket_price).toFixed(2), "\u20BA"] }))] }))), ticket.remaining_tickets === 0 && (_jsx("button", __assign({ className: "btn fw-bold w-100 text-center btn-secondary rounded-pill" }, { children: "T\u00FCkendi" })))] })] }))] })));
}
