var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link, useLocation } from "react-router-dom";
import "./header.css";
import iconCart from "../../assets/icons/feather/shopping-cart.svg";
import iconPlusCircle from "../../assets/icons/feather/plus-circle.svg";
import iconLiveStrem from "../../assets/icons/feather/play-circle.svg";
import iconMenu from "../../assets/icons/feather/menu.svg";
import logoESansRenkli from "../../assets/images/logo-renkli.png";
import { listCartHiddenPages } from "../../constants/global.constants";
import { useEffect, useState } from "react";
import ForgetPassword from "../auth/forgetpassword";
import { useNavigate } from "react-router";
import Login from "../auth/login";
import { auth, authSubject, loginModalSubject, sideMenuSubject, } from "../../store/auth.store";
import { copyMemberNumber, useForceUpdate, } from "../../services/misc.functions";
import { isMobile } from "../../App";
import { cartStore, cartToggleSubject } from "../../store/cart.store";
import { CartDropdown } from "../cart/cart.dropdown";
import iconNotifications from "../../assets/icons/feather/bell.svg";
import paths from "../../paths";
var cartItemCount = 0;
export function Header() {
    var pathname = useLocation().pathname;
    var _a = useState(false), visible = _a[0], setVisible = _a[1];
    var _b = useState(true), hidden = _b[0], setHidden = _b[1];
    var _c = useState(false), loginModal = _c[0], showLoginModal = _c[1];
    var _d = useState(false), passwordModal = _d[0], showPasswordModal = _d[1];
    var forceUpdate = useForceUpdate();
    var navigate = useNavigate();
    var goTop = function () {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    useEffect(function () {
        var cartToggleSubject_subscriber = cartToggleSubject.subscribe(function () {
            cartItemCount =
                cartStore.items.length > 0
                    ? cartStore.items
                        .map(function (el) { return el.quantity; })
                        .reduce(function (a, b) {
                        return Number(a) + Number(b);
                    })
                    : 0;
            forceUpdate();
        });
        return function () {
            cartToggleSubject_subscriber.unsubscribe();
        };
    }, []);
    useEffect(function () {
        var urlName = pathname.endsWith('/') ? pathname.slice(0, -1) : pathname;
        if (listCartHiddenPages.includes(urlName)) {
            setVisible(false);
        }
        else {
            setVisible(true);
        }
    }, [pathname]);
    function closePasswordModal() {
        showPasswordModal(false);
    }
    useEffect(function () {
        var auth_subscriber = authSubject.subscribe(function (res) {
            forceUpdate();
        });
        return function () {
            auth_subscriber.unsubscribe();
        };
    }, []);
    useEffect(function () {
        var auth_subscriber = loginModalSubject.subscribe(function (res) {
            if (res === "show") {
                // showLoginModal(true);
                navigate(paths.login.url);
            }
            if (res === "hide") {
                showLoginModal(false);
            }
        });
        return function () {
            auth_subscriber.unsubscribe();
        };
    }, []);
    var mainWrapper = document.getElementById("mainWrapper");
    if (!visible) {
        if (mainWrapper) {
            mainWrapper.style.background = "unset";
        }
        return _jsx(_Fragment, {});
    }
    // if(mainWrapper){ mainWrapper.style.background = 'var(--ps-body-bg)'; }
    var hideCart = function () {
        cartToggleSubject.next({ action: "visibleToggleCart", show: false });
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ id: "myHeader", className: "" }, { children: _jsxs("header", __assign({ id: "fixedHeader", className: "container-xl pr-3 fixed-header" }, { children: [_jsx("div", __assign({ id: "branding", className: "branding pointer", onClick: function () {
                                if (isMobile) {
                                    hideCart();
                                }
                                if (pathname === paths.home.url) {
                                    goTop();
                                }
                                else {
                                    navigate(paths.home.url);
                                }
                            } }, { children: _jsx("img", { src: logoESansRenkli }) })), _jsxs("div", __assign({ className: "right-side-header" }, { children: [!isMobile && (_jsxs("ul", __assign({ className: "navbar" }, { children: [_jsx("li", __assign({ className: pathname.includes(paths.raffleTickets.url)
                                                ? "active"
                                                : undefined }, { children: _jsx(Link, __assign({ to: paths.raffleTickets.url }, { children: "\u00C7ekili\u015Fler" })) })), _jsx("li", __assign({ className: pathname.includes(paths.games.url) ? "active" : undefined }, { children: _jsx(Link, __assign({ to: paths.games.url }, { children: "Oyunlar" })) })), _jsx("li", __assign({ className: pathname.includes(paths.products.url) ? "active" : undefined }, { children: _jsx(Link, __assign({ to: paths.products.url }, { children: "\u00D6d\u00FCller" })) })), _jsx("li", __assign({ className: pathname.includes(paths.campaign.url) ? "active" : undefined }, { children: _jsx(Link, __assign({ to: paths.campaign.url }, { children: "Kampanyalar" })) })), _jsx("li", __assign({ className: "".concat(pathname.includes(paths.liveStream.url) ? "active" : undefined, " live-stream") }, { children: _jsxs(Link, __assign({ to: paths.liveStream.url }, { children: [_jsx("img", { src: iconLiveStrem }), "Canl\u0131 \u0130zle"] })) }))] }))), _jsx("ul", __assign({ className: "auth-widget float-end" }, { children: auth.authentication && auth.member && auth.member.id ? (_jsxs(_Fragment, { children: [_jsxs("li", __assign({ className: "balance pointer", onClick: function () {
                                                    navigate(paths.deposit.url);
                                                } }, { children: [_jsx(Link, __assign({ to: paths.deposit.url }, { children: _jsx("img", { src: iconPlusCircle }) })), !isMobile && _jsx("div", __assign({ className: "label" }, { children: "Bakiye:" })), _jsxs("div", __assign({ className: "lines" }, { children: [_jsxs("span", { children: [auth.member.balance_debit, "\u20BA"] }), _jsxs("span", { children: [Number(auth.member.balance_point).toFixed(2), " \u015EP"] })] }))] })), !isMobile && _jsxs("li", __assign({ className: "notification pointer position-relative", onClick: function () {
                                                    navigate(paths.notifications.url);
                                                } }, { children: [_jsx("img", { src: iconNotifications }), auth.member.unread_notification_count &&
                                                        auth.member.unread_notification_count > 0 ? (_jsx("span", __assign({ className: "position-absolute px-2 translate-middle text-white badge rounded-pill bg-danger" }, { children: auth.member.unread_notification_count }))) : (_jsx(_Fragment, {}))] })), _jsxs("li", __assign({ className: "username", onClick: function () {
                                                    if (isMobile) {
                                                        sideMenuSubject.next(true);
                                                    }
                                                } }, { children: [!isMobile && (_jsxs(_Fragment, { children: [_jsx("img", { className: "pointer", src: iconMenu, onClick: function () {
                                                                    sideMenuSubject.next(true);
                                                                } }), _jsxs(Link, __assign({ to: "".concat(paths.account.url, "/hesap-bilgileri"), className: "d-block nowrap" }, { children: [auth.member.first_name, " ", auth.member.last_name, _jsx("span", { children: auth.member.id })] }))] })), isMobile && (_jsxs("span", __assign({ className: "d-flex justify-items-between" }, { children: [_jsxs("span", __assign({ style: { maxWidth: "80px" } }, { children: [_jsxs("a", __assign({ className: "nowrap pe-2 d-block" }, { children: [auth.member.first_name, " ", auth.member.last_name] })), _jsx("span", __assign({ onClick: function () {
                                                                            if (!isMobile) {
                                                                                copyMemberNumber(auth.member.id, "Üye numaranız kopyalandı!");
                                                                            }
                                                                        } }, { children: auth.member.id }))] })), _jsx("img", { className: "pointer", style: { marginLeft: "0px", marginBottom: "2px" }, src: iconMenu, onClick: function () {
                                                                    sideMenuSubject.next(true);
                                                                } })] })))] })), !isMobile && (_jsxs("li", __assign({ className: "notification pointer position-relative", onClick: function () {
                                                    if (![paths.raffleTickets.url].includes(pathname)) {
                                                        setHidden(!hidden);
                                                        cartToggleSubject.next({
                                                            action: "visibleToggleCart",
                                                            show: hidden,
                                                        });
                                                    }
                                                    else {
                                                        goTop();
                                                    }
                                                } }, { children: [_jsx("img", { src: iconCart }), cartItemCount > 0 ? (_jsx("span", __assign({ className: "position-absolute px-2 translate-middle text-white badge rounded-pill bg-danger" }, { children: cartItemCount }))) : (_jsx(_Fragment, {}))] }))), _jsx(CartDropdown, {})] })) : (_jsxs(_Fragment, { children: [_jsxs("li", { children: [_jsx("button", __assign({ onClick: function () {
                                                            // showLoginModal(true);
                                                            navigate(paths.login.url);
                                                        }, className: "btn header-auth-btn" }, { children: "Giri\u015F Yap" })), _jsx("button", __assign({ onClick: function () {
                                                            navigate(paths.register.url);
                                                            hideCart();
                                                        }, className: "btn header-auth-btn" }, { children: "\u00DCye Ol" }))] }), !isMobile && (_jsxs("li", __assign({ className: "notification pointer position-relative", onClick: function () {
                                                    if (![paths.raffleTickets.url].includes(pathname)) {
                                                        setHidden(!hidden);
                                                        cartToggleSubject.next({
                                                            action: "visibleToggleCart",
                                                            show: hidden,
                                                        });
                                                    }
                                                } }, { children: [_jsx("img", { src: iconCart }), cartItemCount > 0 ? (_jsx("span", __assign({ className: "position-absolute px-2 translate-middle text-white badge rounded-pill bg-danger" }, { children: cartItemCount }))) : (_jsx(_Fragment, {}))] }))), _jsx(CartDropdown, {})] })) })), _jsx("div", { className: "clearfix" })] }))] })) })), _jsx(Login, { loginModal: loginModal, showPasswordModal: showPasswordModal, showLoginModal: showLoginModal }), _jsx(ForgetPassword, { passwordModal: passwordModal, closePasswordModal: closePasswordModal })] }));
}
