var description = "Binlerce Muhteşem Ödülde Heyecana Ortak Ol! Web Sitemize Gel, Çekiliş Biletini Al,  84 Yıllık Mili Piyango Güvencesiyle, Hayalini Kurduğun Her Şey e-Şans'da.";
var keywords = "piyango, eşya piyangosu, kazı kazan, manyın tarlası, zeplin, milli piayngo";
var paths = {
    home: {
        url: "/",
        title: "e-Şans - Büyük Ödüller ve Heyecan Dolu Eşya Piyangoları Sizi Bekliyor!",
        description: "e-Şans ile eşsiz şans oyunlarına katılın. Türkiye'nin en popüler eşya piyangosuna hemen katılın, büyük ödülleri kazanın. Hızlı ve güvenilir.",
        keywords: "eşya piyangosu, büyük ödüller, şans oyunları, çekiliş, e-şans, yasal şans oyunları",
    },
    login: {
        url: "/giris-yap",
        title: "e-Şans Giriş - Büyük Ödülleri Kazanmak İçin Hemen Giriş Yapın",
        description: "e-Şans hesabınıza giriş yaparak eşya piyangolarına katılın ve büyük ödüller kazanma şansını yakalayın. Güvenli ve hızlı giriş.",
        keywords: "e-şans giriş, ödül kazan, çekiliş giriş, hesap girişi, şans oyunları",
    },
    register: {
        url: "/uye-ol",
        title: "e-Şans Üyelik - Heyecanlı Çekilişler ve Büyük Ödüller İçin Üye Olun",
        description: "e-Şans'a üye olarak Türkiye'nin en büyük çekilişlerinde yerinizi alın. Hemen üye olun, büyük ödüller kazanın!",
        keywords: "e-şans üye ol, çekiliş üyelik, büyük ödüller, ücretsiz üyelik, şans oyunları",
    },
    registerMarket: {
        url: "/uye-ol",
        title: "e-Şans Üyelik - Heyecanlı Çekilişler ve Büyük Ödüller İçin Üye Olun",
        description: "e-Şans'a üye olarak Türkiye'nin en büyük çekilişlerinde yerinizi alın. Hemen üye olun, büyük ödüller kazanın!",
        keywords: "e-şans üye ol, çekiliş üyelik, büyük ödüller, ücretsiz üyelik, şans oyunları",
    },
    resetPassword: {
        url: "/sifre-sifirla",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    verifyEmail: {
        url: "/eposta-dogrula",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    raffleTickets: {
        url: "/esya-piyangosu",
        title: "Eşya Piyangosu Biletleri - Büyük Ödüller İçin Hemen Katılın",
        description: "e-Şans eşya piyangosu biletleri ile büyük ödüllere ulaşma şansını kaçırmayın. Hemen bilet alın, kazanmaya başlayın!",
        keywords: "eşya piyangosu biletleri, büyük ödüller, çekiliş biletleri, e-şans bilet",
    },
    products: {
        url: "/oduller",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    liveStream: {
        url: "/canli-izle",
        title: "e-Şans Canlı Yayın - Çekilişleri Anında Takip Edin ve Kazananları Öğrenin",
        description: "e-Şans canlı yayın sayfasından çekilişleri anlık takip edin ve kazananları öğrenin. Heyecanı canlı yaşayın!",
        keywords: "canlı çekiliş, çekiliş canlı yayın, kazananlar, e-şans canlı takip",
    },
    campaign: {
        url: "/kampanyalar",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    deposit: {
        url: "/para-yatir",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    withdraw: {
        url: "/para-cek",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    account: {
        url: "/hesabim",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    games: {
        url: "/dijital-oyunlar",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    newGames: {
        url: "/dijital-oyunlar/yeni-oyunlar",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    upcomingGames: {
        url: "/dijital-oyunlar/gelecek-oyunlar",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    notifications: {
        url: "/bildirimlerim",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    agreement: {
        url: "/sozlesmeler",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    transactions: {
        url: "/hesap-hareketleri",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    support: {
        url: "/yardim",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    aboutus: {
        url: "/hakkimizda",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    landing: {
        url: "/landing",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    redirectPage: {
        url: "/uye-ol-basarili",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    giftTicket: {
        url: "/hediye-biletler",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    qr: {
        url: "/qr",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    reportLine: {
        url: "/ihbar-hatti",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    cookieSettings: {
        url: "/cerez-ayarlari",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    download: {
        url: "/uygulama-indir",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    scratch: {
        url: "/kazi-kazan",
        title: "Online Kazı Kazan - Hemen Oyna ve Büyük İkramiyeleri Yakala",
        description: "e-Şans'ta online kazı kazan oyunları ile büyük ikramiyeler kazanma şansını yakalayın. Kolay oynayın, büyük kazanın!",
        keywords: "online kazı kazan, kazı kazan oyna, büyük ikramiye, kazı kazan ödülleri, e-şans kazı kazan",
    },
    scratchMyCards: {
        url: "/kazi-kazan/kartlarim",
        title: "Onlne Kazı Kazan Oyna, Muhteşem Ödüller Kazan!",
        description: "Türkiyenin yeni yasal şans oyunları platformu E-Şansta online Kazı Kazan oyna, E-Şans ile muhteşem ödüller kazanma şansını yakala!",
        keywords: "kazı kazan oyna onliine kazı kazan kazı kazan nasıl oynanır kazı kazan büyük ikramiye şans kartı kazı kazan kuralları kazı kazan para çıkma olasılığı kazı kazan ikramiyeleri kazı kazan en yüksek ikramiye",
    },
    scartchRules: {
        url: "/kazi-kazan/kurallar",
        title: "Onlne Kazı Kazan Oyna, Muhteşem Ödüller Kazan!",
        description: "Türkiyenin yeni yasal şans oyunları platformu E-Şansta online Kazı Kazan oyna, E-Şans ile muhteşem ödüller kazanma şansını yakala!",
        keywords: "kazı kazan oyna onliine kazı kazan kazı kazan nasıl oynanır kazı kazan büyük ikramiye şans kartı kazı kazan kuralları kazı kazan para çıkma olasılığı kazı kazan ikramiyeleri kazı kazan en yüksek ikramiye",
    },
    superBingo: {
        url: "/super-tombala",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    turboMines: {
        url: "/isiltili-taslar",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    crashX: {
        url: "/galaksi-turu",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    turboPlinko: {
        url: "/super-plinko",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    hamsta: {
        url: "/kunduzun-macerasi",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    zeplin: {
        url: "/zeplin",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    fireballs: {
        url: "/ates-toplari",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    fruitTowes: {
        url: "/meyve-kulesi",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    superWheel: {
        url: "/super-carkifelek",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    rockPaperScissors: {
        url: "/tas-kagit-makas",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
    paparaCoupon: {
        url: "/kupon-kodu",
        title: "e-Şans Açıldı - Muhteşem Ödüller Sizi Bekliyor - e-Şans",
        description: description,
        keywords: keywords,
    },
};
export default paths;
