var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import "./games.css";
import { useEffect, useState } from "react";
import { Storage } from "../../services/localstorege.service";
import { api } from "../../services/http.service";
import { env } from "../../constants/global.constants";
import _ from "lodash";
import iconRight from "../../assets/icons/right-arrow.png";
import { isMobile } from "../../App";
import { gameNavigationMiddleware, gtmSelectItem, gtmViewItemList, slugify, useForceUpdate, } from "../../services/misc.functions";
import { useNavigate, useParams } from "react-router";
import { auth, authSubject } from "../../store/auth.store";
import paths from "../../paths";
export function Games() {
    var _this = this;
    var params = useParams();
    var forceUpdate = useForceUpdate();
    var _a = useState([]), gamesList = _a[0], setGamesList = _a[1];
    var navigate = useNavigate();
    useEffect(function () {
        getGames().then(function (res) {
            setGamesList(res);
            gtmViewItemList(res, "Oyunlar");
        });
    }, [params]);
    useEffect(function () {
        var auth_subscriber = authSubject.subscribe(function (res) {
            forceUpdate();
        });
        return function () {
            auth_subscriber.unsubscribe();
        };
    }, []);
    var getGames = function () { return __awaiter(_this, void 0, void 0, function () {
        var games;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    games = Storage.get("games");
                    if (games) {
                        if (params.subpage === "yeni-oyunlar") {
                            return [2 /*return*/, games.filter(function (game) {
                                    return game.is_active === true;
                                })];
                        }
                        else if (params.subpage === "gelecek-oyunlar") {
                            return [2 /*return*/, games.filter(function (game) {
                                    return game.is_active === false;
                                })];
                        }
                        else {
                            return [2 /*return*/, games];
                        }
                    }
                    return [4 /*yield*/, api.start("get", env.accounting_api + "/games/api/list-game/", null, false).then(function (res) {
                            if (res && res.status) {
                                var d = _.orderBy(res.data, ["ordering"], "asc");
                                Storage.set("games", d, 60 * 60 * 5);
                                if (params.subpage === "yeni-oyunlar") {
                                    return d.filter(function (game) {
                                        return game.is_active === true;
                                    });
                                }
                                else if (params.subpage === "gelecek-oyunlar") {
                                    return d.filter(function (game) {
                                        return game.is_active === false;
                                    });
                                }
                                else {
                                    return d;
                                }
                            }
                            else {
                                return [];
                            }
                            return [];
                        })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    }); };
    // create function to capital first letter of string
    var gameNameFixer = function (string) {
        if (string == "KAZI Kazan") {
            return "Kazı Kazan";
        }
        else if (string == "MAYIN Tarlası") {
            return "Mayın Tarlası";
        }
        else if (string == "AŞAĞI YUKARI") {
            return "Aşağı Yukarı";
        }
        else if (string == "Süper PENALTI") {
            return "Süper Penaltı";
        }
        else if (string == "ŞANSLI 6") {
            return "Şanslı 6";
        }
        else if (string == "HIZLI Tombala")
            return "Hızlı Tombala";
        else {
            return string;
        }
    };
    return (_jsxs(_Fragment, { children: [isMobile && (_jsx("div", __assign({ className: "row inner-page-top-tabs mb-4" }, { children: _jsxs("div", __assign({ className: "liner-tabs", style: { "--tab-count": "3" } }, { children: [_jsx("input", { type: "radio", value: "0", checked: params.subpage === undefined, "data-rel": "tab1", id: "raffle-id_tab-1", onChange: function () {
                                navigate(paths.games.url);
                            }, name: "tabs" }), _jsxs("label", __assign({ className: "tab text-center flex-column", htmlFor: "raffle-id_tab-1" }, { children: [_jsx("div", __assign({ className: "md-3" }, { children: "\uD83C\uDFAE" })), _jsx("div", __assign({ className: "md-9" }, { children: " Dijital Oyunlar" }))] })), _jsx("input", { type: "radio", value: "1", "data-rel": "tab2", checked: params.subpage === "yeni-oyunlar", id: "raffle-id_tab-2", onChange: function () {
                                navigate(paths.games.url + "/yeni-oyunlar");
                            }, name: "tabs" }), _jsxs("label", __assign({ className: "tab text-center flex-column", htmlFor: "raffle-id_tab-2" }, { children: [_jsx("div", __assign({ className: "md-3" }, { children: "\u2B50" })), _jsx("div", __assign({ className: "md-9" }, { children: " Yeni Oyunlar" }))] })), _jsx("input", { type: "radio", value: "2", "data-rel": "tab3", checked: params.subpage === "gelecek-oyunlar", id: "raffle-id_tab-3", onChange: function () {
                                navigate(paths.games.url + "/gelecek-oyunlar");
                            }, name: "tabs" }), _jsxs("label", __assign({ className: "tab text-center flex-column", htmlFor: "raffle-id_tab-3" }, { children: [_jsx("div", __assign({ className: "md-3" }, { children: "\u23F3" })), _jsx("div", __assign({ className: "md-9" }, { children: " Gelecek Oyunlar" }))] })), _jsx("span", { className: "glider" })] })) }))), !isMobile && (_jsx("div", __assign({ className: "row " }, { children: _jsx("div", __assign({ className: " mb-3" }, { children: _jsxs("div", __assign({ className: "slider-tabs", style: { "--tab-count": "3" } }, { children: [_jsx("input", { type: "radio", value: "0", checked: params.subpage === undefined, "data-rel": "tab1", id: "raffle-id_tab-1", onChange: function () {
                                    navigate(paths.games.url);
                                }, name: "tabs" }), _jsx("label", __assign({ className: "tab", htmlFor: "raffle-id_tab-1" }, { children: _jsx("h1", __assign({ className: "fs-6 fw-bold mb-0" }, { children: "\uD83C\uDFAE Dijital Oyunlar" })) })), _jsx("input", { type: "radio", checked: params.subpage === "yeni-oyunlar", value: "1", "data-rel": "tab2", id: "raffle-id_tab-2", onChange: function () {
                                    navigate(paths.games.url + "/yeni-oyunlar");
                                }, name: "tabs" }), _jsx("label", __assign({ className: "tab", htmlFor: "raffle-id_tab-2" }, { children: _jsx("h1", __assign({ className: "fs-6 fw-bold mb-0" }, { children: "\u2B50 Yeni Oyunlar" })) })), _jsx("input", { type: "radio", checked: params.subpage === "gelecek-oyunlar", value: "2", "data-rel": "tab3", id: "raffle-id_tab-3", onChange: function () {
                                    navigate(paths.games.url + "/gelecek-oyunlar");
                                }, name: "tabs" }), _jsx("label", __assign({ className: "tab", htmlFor: "raffle-id_tab-3" }, { children: _jsx("h1", __assign({ className: "fs-6 fw-bold mb-0" }, { children: "\u23F3 Gelecek Oyunlar" })) })), _jsx("span", { className: "glider" })] })) })) }))), _jsx("div", __assign({ className: "row" }, { children: gamesList.map(function (game, key) {
                    var _a, _b, _c, _d;
                    return (_jsx("div", __assign({ className: "col-3 col-lg-3 col-mobile-6 mb-4 pointer", onClick: function () {
                            gameNavigationMiddleware(game, navigate);
                            gtmSelectItem([game], "Oyunlar");
                        } }, { children: _jsxs("div", __assign({ className: "game-widget" }, { children: [_jsx("img", { className: "games", src: env.cdn_host + "/media/" + game.image }), _jsx("img", { className: "game-gif", src: game.gif ? env.cdn_host + "/media/" + game.gif : env.cdn_host + "/media/" + game.image, alt: "" }), _jsx("h2", __assign({ className: "title" }, { children: gameNameFixer(game.name) })), !isMobile && (_jsxs("div", __assign({ className: "".concat(game.is_active === true ? "playable" : "", " ").concat(game.is_active === false && ((_a = auth.member) === null || _a === void 0 ? void 0 : _a.id) && auth.remaining_games.includes(game.id)
                                        ? "remanded"
                                        : "", " body  mt-2") }, { children: [game.is_active === true && (_jsxs("a", __assign({ href: "/" +
                                                slugify(game.name
                                                    .toLowerCase()
                                                    .replaceAll("ü", "u")
                                                    .replaceAll("ş", "s")
                                                    .replaceAll("ç", "c")
                                                    .replaceAll("ğ", "g")
                                                    .replaceAll("ı", "i")
                                                    .replaceAll("ö", "o")), className: "label text-decoration-none text-white" }, { children: ["Hemen Oyna", _jsx("img", { src: iconRight, style: {
                                                        width: 12,
                                                        filter: "invert(1)",
                                                        marginTop: -3,
                                                    } })] }))), game.is_active !== true && (_jsxs("span", __assign({ className: "label" }, { children: [((_b = auth.member) === null || _b === void 0 ? void 0 : _b.id) && auth.remaining_games.includes(game.id)
                                                    ? "✅ Hatırlatıcı kuruldu"
                                                    : "⏰ Hatırlat", _jsx("img", { src: iconRight, style: {
                                                        width: 12,
                                                        filter: "invert(1)",
                                                        marginTop: -3,
                                                    } })] })))] }))), isMobile && (_jsxs("div", __assign({ className: "".concat(game.is_active === true ? "playable" : "", " ").concat(game.is_active === false && ((_c = auth.member) === null || _c === void 0 ? void 0 : _c.id) && auth.remaining_games.includes(game.id)
                                        ? "remanded"
                                        : "", " body  mt-2") }, { children: [game.is_active === true && (_jsxs("span", __assign({ className: "label", onClick: function () {
                                                gameNavigationMiddleware(game, navigate);
                                            } }, { children: ["Hemen Oyna", _jsx("img", { src: iconRight, style: {
                                                        width: 12,
                                                        filter: "invert(1)",
                                                        marginTop: -3,
                                                    } })] }))), game.is_active !== true && game.reminder !== true && (_jsxs("span", __assign({ className: "label" }, { children: [((_d = auth.member) === null || _d === void 0 ? void 0 : _d.id) && auth.remaining_games.includes(game.id)
                                                    ? "✅ Hatırlatıcı kuruldu"
                                                    : "⏰ Hatırlat", _jsx("img", { src: iconRight, style: {
                                                        width: 12,
                                                        filter: "invert(1)",
                                                        marginTop: -3,
                                                    } })] })))] })))] })) }), "games-list-".concat(key)));
                }) }))] }));
}
