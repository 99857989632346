import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef } from 'react';
import "./zeplin-card.css";
import { api } from "../../../services/http.service";
import { env } from "../../../constants/global.constants";
import { GameInfo } from "../game.info";
import { gtmViewItem, useForceUpdate } from "../../../services/misc.functions";
import { auth, authSubject } from "../../../store/auth.store";
import { GameLogin } from "../game.login";
var iframeHtmlString = function (config) { return "\n<body>\n  <style>\n    html,\n    body {\n      width: 100% !important;\n      height: 100% !important;\n      margin: 0;\n      padding: 0;\n    }\n    #gameIFrame {\n      width: 100% !important;\n      height: 100% !important;\n      border: none;\n      text-align: center;\n      background-color: Black;\n    }\n    #iframeContainer {\n      width: 100%;\n    }\n  </style>\n  <div id=\"iframeContainer\"></div>\n  <script\n    src=\"https://static-cdn.betsolutions.com/casino/GameStarter/Prod/gameStarter.min.js?v=18092019053918\"\n    type=\"text/javascript\"\n  ></script>\n  <script type=\"text/javascript\">\n    var config = {\n      Token: \"".concat(config.public_token, "\",\n      MerchantId: ").concat(config.merchant_id, ",\n      Lang: \"tr-TR\",\n      GameId: ").concat(config.game_id, " ,\n      ProductId: 3,\n      IsFreeplay: 0,\n      Platform: \"desktop\",\n      IframeContainerId: \"iframeContainer\",\n      IframeId: \"gameIFrame\",\n      GameOpenType: \"iframe\",\n      AuthUrl: \"").concat(config.post_url, "\",\n      WindowWidth: 1280,\n      ResizeWindow: true,\n    };\n    // @ts-ignore\n    Charismatic.Casino.GameStarter.startGame(config);\n  </script>\n</body>\n"); };
function ZeplinGameCards() {
    var iframeContainerRef = useRef(null);
    var forceUpdate = useForceUpdate();
    useEffect(function () {
        var auth_subscriber = authSubject.subscribe(function () {
            forceUpdate();
        });
        return function () {
            auth_subscriber.unsubscribe();
        };
    }, []);
    useEffect(function () {
        if (auth.authentication && auth.member && auth.member.id) {
            gtmViewItem([{ code: "zeplin", name: "Zeplin" }], 'Oyunlar');
            try {
                var fixedHeader = document.getElementById('fixedHeader');
                var appContainer = document.getElementById('appContainer');
                fixedHeader.classList.remove('fixed-header');
                appContainer.style.paddingTop = '10px';
            }
            catch (e) {
                console.log(e);
            }
            api.start("get", env.accounting_api + "/games/betsolutions/api/get_member_token/", null, true)
                .then(function (res) {
                if (res && res.status && iframeContainerRef.current) {
                    var blobContent = new Blob([iframeHtmlString(res.data)], { type: "text/html" });
                    iframeContainerRef.current.src = URL.createObjectURL(blobContent);
                }
            });
        }
    }, [iframeContainerRef.current]);
    // useEffect(() => {
    //   if (!isMobile && auth.authentication && auth.member && auth.member.id) {
    //     setTimeout(() => {
    //       var fixedHeader: any = document.getElementById('fixedHeader');
    //       console.log(fixedHeader);
    //       var appContainer: any = document.getElementById('appContainer');
    //       fixedHeader.classList.remove('fixed-header');
    //       appContainer.style.paddingTop = '10px';
    //     }, 2000);
    //   }
    // }, []);
    return _jsxs("div", { children: [auth.authentication && auth.member && auth.member.id ?
                _jsx("iframe", { ref: iframeContainerRef, className: "iframeContainer-zeplin" }) :
                _jsx(GameLogin, {}), _jsx(GameInfo, { code: "zeplin" })] });
}
export default ZeplinGameCards;
