var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { api } from "../../services/http.service";
import { env } from "../../constants/global.constants";
import { cartStore, cartToggleSubject } from "../../store/cart.store";
import Modal from "react-modal";
import { dateFormat } from "../../services/misc.functions";
import iconPlus from "../../assets/icons/feather/plus-circle.svg";
import { isMobile } from "../../App";
export function CouponCodeWidget(_a) {
    var _b = _a.buttonText, buttonText = _b === void 0 ? "Kupon Gir" : _b;
    var _c = useState(false), couponCode = _c[0], setCouponCode = _c[1];
    var _d = useState(null), errorMessage = _d[0], setErrorMessage = _d[1];
    var _e = useState([]), availabelCouponCode = _e[0], setAvailabelCouponCode = _e[1];
    var _f = useForm(), register = _f.register, handleSubmit = _f.handleSubmit, reset = _f.reset, errors = _f.formState.errors;
    var onSubmit = function (payload) {
        var doneButton = document.getElementById("doneButton");
        doneButton.disabled = true;
        var inputElements = document.querySelectorAll("input.code-input");
        var validateCodeValue = __spreadArray([], inputElements, true).map(function (inp) { return inp.value; })
            .join("");
        api
            .start("post", env.accounting_api + "/misc/api/check-discount-coupons/", {
            code: String(validateCodeValue.slice(0, 3) + "-" + validateCodeValue.slice(3, 6)),
        }, true)
            .then(function (res) {
            // console.log(res)
            doneButton.disabled = false;
            if (res.status) {
                cartStore.couponCode = {
                    code: validateCodeValue,
                    amount: Number(res.data[0].amount),
                    minCartCond: Number(res.data[0].min_cart_amount),
                };
                setCouponCode(false);
                cartToggleSubject.next({
                    action: "couponCode",
                    data: cartStore.couponCode,
                });
            }
            else {
                setErrorMessage(res.message);
            }
        })
            .catch(function (err) {
            doneButton.disabled = false;
            setErrorMessage(err);
        });
    };
    return (_jsxs(_Fragment, { children: [!cartStore.couponCode && isMobile && (_jsxs("div", __assign({ onClick: function () {
                    setCouponCode(true);
                    // api
                    //   .start(
                    //     "get",
                    //     env.accounting_api + "/misc/api/list-discount-coupons/ ",
                    //     null,
                    //     true
                    //   )
                    //   .then((res: any) => {
                    //     if (res && res.status) {
                    //       setAvailabelCouponCode(res.data);
                    //     }
                    //   })
                    //   .finally(() => {
                    //     setCouponCode(true);
                    //   });
                } }, { children: [_jsx("img", { src: iconPlus, width: 18 }), _jsx("br", {}), buttonText] }))), !cartStore.couponCode && !isMobile && (_jsxs("button", __assign({ onClick: function () {
                    // api
                    //     .start(
                    //         "get",
                    //         env.accounting_api + "/misc/api/list-discount-coupons/ ",
                    //         null,
                    //         true
                    //     )
                    //     .then((res: any) => {
                    //       if (res && res.status) {
                    //         setAvailabelCouponCode(res.data);
                    //       }
                    //     })
                    //     .finally(() => {
                    //       setCouponCode(true);
                    //     });
                    setCouponCode(true);
                }, className: "btn btn-sm btn-primary rounded-pill" }, { children: [_jsx("span", { children: " + " }), " ", buttonText] }))), cartStore.couponCode && (_jsx(_Fragment, { children: isMobile ?
                    _jsxs("div", __assign({ className: "d-flex flex-column justify-content-center align-items-center" }, { children: [_jsxs("span", __assign({ className: "text-success" }, { children: [cartStore.couponCode.amount, "\u20BA"] })), _jsx("span", __assign({ onClick: function () {
                                    cartToggleSubject.next({ action: "couponCode", data: null });
                                }, className: "rounded-pill px-2 text-white fs-8 bg-danger" }, { children: "iptal et" }))] }))
                    : _jsxs(_Fragment, { children: [_jsx("span", __assign({ onClick: function () {
                                    cartToggleSubject.next({ action: "couponCode", data: null });
                                }, className: "rounded-pill px-2 text-white fs-8 bg-danger me-2" }, { children: "iptal et" })), _jsxs("span", __assign({ className: "text-success" }, { children: [cartStore.couponCode.amount, "\u20BA"] }))] }) })), _jsx(Modal, __assign({ isOpen: couponCode, onRequestClose: function () {
                    setCouponCode(false);
                }, onAfterOpen: function () {
                    // let doneButton:any = document.getElementById('doneButton');
                    // doneButton.disabled=true;
                    var inputElements = document.querySelectorAll("input.code-input");
                    inputElements.forEach(function (ele, index) {
                        ele.addEventListener("keydown", function (e) {
                            // if the keycode is backspace & the current field is empty
                            // focus the input before the current. Then the event happens
                            // which will clear the "before" input box.
                            if (e.keyCode === 8 && e.target.value === "")
                                inputElements[Math.max(0, index - 1)].focus();
                        });
                        ele.addEventListener("input", function (e) {
                            // take the first character of the input
                            // this actually breaks if you input an emoji like 👨‍👩‍👧‍👦....
                            // but I'm willing to overlook insane security code practices.
                            var _a = e.target.value, first = _a[0], rest = _a.slice(1);
                            e.target.value = first !== null && first !== void 0 ? first : ""; // first will be undefined when backspace was entered, so set the input to ""
                            var lastInputBox = index === inputElements.length - 1;
                            var didInsertContent = first !== undefined;
                            if (didInsertContent && !lastInputBox) {
                                // continue to input the rest of the string
                                inputElements[index + 1].focus();
                                // inputElements[index+1].value = rest.join('')
                                inputElements[index + 1].dispatchEvent(new Event("input"));
                            }
                            var validateCodeValue = __spreadArray([], inputElements, true).map(function (inp) { return inp.value; })
                                .join("");
                            // if(validateCodeValue.length===6){
                            //   doneButton.disabled=false;
                            // }else{
                            //   doneButton.disabled=true;
                            // }
                        });
                    });
                }, style: {
                    content: {
                        top: "50%",
                        left: "50%",
                        right: "auto",
                        bottom: "auto",
                        marginRight: "-50%",
                        maxWidth: "calc(100% - 45px)",
                        width: "360px",
                        padding: "0.5rem",
                        maxHeight: "calc(100vh - 100px)",
                        transform: "translate(-50%, -50%)",
                        background: "var(--bs-white)",
                    },
                }, contentLabel: "" }, { children: _jsx("form", __assign({ onSubmit: handleSubmit(onSubmit) }, { children: _jsxs("div", __assign({ className: "coupon-code-widget" }, { children: [_jsx("h2", { children: "\u0130ndirim Kuponu" }), _jsx("p", { children: "Hesab\u0131n\u0131za tan\u0131ml\u0131 kuponlardan se\u00E7erek yada kupon kodunuzu girerek indirimden faydalana bilirsiniz." }), errorMessage && (_jsx("div", __assign({ className: "alert text-center alert-danger" }, { children: errorMessage }))), _jsxs("table", __assign({ className: "table table-hover table-striped" }, { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { children: "Kupon" }), _jsx("th", { children: "Ge\u00E7erlilik" }), _jsx("th", { children: "De\u011Feri" })] }) }), _jsx("tbody", { children: availabelCouponCode.map(function (cp, cKey) {
                                            return (_jsxs("tr", __assign({ className: "pointer", onClick: function () {
                                                    var inputElements = document.querySelectorAll("input.code-input");
                                                    inputElements.forEach(function (ele, index) {
                                                        ele.value = cp.code.replace("-", "").split("")[index];
                                                    });
                                                } }, { children: [_jsx("td", { children: cp.code }), _jsx("td", { children: dateFormat(cp.expire_date) }), _jsxs("td", { children: [Number(cp.amount).toFixed(2), "\u20BA"] })] }), "available-couponcodes-".concat(cKey)));
                                        }) })] })), _jsx("div", __assign({ className: "mt-2 mb-5" }, { children: _jsxs("div", __assign({ className: "mb-3 d-flex" }, { children: [_jsx("input", { type: "text", autoComplete: "off", name: "char", className: "code-input col-auto", required: true }), _jsx("input", { type: "text", autoComplete: "off", name: "char", className: "code-input col-auto", required: true }), _jsx("input", { type: "text", autoComplete: "off", name: "char", className: "code-input col-auto", required: true }), _jsx("div", __assign({ className: "code-input-seperator" }, { children: "-" })), _jsx("input", { type: "text", autoComplete: "off", name: "char", className: "code-input col-auto", required: true }), _jsx("input", { type: "text", autoComplete: "off", name: "char", className: "code-input col-auto", required: true }), _jsx("input", { type: "text", autoComplete: "off", name: "char", className: "code-input col-auto", required: true }), _jsx("div", { className: "clearfix" })] })) })), _jsxs("div", __assign({ className: "modal-ticket-footer text-end" }, { children: [_jsx("div", { className: "wave" }), _jsxs("div", __assign({ className: "body-row row" }, { children: [_jsx("div", __assign({ className: "col-6 pe-0" }, { children: _jsx("button", __assign({ type: "button", onClick: function () {
                                                        setCouponCode(false);
                                                    }, className: "btn w-100 custom-btn-danger me-2" }, { children: "Vazge\u00E7" })) })), _jsx("div", __assign({ className: "col-6 ps-0" }, { children: _jsx("button", __assign({ id: "doneButton", className: "btn  w-100 btn-success" }, { children: "Kuponu Uygula" })) }))] }))] }))] })) })) }))] }));
}
