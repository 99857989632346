var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { NotificationMenu } from "./account";
import { useEffect } from "react";
import { auth, authSubject } from "../../store/auth.store";
import { useForceUpdate } from "../../services/misc.functions";
import { api } from "../../services/http.service";
import { env } from "../../constants/global.constants";
import { wssProvider } from "../../services/websocket.service";
import { sendEmailValidationLink } from "../../services/auth.service";
export function ContactSettings() {
    var forceUpdate = useForceUpdate();
    useEffect(function () {
        var auth_subscriber = authSubject.subscribe(function () {
            // console.log("refresh")
            forceUpdate();
        });
        return function () {
            auth_subscriber.unsubscribe();
        };
    }, []);
    var updateNotificationSettings = function (payload, obj) {
        // for (const [k, v] of Object.entries(payload)) {
        //   auth.member[k] = v;
        // }
        // forceUpdate();
        obj.target.disabled = true;
        api
            .start("post", env.accounting_api + "/member/api/update-account-info/", payload, true)
            .then(function (res) {
            wssProvider.checkauth();
            forceUpdate();
        })
            .finally(function () {
            obj.target.disabled = false;
        });
    };
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "row" }, { children: [_jsx("div", __assign({ className: "col-3 col-mobile-12" }, { children: _jsx(NotificationMenu, {}) })), _jsxs("div", __assign({ className: "col-9 col-mobile-12" }, { children: [_jsxs("div", __assign({ className: "card  mb-4 rounded-4" }, { children: [_jsx("div", __assign({ className: "card-header text-start text-primary" }, { children: _jsx("h2", { children: "\u0130leti\u015Fim Tercihlerim" }) })), _jsx("div", __assign({ className: "card-body" }, { children: _jsx("p", __assign({ className: "white-space-normal fs-7" }, { children: "R\u0131za Metni kapsam\u0131nda, f\u0131rsatlar\u0131m\u0131z ve kampanyalar\u0131m\u0131zdan sizi haberdar etmek ve b\u00FCy\u00FCk \u00F6d\u00FCllerimizi kazanman\u0131z durumda size ula\u015Fmak ve tebrik etmek i\u00E7in izninizi istiyoruz." })) }))] })), _jsx("div", __assign({ className: "card mb-4 rounded-4 notification-settings" }, { children: _jsxs("div", __assign({ className: "card-body pt-3" }, { children: [_jsxs("div", __assign({ className: "form-check form-switch" }, { children: [_jsxs("label", __assign({ className: "form-check-label", htmlFor: "id_switch_mail_notification" }, { children: [_jsx("h3", { children: "E-Posta" }), _jsx("p", __assign({ className: "white-space-normal" }, { children: "info@e-sans.com.tr adresinden gelmeyen e-postalara itibar etmeyiniz." }))] })), _jsx("input", { checked: auth.member.mail_notification, className: "form-check-input float-end mt-3", type: "checkbox", onChange: function (e) {
                                                    updateNotificationSettings({ mail_notification: !auth.member.mail_notification }, e);
                                                }, role: "switch", id: "id_switch_mail_notification" })] })), _jsxs("div", __assign({ className: "form-check form-switch" }, { children: [_jsxs("label", __assign({ className: "form-check-label", htmlFor: "id_switch_sms_notification" }, { children: [_jsx("h3", { children: "SMS" }), _jsx("p", __assign({ className: "white-space-normal" }, { children: "106 Dijital ismi ile gelmeyen SMS'lere itibar etmeyiniz!" }))] })), _jsx("input", { checked: auth.member.sms_notification, className: "form-check-input float-end mt-3", type: "checkbox", onChange: function (e) {
                                                    updateNotificationSettings({ sms_notification: !auth.member.sms_notification }, e);
                                                }, role: "switch", id: "id_switch_sms_notification" })] })), _jsxs("div", __assign({ className: "form-check form-switch" }, { children: [_jsxs("label", __assign({ className: "form-check-label", htmlFor: "is_switch_call_notification" }, { children: [_jsx("h3", { children: "M\u00FC\u015Fteri Hizmetleri" }), _jsx("p", __assign({ className: "white-space-normal" }, { children: "0850 241 90 99 telefon numaras\u0131ndan gelmeyen aramalara itibar etmeyiniz." }))] })), _jsx("input", { checked: auth.member.call_notification, className: "form-check-input float-end mt-3", type: "checkbox", onChange: function (e) {
                                                    updateNotificationSettings({ call_notification: !auth.member.call_notification }, e);
                                                }, role: "switch", id: "is_switch_call_notification" })] }))] })) })), _jsx("p", __assign({ className: "p-3 white-space-normal" }, { children: "SMS ve Email arac\u0131l\u0131\u011F\u0131 ile size ula\u015Fabilmemiz ve bildirim alabilmeniz i\u00E7in e-posta adresinizi ve telefon numaran\u0131z\u0131 do\u011Frulaman\u0131z gerekmektedir." })), auth.member.email_validation !== true && (_jsxs("div", __assign({ className: "card mt-4 rounded-4" }, { children: [_jsx("div", __assign({ className: "card-header" }, { children: _jsx("h2", { children: "E-Posta Adresinizi Do\u011Frulay\u0131n" }) })), _jsxs("div", __assign({ className: "card-body" }, { children: [_jsx("p", __assign({ className: "white-space-normal" }, { children: "Hesab\u0131n\u0131z\u0131n g\u00FCvenli\u011Fini sa\u011Flamaya yard\u0131mc\u0131 olmak i\u00E7in l\u00FCtfen telefon numaran\u0131z\u0131 ve e-posta adresinizi do\u011Frulay\u0131n. Bu i\u015Flem i\u00E7in e-posta adresine bir do\u011Frulama e-postas\u0131 g\u00F6nderin veya e-posta adresinizi g\u00FCncelleyin." })), _jsx("button", __assign({ className: "btn d-block btn-sm mt-2 btn-success", onClick: function () {
                                                sendEmailValidationLink();
                                            } }, { children: "Eposta adresimi do\u011Frula" }))] }))] })))] }))] })) }));
}
