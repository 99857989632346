import { Subject } from "rxjs";
export var raffleDraws = [];
export var raffleCategories = [];
export var drawFilters = {
    categories: [],
    isPopular: false,
    isNew: false,
    runningOut: true,
    ordering: ['sort', 'asc'],
};
export var raffleSubject = new Subject();
export var raffleDrawSubject = new Subject();
export var scratchCards = [];
