var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import "./advertisement.css";
import { Storage } from "../../services/localstorege.service";
import Modal from "react-modal";
import iconClose from "../../assets/icons/feather/x-circle.svg";
import { useForceUpdate } from "../../services/misc.functions";
import { useNavigate } from "react-router";
import { newsSubject } from "../../store/misc.store";
import { env, news } from "../../constants/global.constants";
export default function Advertisement() {
    var advertisementData = Storage.get("advertisement");
    var forceUpdate = useForceUpdate();
    var navigate = useNavigate();
    var _a = useState(null), showingData = _a[0], setShowingData = _a[1];
    function admit() {
        var element = document.getElementById("advertisement");
        element.style.display = "none";
        Storage.set("advertisement", false);
    }
    useEffect(function () {
        var newsSubject_subscriber = newsSubject.subscribe(function () {
            if (news.length > 0) {
                var notSeen = news.filter(function (n) { return n.is_seen !== true; });
                if (notSeen.length > 0) {
                    notSeen[0].is_seen = true;
                    setShowingData(notSeen[0]);
                    forceUpdate();
                }
            }
        });
        return function () {
            newsSubject_subscriber.unsubscribe();
        };
    }, []);
    return showingData == null ? null : (_jsxs(Modal, __assign({ style: {
            content: {
                background: "transparent",
                boxShadow: "none",
                width: "40%",
            },
        }, isOpen: showingData != null ? true : false, className: "default", onRequestClose: function () {
            setShowingData(null);
            Storage.set("advertisement", showingData);
            forceUpdate();
        } }, { children: [_jsx("div", __assign({ className: "modal-close", style: { right: "30px", top: "30px" } }, { children: _jsx("img", { src: iconClose, width: 24, style: { fill: "#FFFFFF" }, className: "float-right cursor-p ", onClick: function () {
                        Storage.set("advertisement", showingData);
                        setShowingData(null);
                        forceUpdate();
                    } }) })), _jsx("div", __assign({ className: "modal-body text-center", onClick: function () {
                    Storage.set("advertisement", showingData);
                    setShowingData(null);
                    // navigate("/esya-piyangosu/6276/3899-nolu-eya-piyangosu-ekilii/?n=/esya-piyangosu");
                } }, { children: _jsx("img", { className: "pointer", style: { width: "100%" }, src: env.accounting_api + "/media/" + showingData.photo_web }) }))] })));
}
