import { jsx as _jsx } from "react/jsx-runtime";
import App from "./App";
import { createRoot } from "react-dom/client";
import "bootstrap/dist/css/bootstrap-reboot.min.css";
import "bootstrap/dist/css/bootstrap-grid.min.css";
import "bootstrap/dist/css/bootstrap-utilities.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./index.css";
import "./assets/css/mobile.css";
var rootElement = document.getElementById("root");
var root = createRoot(rootElement);
root.render(_jsx(App, {})
// <StrictMode>
//   <App />
// </StrictMode>
);
