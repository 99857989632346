var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { SupportMenu } from "./support";
import React, { useEffect, useState } from "react";
import { env, supportTicketStatus } from "../../constants/global.constants";
import { api } from "../../services/http.service";
import { dateTimeFormat, loadingAnimation, } from "../../services/misc.functions";
import { useNavigate, useParams } from "react-router";
import iconFile from "../../assets/icons/feather/file.svg";
import { modalServiceSubject } from "../../services/modal.service";
import { SupportTicketForm } from "./support.ticket";
import { updateTicketSubject } from "../../store/misc.store";
import paths from "../../paths";
var selectedSupportTicket = null;
var supportTicketPage = 0;
export function SupportTicketList() {
    var _this = this;
    var _a, _b;
    var _c = useState([]), dataList = _c[0], setDataList = _c[1];
    var _d = React.useState(false), ticketForm = _d[0], showTicketForm = _d[1];
    var params = useParams();
    var navigate = useNavigate();
    useEffect(function () {
        var updateTicketSubject_subscriber = updateTicketSubject.subscribe(function () {
            loadData(0);
        });
        return function () {
            updateTicketSubject_subscriber.unsubscribe();
        };
    }, []);
    useEffect(function () {
        loadData(supportTicketPage);
    }, []);
    var loadData = function (page) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    loadingAnimation(true);
                    if (page === 0) {
                        setDataList([]);
                    }
                    return [4 /*yield*/, api
                            .start("post", env.accounting_api + "/support/api/list-ticket/", null, true)
                            .then(function (res) {
                            loadingAnimation(false);
                            if (res && res.status) {
                                dataList.push.apply(dataList, res.data);
                                setDataList(dataList);
                                return res.data;
                            }
                            if (res && res.metadata) {
                                var id_datalist_loadmore = document.getElementById("id_datalist_loadmore");
                                if (res.metadata.count === 0 ||
                                    res.metadata.count <= dataList.length) {
                                    id_datalist_loadmore.style.display = "none";
                                }
                                else {
                                    id_datalist_loadmore.style.display = "block";
                                }
                            }
                        })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    }); };
    var loadMore = function (e) {
        e.target.disabled = true;
        loadData(supportTicketPage + 1).finally(function () {
            e.target.disabled = false;
        });
    };
    if (params.id) {
        selectedSupportTicket = dataList.find(function (el) { return Number(el.id) === Number(params.id); });
    }
    else {
        selectedSupportTicket = null;
    }
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "row" }, { children: [_jsx("div", __assign({ className: "col-3 col-mobile-12" }, { children: _jsx(SupportMenu, {}) })), _jsx("div", __assign({ className: "col-9 col-mobile-12" }, { children: _jsxs("div", __assign({ className: "card  mb-4 rounded-4" }, { children: [_jsxs("div", __assign({ className: "card-header" }, { children: [_jsx("button", __assign({ onClick: function () {
                                            navigate(paths.support.url + "/cozum-merkezi/");
                                        }, className: "btn btn-sm rounded-pill float-end btn-success fw-bolder" }, { children: "+ Talep Olu\u015Ftur" })), _jsx("h2", __assign({ className: "support-header" }, { children: "Destek Taleplerim" }))] })), _jsxs("div", __assign({ className: "card-body support-ticket-list" }, { children: [selectedSupportTicket && (_jsxs("div", { children: [_jsxs("div", { children: [_jsx("span", __assign({ className: "fw-bold" }, { children: "Konu: " })), (_a = selectedSupportTicket.subject) === null || _a === void 0 ? void 0 : _a.title, _jsx("br", {}), _jsxs("span", __assign({ className: "float-end" }, { children: [_jsx("br", {}), dateTimeFormat(selectedSupportTicket.create_date)] }))] }), _jsxs("div", { children: [_jsx("span", __assign({ className: "fw-bold" }, { children: "Destek talep numaras\u0131: " })), selectedSupportTicket.id] }), _jsx("p", __assign({ className: "py-4", style: { whiteSpace: "normal" } }, { children: selectedSupportTicket.message })), selectedSupportTicket.Responses &&
                                                selectedSupportTicket.Responses.length > 0 && (_jsxs("div", __assign({ className: "ticket-responses" }, { children: [_jsx("h3", { children: "e-\u015Eans Destek Ekibi Cevab\u0131" }), selectedSupportTicket.Responses.map(function (response, key) {
                                                        return (_jsxs("div", __assign({ className: "ticket-response mb-4" }, { children: [_jsx("span", __assign({ className: "fw-bold" }, { children: "Tarih: " })), dateTimeFormat(response.create_date), _jsx("p", { children: response.message }), response.file && (_jsxs("a", __assign({ className: "btn btn-sm btn-warning attach", href: env.cdn_host + response.file, target: "_blank" }, { children: [_jsx("img", { src: iconFile, alt: "Aç", width: 18 }), "Dosya Eki"] })))] }), "ticket-response-".concat(key)));
                                                    }), _jsxs("div", __assign({ className: "text-center" }, { children: [_jsx("p", __assign({ className: "my-4" }, { children: "Bu i\u00E7erik size yard\u0131mc\u0131 oldu mu?" })), _jsx("button", __assign({ className: "btn rounded-pill btn-success me-1", onClick: function () {
                                                                    modalServiceSubject.next({
                                                                        case: "success",
                                                                        title: "Teşekkürler",
                                                                        content: "<p>Geri bildirim için teşekkür ederiz. Sizlere daha iyi hizmet verebilmek için biz buradayız.</p>",
                                                                        confirm: {
                                                                            cancel: {
                                                                                class: "btn btn-success",
                                                                                label: "Kapat",
                                                                            },
                                                                        },
                                                                    });
                                                                } }, { children: "Evet" })), _jsx("button", __assign({ className: "btn rounded-pill btn-secondary ms-1", onClick: function () {
                                                                    showTicketForm(true);
                                                                } }, { children: "Hay\u0131r" }))] })), ticketForm && (_jsxs(_Fragment, { children: [_jsxs("p", __assign({ className: "my-3 text-center" }, { children: ["M\u00FC\u015Fteri destek eibimizi arayabilir ve akl\u0131n\u0131za tak\u0131lan sorular\u0131 sorabilirsiniz.", _jsx("br", {}), " ", _jsx("br", {}), _jsx("a", __assign({ href: "tel:08502419099", className: "btn rounded-pill btn-outline-success ms-2" }, { children: "0850 241 90 99" }))] })), _jsx(SupportTicketForm, { hintId: (_b = selectedSupportTicket.subject) === null || _b === void 0 ? void 0 : _b.id })] }))] })))] })), selectedSupportTicket === null && (_jsxs("div", __assign({ className: "table-responsive" }, { children: [_jsx("table", __assign({ className: "table table-striped" }, { children: _jsx("tbody", { children: dataList &&
                                                        dataList.map(function (item, key) {
                                                            var _a;
                                                            return (_jsxs("tr", __assign({ className: "pointer", onClick: function () {
                                                                    navigate(paths.support.url +
                                                                        "/destek-taleplerim/" +
                                                                        item.id +
                                                                        "/");
                                                                } }, { children: [_jsxs("td", { children: [_jsxs("span", __assign({ className: "d-block fw-bold" }, { children: ["#", item.id, " - ", (_a = item.subject) === null || _a === void 0 ? void 0 : _a.title] })), _jsx("p", __assign({ className: "message" }, { children: item.message }))] }), _jsxs("td", __assign({ className: "text-end" }, { children: [_jsx("span", __assign({ className: "d-block date" }, { children: dateTimeFormat(item.create_date) })), supportTicketStatus[item.status]] }))] }), "support-ticket-list-".concat(key)));
                                                        }) }) })), _jsxs("div", __assign({ className: "text-center" }, { children: [_jsx("div", { id: "id_loaderAnimation", className: "loader" }), _jsx("button", __assign({ id: "id_datalist_loadmore", onClick: loadMore, type: "button", className: "btn rounded-pill btn-outline-primary" }, { children: "Devam\u0131n\u0131 Y\u00FCkle" }))] }))] })))] }))] })) }))] })) }));
}
