import { ServerDate } from "../services/misc.functions";
import { env } from "../constants/global.constants";
var Raffle = /** @class */ (function () {
    function Raffle(obj) {
        this.detail = obj.detail;
        this.draw_categories_id = obj.draw_categories_id;
        this.draw_categories_sort = obj.draw_categories_sort;
        this.draw_date = obj.draw_date;
        this.game_type = obj.game_type;
        this.is_added = obj.is_added;
        this.cartIndex = obj.cartIndex;
        this.draw_price = obj.draw_price;
        this.id = obj.id;
        this.tag = obj.tag === 1;
        this.is_new = obj.tag === 1;
        this.is_popular = obj.is_popular;
        this.is_running_out = obj.is_running_out;
        this.best_selling = obj.tag === 3;
        this.name = obj.name;
        this.no = obj.no;
        this.payout_amount = obj.payout_amount;
        this.photo = env.accounting_api + '/media/' + obj.photo;
        this.detail_photo = env.accounting_api + '/media/' + obj.detail_photo;
        this.remaining_tickets = obj.remaining_tickets;
        this.second_hand = obj.tag === 2;
        this.sell_end_date = obj.sell_end_date;
        this.sell_start_date = obj.sell_start_date;
        this.status = obj.status;
        this.this_week = obj.this_week;
        this.ticket_price = Number(obj.ticket_price);
        this.title = obj.title;
        this.title_abbreviated = obj.title_abbreviated;
        this.total_tickets = obj.total_tickets;
        this.transferred_amount = obj.transferred_amount;
        this.transferred_count = obj.transferred_count;
        this.sort = obj.sort;
    }
    Raffle.prototype.leftTicketPercColor = function () {
        var perc = (100 - (100 * this.remaining_tickets) / this.total_tickets);
        if (perc < 50) {
            return '#4AD3A5';
        }
        else if (perc < 80) {
            return '#FF9F46';
        }
        else {
            return '#FF4646';
        }
    };
    Raffle.prototype.leftTicketPerc = function () {
        var perc = ((100 - (100 * this.remaining_tickets) / this.total_tickets));
        if (this.remaining_tickets == 0) {
            perc = 100;
        }
        else {
            if (perc > 99) {
                perc = 99;
            }
        }
        return perc.toFixed(0);
    };
    Raffle.prototype.leftDays = function () {
        try {
            var leftDay = Math.floor((new Date(this.draw_date.split('T')[0]).getTime() - ServerDate().setHours(0, 0, 0, 0)) / 1000 / 60 / 60 / 24);
            if (leftDay == 0) {
                return 'Bugün';
            }
            else {
                return leftDay;
            }
        }
        catch (e) {
            return Math.floor((new Date(this.draw_date).getTime() - ServerDate().getTime()) / 1000 / 60 / 60 / 24);
        }
    };
    return Raffle;
}());
export { Raffle };
