var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Link } from "react-router-dom";
import logoESans from "../../assets/images/logo-renkli.png";
import landingImage from "../../assets/images/landing.png";
import "./auth.css";
import paths from "../../paths";
export function Landing() {
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "page-landing" }, { children: [_jsx("div", { className: "clearfix" }), _jsx(Link, __assign({ className: "branding-big mx-3 px-1 pointer", to: paths.home.url }, { children: _jsx("img", { src: logoESans }) })), _jsx("div", { className: "clearfix" }), _jsx("div", __assign({ className: "row" }, { children: _jsx("div", __assign({ className: "col-12" }, { children: _jsx("p", __assign({ className: "landing-text text-wrap" }, { children: "Sevdi\u011Fin b\u00FCt\u00FCn \u00E7ekili\u015Fler ve oyunlar e-\u015Eans'da!" })) })) })), _jsx(Link, __assign({ className: "pointer", to: paths.home.url }, { children: _jsx("img", { className: "center-image", src: landingImage }) })), _jsx(Link, __assign({ className: "btn btn-custom w-100 mt-5", to: paths.login.url, id: "btn_submit" }, { children: "Giri\u015F Yap" })), _jsx(Link, __assign({ className: "btn btn-success w-100 mt-3 fw-bold", to: paths.register.url, id: "btn_submit" }, { children: "\u00DCye Ol" })), _jsx("div", { className: "clearfix" })] })) }));
}
