var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { raffleDraws, raffleSubject } from "../../store/games.store";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import { useEffect, useRef, useState } from "react";
import { Ticket } from "../../shared/ticket/ticket_bck";
import { useForceUpdate } from "../../services/misc.functions";
import { TicketSlide } from "../../shared/ticket/slide";
import { isMobile } from "../../App";
import "swiper/css/pagination";
import "swiper/css/navigation";
import _ from "lodash";
export function HomeRaffleWidget(props) {
    var withCategories = props.withCategories;
    var selectedCategory = props.selectedCategory;
    var _a = useState("esya-piyangosu"), selectedFilter = _a[0], setSelectedFilter = _a[1];
    var forceUpdate = useForceUpdate();
    useEffect(function () {
        var raffleSubject_subscriber = raffleSubject.subscribe(function () {
            // console.log(raffleDraws)
            forceUpdate();
        });
        return function () {
            raffleSubject_subscriber.unsubscribe();
        };
    }, []);
    var navigationPrevRef = useRef(null);
    var navigationNextRef = useRef(null);
    var popularRaffles;
    if (isMobile) {
        popularRaffles = raffleDraws.filter(function (el) { return el.remaining_tickets > 0; });
        if (selectedFilter == "esya-piyangosu") {
            popularRaffles = popularRaffles.filter(function (el) {
                return el.draw_categories_id === 22 || el.draw_categories_id === 23;
            });
            var others_1;
            others_1 = raffleDraws.filter(function (el) {
                return el.remaining_tickets > 0 &&
                    el.draw_categories_id !== 22 &&
                    el.draw_categories_id !== 23;
            });
            others_1 = _.orderBy(others_1, ["ticket_price"], ["desc"]);
            popularRaffles = __spreadArray(__spreadArray([], others_1, true), popularRaffles, true);
        }
        else if (selectedFilter == "populer") {
            popularRaffles = raffleDraws.filter(function (el) { return el.is_popular && el.remaining_tickets > 0; });
            popularRaffles = _.orderBy(popularRaffles, ["draw_date"], ["asc"]);
        }
        else if (selectedFilter == "yeni") {
            popularRaffles = raffleDraws.filter(function (el) { return el.is_new && el.remaining_tickets > 0; });
            popularRaffles = _.orderBy(popularRaffles, ["draw_date"], ["asc"]);
        }
    }
    else {
        if (selectedCategory) {
            if (selectedCategory == "populer") {
                // popularRaffles = raffleDraws.filter((el: Raffle) => el.remaining_tickets > 0);
                // popularRaffles = popularRaffles.filter((el: Raffle) => el.draw_categories_id === 22 || el.draw_categories_id === 23);
                // let others: Raffle[]
                // others =  raffleDraws.filter((el: Raffle) => el.remaining_tickets > 0 && el.draw_categories_id !== 22 && el.draw_categories_id !== 23);
                // others =  _.orderBy(others, ['ticket_price'], ['asc'])
                // popularRaffles = [...popularRaffles,...others];
                var _showingDraws = [];
                var cars = raffleDraws.filter(function (el) {
                    return el.draw_categories_sort == 1 && el.remaining_tickets > 0;
                });
                var motorbike = raffleDraws.filter(function (el) {
                    return el.draw_categories_sort == 2 && el.remaining_tickets > 0;
                });
                var others = raffleDraws.filter(function (el) {
                    return el.draw_categories_sort != 1 &&
                        el.draw_categories_sort != 2 &&
                        el.remaining_tickets > 0;
                });
                others = _.orderBy(others, "ticket_price", "desc");
                _showingDraws = __spreadArray(__spreadArray(__spreadArray([], cars, true), motorbike, true), others, true);
                popularRaffles = _.orderBy(_showingDraws, "sort", "desc");
            }
            else {
                popularRaffles = raffleDraws.filter(function (el) { return el.draw_categories_id === (selectedCategory === null || selectedCategory === void 0 ? void 0 : selectedCategory.id); }); //raffleDraws.filter((el:Raffle)=>el.is_popular);
                popularRaffles = _.orderBy(popularRaffles, "remaining_tickets", "desc");
            }
        }
        else {
            popularRaffles = raffleDraws.filter(function (el) { return el.is_popular; });
            popularRaffles = _.orderBy(popularRaffles, "remaining_tickets", "desc");
        }
    }
    return (_jsxs(_Fragment, { children: [isMobile && (_jsxs("div", __assign({ className: "slider-tabs mt-2", style: { "--tab-count": "3" } }, { children: [_jsx("input", { type: "radio", value: 0, checked: selectedFilter === "esya-piyangosu", "data-rel": "tab1", id: "home-raffle-tab-1", name: "tabs2", onChange: function () {
                            setSelectedFilter("esya-piyangosu");
                        } }), _jsx("label", __assign({ className: "tab", htmlFor: "home-raffle-tab-1" }, { children: "E\u015Fya Piyangosu" })), _jsx("input", { type: "radio", value: 1, "data-rel": "tab2", id: "home-raffle-tab-2", name: "tabs2", onChange: function () {
                            setSelectedFilter("populer");
                        } }), _jsx("label", __assign({ className: "tab", htmlFor: "home-raffle-tab-2" }, { children: "Pop\u00FCler" })), _jsx("input", { type: "radio", value: 2, "data-rel": "tab3", id: "home-raffle-tab-3", name: "tabs2", onChange: function () {
                            setSelectedFilter("yeni");
                        } }), _jsx("label", __assign({ className: "tab", htmlFor: "home-raffle-tab-3" }, { children: "Yeni" })), _jsx("span", { className: "glider" })] }))), _jsxs("div", __assign({ className: "home-ticket-slider" }, { children: [_jsx("div", { className: "clearfix" }), _jsx("div", __assign({ className: "slider-container" }, { children: withCategories ? (_jsx(Swiper, __assign({ spaceBetween: 0, breakpoints: {
                                0: {
                                    slidesPerView: 2,
                                    spaceBetween: 0,
                                },
                                948: {
                                    slidesPerView: withCategories ? 4 : 2,
                                    spaceBetween: 0,
                                },
                                1400: {
                                    slidesPerView: withCategories ? 4 : 2,
                                    spaceBetween: 0,
                                },
                                1900: {
                                    slidesPerView: withCategories ? 4 : 2,
                                    spaceBetween: 0,
                                },
                            }, navigation: true, autoplay: { delay: 3000 }, modules: [Navigation] }, { children: popularRaffles.map(function (item, key) {
                                return (_jsx(SwiperSlide, { children: _jsx(Ticket, { item: item, maskColor: withCategories === true ? "#BBE2EE" : "#BBE2EE" }) }, "home-popular-raffles-".concat(item.id, "-").concat(key)));
                            }) }))) : (_jsx(Swiper, __assign({ spaceBetween: 0, breakpoints: {
                                0: {
                                    slidesPerView: 2,
                                    spaceBetween: 0,
                                },
                                948: {
                                    slidesPerView: withCategories ? 4 : 2,
                                    spaceBetween: 0,
                                },
                                1400: {
                                    slidesPerView: withCategories ? 4 : 2,
                                    spaceBetween: 0,
                                },
                                1900: {
                                    slidesPerView: withCategories ? 4 : 2,
                                    spaceBetween: 0,
                                },
                            }, navigation: true, modules: [Navigation] }, { children: popularRaffles.map(function (item, key) {
                                return (_jsx(SwiperSlide, { children: _jsx(Ticket, { item: item, maskColor: withCategories === true ? "#BBE2EE" : "#BBE2EE" }) }, "home-popular-raffles-".concat(item.id, "-").concat(key)));
                            }) }))) }))] }))] }));
}
export function HomeNewRaffleWidget() {
    var forceUpdate = useForceUpdate();
    useEffect(function () {
        var raffleSubject_subscriber = raffleSubject.subscribe(function () {
            // console.log(raffleDraws)
            forceUpdate();
        });
        return function () {
            raffleSubject_subscriber.unsubscribe();
        };
    }, []);
    var newRaffles = raffleDraws
        .filter(function (el) { return el.is_new && el.remaining_tickets > 0; })
        .slice(0, 10);
    return (_jsx(_Fragment, { children: _jsx(Swiper, __assign({ spaceBetween: 10, slidesPerView: 1, autoplay: { delay: 5000 }, modules: [Autoplay, Navigation] }, { children: newRaffles.map(function (el, key) {
                return (_jsx(SwiperSlide, { children: _jsx(TicketSlide, { item: el }) }, "home-popular-raffle-".concat(key)));
            }) })) }));
}
