var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import "./redirectPage.css";
export function RedirectPage() {
    return (_jsx("header", __assign({ className: 'redirect-page-header' }, { children: _jsx("div", __assign({ className: "redirect-page" }, { children: _jsx("p", { children: "Te\u015Fekk\u00FCrler, \u00FCyeli\u011Finiz olu\u015Fturulmu\u015Ftur." }) })) })));
}
