var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { agreements, env, loginModalStyles } from "../../constants/global.constants";
import Modal from "react-modal";
import EyeOffIcon from "../../assets/icons/feather/eye-off.svg";
import { Link } from "react-router-dom";
import { api } from "../../services/http.service";
import EyeIcon from "../../assets/icons/feather/eye.svg";
import { sendValidationCode, userLogin } from "../../services/auth.service";
import { gtmUserEvent, showAgreementModal } from "../../services/misc.functions";
import { modalServiceSubject } from "../../services/modal.service";
import paths from "../../paths";
var s;
var resendButton;
export default function Login(props) {
    var showLoginModal = props.showLoginModal;
    var showPasswordModal = props.showPasswordModal;
    var loginModal = props.loginModal;
    var _a = useState(null), errorMessage = _a[0], setErrorMessage = _a[1];
    var _b = useState(false), showCodeField = _b[0], setShowCodeField = _b[1];
    function closeModal() {
        showLoginModal(false);
    }
    var _c = useForm(), register = _c.register, handleSubmit = _c.handleSubmit, reset = _c.reset, errors = _c.formState.errors;
    var senderTimer = function (s) {
        if (s === void 0) { s = null; }
        var resendTimer = document.getElementById('id_resendTimer');
        if (resendTimer) {
            var secondLeft = Number((Number(resendTimer.innerHTML.split(':')[0]) * 60) + Number(resendTimer.innerHTML.split(':')[1]) - 1);
            if (secondLeft < 0) {
                clearInterval(s);
                resendButton = document.getElementById('id_resendCode');
                if (resendButton) {
                    resendButton.classList.remove('hide');
                }
            }
            else {
                resendTimer.innerHTML = Math.floor(secondLeft / 60) + ':' + ("00" + (secondLeft % 60)).slice(-2);
            }
        }
    };
    var doLogin = function (payload) {
        var loginButton = document.getElementById('id_btnLogin');
        if (loginButton) {
            loginButton.disabled = true;
        }
        userLogin(payload).then(function (response) {
            if (loginButton) {
                loginButton.disabled = false;
            }
            if (response.status) {
                gtmUserEvent('login');
                reset({ user: '', password: '', code: '' });
                setErrorMessage(null);
                setShowCodeField(false);
                showLoginModal(false);
            }
            else if (response.error === 'sms_validate') {
                sendValidationCode(response.data);
                s = setInterval(senderTimer, 1000);
                setShowCodeField(response.data);
            }
            else if (response.error == 'agreement_required') {
                // showAgreementModal('uyelik-sozlesmesi')
                var content = agreements['uyelik-sozlesmesi'];
                modalServiceSubject.next({
                    title: content.title,
                    content: content.content,
                    width: 720,
                    confirm: {
                        cancel: {
                            label: 'Vazgeç'
                        },
                        sure: {
                            label: 'Kabul Et',
                            class: 'btn-info  w-100',
                            action: function () {
                                payload['is_checked'] = true;
                                doLogin(payload);
                            }
                        }
                    }
                });
            }
            else {
                setErrorMessage(response.message);
            }
        });
    };
    var onSubmit = function (payload) {
        if (payload.code && payload.code !== '') {
            api.start('post', env.accounting_api + '/member/api/validation-sms/', {
                mobile: showCodeField.mobile,
                type: "register_validate",
                code: payload.code
            }, false)
                .then(function (res) {
                if (res && res.status) {
                    doLogin(payload);
                }
                else {
                    setErrorMessage(res.message);
                }
            }).catch(function (e) {
                setErrorMessage(e);
            });
        }
        else {
            doLogin(payload);
        }
    };
    var isPasswordVisible = function () {
        var password = document.getElementById("password");
        var eyeIcon = document.getElementById("eye-icon");
        if (password.type === "password") {
            password.type = "text";
            eyeIcon.src = EyeIcon;
        }
        else {
            password.type = "password";
            eyeIcon.src = EyeOffIcon;
        }
    };
    // loginModalStyles['content']['height'] = showCodeField ? '495px' : '420px';
    return (_jsx(_Fragment, { children: _jsx(Modal, __assign({ isOpen: loginModal, onRequestClose: closeModal, style: loginModalStyles, contentLabel: "Example Modal" }, { children: _jsxs("div", __assign({ className: "login-modal" }, { children: [_jsx("h2", { children: "Hesaba Giri\u015F Yap" }), errorMessage && _jsx("p", __assign({ className: "alert text-center alert-danger" }, { children: errorMessage })), _jsxs("form", __assign({ onSubmit: handleSubmit(onSubmit) }, { children: [_jsxs("div", __assign({ className: "mt-2" }, { children: [_jsx("label", { children: "E-posta - TC kimlik" }), _jsx("input", __assign({ className: "form-control" }, register('user', { required: true }))), errors.user &&
                                        _jsx("div", __assign({ className: "invalid-feedback d-block" }, { children: "TCKN yada e-posta gereklidir." }))] })), _jsxs("div", __assign({ className: "mt-2 position-relative" }, { children: [_jsx("label", { children: "\u015Eifre" }), _jsx("input", __assign({ type: "password", className: "form-control", id: "password" }, register('password', { required: true }))), errors.password &&
                                        _jsx("div", __assign({ className: "invalid-feedback d-block" }, { children: "\u015Eifre gereklidir." })), _jsx("div", __assign({ className: "cursor-pointer eye-icon float-right mr-2 position-absolute", onClick: function () { return isPasswordVisible(); } }, { children: _jsx("img", { id: "eye-icon", height: 16, width: 16, src: EyeOffIcon }) }))] })), showCodeField && _jsxs("div", __assign({ className: "mt-4 position-relative" }, { children: [_jsxs("p", __assign({ className: "my-2 alert alert-info" }, { children: ["Oturum a\u00E7abilmek ve \u00FCyelik i\u015Fleminizi tamamlayabilmek i\u00E7in ", _jsxs("b", { children: [" ", showCodeField.mobile] }), " numaral\u0131 telefonunuza g\u00F6nderdi\u011Fimiz do\u011Frulama kodunu giriniz."] })), _jsx("label", { children: "Do\u011Frulama Kodu" }), _jsx("div", __assign({ id: "emailHelp", style: { right: 10, marginTop: 10 }, className: "form-text position-absolute" }, { children: _jsx("span", __assign({ id: "id_resendTimer" }, { children: "0:30" })) })), _jsx("button", __assign({ type: "button", onClick: function () {
                                            clearInterval(s);
                                            sendValidationCode(showCodeField);
                                            if (resendButton) {
                                                resendButton.classList.add('hide');
                                            }
                                            var resendTimer = document.getElementById('id_resendTimer');
                                            if (resendTimer) {
                                                resendTimer.innerHTML = '3:00';
                                            }
                                            s = setInterval(senderTimer, 1000);
                                        }, style: { right: 10, bottom: 8, fontSize: 13, padding: '0px 5px' }, className: "btn hide btn-sm position-absolute btn-warning", id: "id_resendCode" }, { children: "Tekrar G\u00F6nder" })), _jsx("input", __assign({ className: "form-control" }, register('code', { required: true }))), errors.code && _jsx("div", __assign({ className: "invalid-feedback d-block" }, { children: "Do\u011Frulama kodu gereklidir." }))] })), _jsx("div", __assign({ className: "forget-password-link", onClick: function () {
                                    showLoginModal(false);
                                    showPasswordModal(true);
                                } }, { children: "\u015Eifremi Unuttum" })), _jsx("div", __assign({ className: "mt-2" }, { children: _jsx("input", { type: "submit", id: "id_btnLogin", className: "btn btn-primary mt-2", value: "Giri\u015F Yap" }) })), _jsxs("ul", { children: [_jsx("li", { children: _jsx(Link, __assign({ to: paths.register.url, onClick: function () {
                                                showLoginModal(false);
                                            } }, { children: "\u00DCcretsiz \u00DCye Ol" })) }), _jsx("li", { children: _jsx(Link, __assign({ to: '#', onClick: function () {
                                                showAgreementModal('aydinlatma-metni');
                                            } }, { children: "Ayd\u0131nlatma Metni ve Gizlilik Politikas\u0131" })) }), _jsx("li", { children: _jsx(Link, __assign({ to: '#', onClick: function () {
                                                showAgreementModal('uyelik-sozlesmesi');
                                            } }, { children: "\u00DCyelik S\u00F6zle\u015Fmesi" })) }), _jsx("li", { children: _jsx(Link, __assign({ to: 'tel:08502419099' }, { children: "M\u00FC\u015Fteri Hizmetleri" })) })] })] }))] })) })) }));
}
