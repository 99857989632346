var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Subject } from "rxjs";
export var modalServiceSubject = new Subject();
import iconCheck from "../assets/icons/check-star-circle.png";
import iconAlert from "../assets/icons/alert-star-circle.png";
import { CreditCardForm } from "../pages/deposit/credit.card.form";
import iconClose from "../assets/icons/feather/x-circle-red.svg";
var afterLoad = false;
var ModalService = function (props) {
    var _a = useState(false), showModal = _a[0], setShowModal = _a[1];
    var _b = useState(true), showCCForm = _b[0], setShowCCForm = _b[1];
    useEffect(function () {
        var modalServiceSubject_subscription = modalServiceSubject.subscribe(function (obj) {
            if (obj) {
                setShowModal(obj);
                if (obj.creditCard) {
                    setShowCCForm(true);
                }
                if (obj.timeout && obj.timeout > 0) {
                    setTimeout(function () {
                        setShowModal(null);
                    }, obj.timeout);
                }
            }
            else {
                unSetModal();
            }
        });
        return function () {
            modalServiceSubject_subscription.unsubscribe();
            afterLoad = false;
        };
    }, []);
    var unSetModal = function () {
        if (showModal && showModal.onClose) {
            showModal.onClose();
        }
        afterLoad = false;
        setShowModal(false);
    };
    if (showModal) {
        if (showModal.afterLoad && afterLoad === false) {
            afterLoad = true;
            setTimeout(function () {
                showModal.afterLoad();
            }, 5);
        }
        if (showModal.creditCard) {
            return (_jsxs(_Fragment, { children: [props.children, _jsx(CreditCardForm, { showCCForm: showCCForm, setShowCCForm: setShowCCForm, isGame: true })] }));
        }
        return (_jsxs(_Fragment, { children: [props.children, _jsxs("div", __assign({ className: "modal show ".concat(showModal.case || '', " ").concat(showModal.class || ''), style: {
                        width: (showModal.width ? showModal.width : 'auto'),
                        maxWidth: (showModal.width ? 'calc( 100vw - 40px)' : '')
                    } }, { children: [showModal.title ? _jsxs("div", __assign({ className: "header" }, { children: [showModal.closeIcon &&
                                    _jsx("div", __assign({ className: "position-absolute top-0 right-5" }, { children: _jsx("img", { src: iconClose, width: 21, className: "float-right cursor-p ", onClick: unSetModal }) })), showModal.case === 'success' ? _jsxs(_Fragment, { children: [_jsx("img", { src: iconCheck, alt: "" }), _jsx("div", { className: "rev-wave" })] }) : _jsx(_Fragment, {}), showModal.case === 'danger' ? _jsxs(_Fragment, { children: [_jsx("img", { src: iconAlert, alt: "" }), " ", _jsx("div", { className: "rev-wave" })] }) : _jsx(_Fragment, {}), showModal.case === 'info' ? _jsxs(_Fragment, { children: [_jsx("img", { src: iconAlert, alt: "" }), " ", _jsx("div", { className: "rev-wave" })] }) : _jsx(_Fragment, {}), showModal.case === 'warning' ? _jsxs(_Fragment, { children: [_jsx("img", { src: iconAlert, alt: "" }), " ", _jsx("div", { className: "rev-wave" })] }) : _jsx(_Fragment, {}), !showModal.case && showModal.title] })) : _jsx(_Fragment, {}), _jsx("div", { className: "body text-center", style: { maxHeight: 'calc(100vh - 150px)', height: (showModal.height ? showModal.height : 'auto') }, dangerouslySetInnerHTML: { __html: (showModal.case ? "<h2 class=\"title\">".concat(showModal.title, "</h2>") : '') + showModal.content } }), _jsx("div", __assign({ className: "footer p-2 text-end" }, { children: showModal.confirm ? _jsxs(_Fragment, { children: [showModal.confirm.sure ? _jsx(_Fragment, { children: _jsx("button", __assign({ id: "id_btnModelServiceSure", className: "btn mb-2 w-100 " + (showModal.confirm.sure.class || 'btn-primary'), onClick: function () {
                                                if (showModal.confirm.sure.async) {
                                                    showModal.confirm.sure.action().then(function (res) {
                                                        if (res) {
                                                            unSetModal();
                                                        }
                                                    });
                                                }
                                                else {
                                                    showModal.confirm.sure.action();
                                                }
                                                if (showModal.confirm.sure.autoClose !== false) {
                                                    unSetModal();
                                                }
                                            } }, { children: showModal.confirm.sure.label })) }) : _jsx(_Fragment, {}), showModal.confirm.cancel ? _jsx(_Fragment, { children: _jsx("button", __assign({ id: "id_btnModelServiceCancel", className: "btn mb-2 w-100 " + (showModal.confirm.cancel.class || 'btn-secondary'), onClick: function () {
                                                if (showModal.confirm.cancel && showModal.confirm.cancel.action) {
                                                    showModal.confirm.cancel.action();
                                                    if (showModal.confirm.cancel.autoClose !== false) {
                                                        unSetModal();
                                                    }
                                                }
                                                else {
                                                    unSetModal();
                                                }
                                            } }, { children: (showModal.confirm.cancel && showModal.confirm.cancel.label) ? showModal.confirm.cancel.label : 'Vazgeç' })) }) : _jsx(_Fragment, {})] }) : _jsx(_Fragment, {}) }))] })), _jsx("div", { onClick: function () {
                        if (showModal.disableBackdrop !== true) {
                            unSetModal();
                        }
                    }, className: "modal-backdrop show" })] }));
    }
    return (_jsx(_Fragment, { children: props.children }));
};
export default ModalService;
