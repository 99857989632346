var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { gtmSelectPromotion, gtmViewPromotion, useForceUpdate } from "../../services/misc.functions";
import { useEffect } from "react";
import { raffleDraws } from "../../store/games.store";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import { api } from "../../services/http.service";
import { env } from "../../constants/global.constants";
import { homeSliderSubject, homeSlides } from "../../store/misc.store";
import { useNavigate } from "react-router";
import { isMobile } from "../../App";
import "swiper/css/pagination";
import { openRaffleDetail } from "../../services/game.service";
import _ from "lodash";
export function HomeSlider() {
    var forceUpdate = useForceUpdate();
    var navigate = useNavigate();
    useEffect(function () {
        var homeSliderSubject_subscriber = homeSliderSubject.subscribe(function () {
            forceUpdate();
        });
        return function () {
            homeSliderSubject_subscriber.unsubscribe();
        };
    }, []);
    useEffect(function () {
        if (homeSlides.length === 0) {
            api.start("get", env.accounting_api + "/misc/api/list-slide/", null, false).then(function (res) {
                var slideData = _.orderBy(res.data, ["ordering"], ["asc"]);
                homeSlides.splice(0, homeSlides.length);
                homeSlides.push.apply(homeSlides, slideData);
                forceUpdate();
            });
        }
    }, []);
    return (_jsx(_Fragment, { children: _jsx(Swiper, __assign({ spaceBetween: 20, slidesPerView: 1, loop: true, navigation: true, pagination: { clickable: true }, autoplay: { delay: 5000 }, modules: [Autoplay, Navigation], onSlideChange: function (e) {
                try {
                    var slide = homeSlides.filter(function (el) { return el.slide_page === 0; })[e.activeIndex];
                    gtmViewPromotion(slide.title, env.accounting_api + "/media/" + slide.photo_web, slide.web_url, slide.web_url);
                }
                catch (e) { }
            } }, { children: homeSlides
                .filter(function (el) { return el.slide_page === 0; })
                .map(function (el, key) {
                var btnLabel = "Şansını Kaçırma";
                if (el.web_url && el.web_url.indexOf("esya-piyangosu") !== -1 && el.web_url.split("/").length >= 4) {
                    var draw = raffleDraws.find(function (r) { return r.id === Number(el.web_url.split("/")[2]); });
                    if (draw) {
                        btnLabel = "Çekilişe Katıl";
                    }
                }
                if (el.web_url && el.web_url.indexOf("dijital-oyunlar") !== -1 && el.web_url.split("/").length >= 4) {
                    var draw = raffleDraws.find(function (r) { return r.id === Number(el.web_url.split("/")[2]); });
                    if (draw) {
                        btnLabel = "Oyna";
                    }
                }
                if (el.web_url && el.web_url.indexOf("puan-kazan") !== -1 && el.web_url.split("/").length >= 4) {
                    var draw = raffleDraws.find(function (r) { return r.id === Number(el.web_url.split("/")[2]); });
                    if (draw) {
                        btnLabel = "Oyna";
                    }
                }
                // console.log(!isMobile && el.slide_type === 0);
                // console.log(el.web_url);
                return (_jsx(SwiperSlide, { children: !isMobile && el.slide_type === 0 ? (_jsx(_Fragment, { children: _jsx("div", __assign({ className: "custom-slide" }, { children: _jsxs("div", __assign({ className: "row" }, { children: [_jsx("div", __assign({ className: "col-5 col-sm-5 col-lg-7 custom-center" }, { children: _jsx("img", { src: env.accounting_api + "/media/" + (isMobile ? el.photo_mobile : el.photo_web) }) })), _jsxs("div", __assign({ className: "col-7 col-sm-7 col-lg-5 p-5 px-lg-3 p-sm-1 py-lg-5 description" }, { children: [_jsx("h2", { children: el.title }), _jsx("div", { dangerouslySetInnerHTML: { __html: el.content.replaceAll("\n", "<br/>") } }), el.web_url && (_jsx("button", __assign({ onClick: function () {
                                                    gtmSelectPromotion(el.title, env.accounting_api + "/media/" + el.photo_web, el.web_url, el.web_url);
                                                    if (el.web_url.includes("https://")) {
                                                        window.open(el.web_url, "_blank");
                                                    }
                                                    else {
                                                        if (el.web_url.indexOf("esya-piyangosu") !== -1 &&
                                                            el.web_url.split("/").length >= 4) {
                                                            var draw = raffleDraws.find(function (r) { return r.id === Number(el.web_url.split("/")[2]); });
                                                            if (draw) {
                                                                openRaffleDetail(draw);
                                                                btnLabel = "Çekilişe Katıl";
                                                            }
                                                            else {
                                                                navigate("".concat(el.web_url, "?n=/"));
                                                            }
                                                        }
                                                        else {
                                                            navigate("".concat(el.web_url, "?n=/"));
                                                        }
                                                    }
                                                }, className: "btn btn-primary fw-bolder py-3 fs-5  w-100 rounded-3" }, { children: btnLabel })))] }))] })) })) })) : (_jsx(_Fragment, { children: _jsx("div", __assign({ className: "image-slide", onClick: function () {
                                gtmSelectPromotion(el.title, env.accounting_api + "/media/" + el.photo_web, el.web_url, el.web_url);
                                if (el.web_url.includes("https://")) {
                                    window.open(el.web_url, "_blank");
                                }
                                else {
                                    if (el.web_url.indexOf("esya-piyangosu") !== -1 && el.web_url.split("/").length >= 4) {
                                        var draw = raffleDraws.find(function (r) { return r.id === Number(el.web_url.split("/")[2]); });
                                        if (draw) {
                                            openRaffleDetail(draw);
                                        }
                                        else {
                                            navigate("".concat(el.web_url, "?n=/"));
                                        }
                                    }
                                    else {
                                        navigate("".concat(el.web_url, "?n=/"));
                                    }
                                }
                            } }, { children: _jsx("img", { src: env.accounting_api + "/media/" + el.photo_web }) })) })) }, "home-slider-items-".concat(key)));
            }) })) }));
}
