var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { api } from "../../services/http.service";
import { env, months } from "../../constants/global.constants";
import { dateFormat, loadingAnimation, useForceUpdate } from "../../services/misc.functions";
import { useLocation } from "react-router-dom";
import "./results.css";
import { modalServiceSubject } from "../../services/modal.service";
import { isMobile } from "../../App";
import Modal from "react-modal";
import iconClose from "../../assets/icons/feather/x-circle.svg";
import iconBackArrow from "../../assets/icons/feather/arrow-left.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
var years = [2021, 2022, 2023, 2024, 2025, 2026, 2027];
var selectedYear = new Date().getFullYear();
var selectedMonth = new Date().getMonth();
var availableWeeks = null;
export function RaffleResults() {
    var _this = this;
    var pathname = useLocation().pathname;
    var _a = useState([]), dataList = _a[0], setDataList = _a[1];
    var _b = useState([]), dateList = _b[0], setDateList = _b[1];
    var _c = useState(null), selectedDate = _c[0], setSelectedDate = _c[1];
    var _d = useState(null), openRaffleResultDetail = _d[0], setOpenRaffleResultDetail = _d[1];
    var _e = useState(true), loading = _e[0], setLoading = _e[1];
    var forceUpdate = useForceUpdate();
    var getRaffleData = function () {
        // console.log(selectedDate);
        if (selectedDate) {
            var payload = {
            // year: selectedYear,
            // month: selectedMonth + 1,
            // draw_date: selectedDate,
            };
            if (isMobile) {
                payload = {
                    draw_date: selectedDate,
                };
            }
            else {
                payload = {
                    year: selectedYear,
                    month: selectedMonth + 1,
                    draw_date: selectedDate,
                };
            }
            api
                .start("post", env.accounting_api + "/somos/api/goods-lottery/list-draw-result/", payload, false)
                .then(function (res) {
                setDataList(res.data);
            });
        }
    };
    useEffect(function () {
        loadData();
    }, [pathname, selectedYear, selectedMonth, selectedDate]);
    useEffect(function () {
        selectedYear = new Date().getFullYear();
        selectedMonth = new Date().getMonth();
        availableWeeks = null;
        // getRaffleData();
    }, [pathname]);
    var loadData = function (forcePage) {
        if (forcePage === void 0) { forcePage = null; }
        return __awaiter(_this, void 0, void 0, function () {
            var payload;
            return __generator(this, function (_a) {
                loadingAnimation(true);
                setLoading(true);
                payload = {
                // year: selectedYear,
                // month: selectedMonth + 1,
                // draw_date: selectedDate,
                };
                if (isMobile) {
                    payload = {
                        draw_date: selectedDate,
                    };
                }
                else {
                    payload = {
                        year: selectedYear,
                        month: selectedMonth + 1,
                        draw_date: selectedDate,
                    };
                }
                // console.log(payload)
                api
                    .start("post", env.accounting_api + "/somos/api/goods-lottery/list-draw-dates/", payload, false)
                    .then(function (res) {
                    loadingAnimation(false);
                    setLoading(false);
                    if (isMobile) {
                        setDateList(res.data);
                        if (selectedDate == null) {
                            setSelectedDate(res.data[0].draw_date);
                        }
                    }
                    else {
                        if (selectedDate === null) {
                            availableWeeks = Array.from(new Set(res.data.map(function (date) { return date.draw_date; })));
                        }
                    }
                })
                    .finally(function () {
                    if (!isMobile) {
                        if (availableWeeks.length > 0) {
                            if (selectedDate == null) {
                                setSelectedDate(availableWeeks[0]);
                            }
                            getRaffleData();
                        }
                    }
                    else {
                        getRaffleData();
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    var yearChangeHandler = function (year) {
        // console.log(year);
        setDataList([]);
        setSelectedDate(null);
        selectedYear = Number(year);
        forceUpdate();
    };
    var monthChangeHandler = function (month) {
        setDataList([]);
        setSelectedDate(null);
        selectedMonth = Number(month);
        forceUpdate();
    };
    var showWinners = function (item) {
        var _a;
        modalServiceSubject.next({
            title: "Kazanan Biletler - ".concat((_a = item.DrawName) !== null && _a !== void 0 ? _a : ""),
            content: "<p>\n              <div class=\"row mx-0\">\n                  ".concat(item.Winner.map(function (c) {
                return "<div class=\"col-3 p-1 text-center\"><div class=\"border  rounded-2 bg-success-subtle  border-success\">".concat(c.winner_id, "</div></div>");
            }).join(""), "\n              </div>\n            </p>"),
            confirm: {
                cancel: {
                    label: "Kapat",
                    class: "btn btn-secondary w-100",
                },
            },
        });
    };
    var loadMore = function (e) {
        e.target.disabled = true;
        loadData().finally(function () {
            e.target.disabled = false;
        });
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "draw-filter-wrapper-column w-auto mx-0 mb-2" }, { children: [!isMobile && _jsx("h4", { children: "Tarih filtresi" }), isMobile ? (_jsx("div", __assign({ className: "row" }, { children: _jsx("div", __assign({ className: "col-12" }, { children: _jsx("select", __assign({ style: { fontSize: "12px" }, className: "form-control text-center date-result-select", value: selectedDate, onChange: function (e) {
                                    setSelectedDate(e.target.value);
                                } }, { children: dateList.map(function (el, key) {
                                    return (_jsx("option", __assign({ value: el.draw_date }, { children: dateFormat(el.draw_date) }), key + el.draw_date));
                                }) })) })) }))) : (_jsxs("div", __assign({ className: "row mt-2" }, { children: [_jsx("div", __assign({ className: "col-4" }, { children: _jsx("select", __assign({ style: { fontSize: "12px" }, className: "form-control text-center", value: selectedYear, onChange: function (e) {
                                        yearChangeHandler(e.target.value);
                                    } }, { children: years
                                        .filter(function (el) { return el <= Number(new Date().getFullYear()); })
                                        .map(function (el) {
                                        return (_jsx("option", __assign({ value: el }, { children: el }), el));
                                    }) })) })), _jsx("div", __assign({ className: "col-4" }, { children: _jsx("select", __assign({ style: { fontSize: "12px" }, value: selectedMonth, className: "form-control text-center", onChange: function (e) {
                                        monthChangeHandler(e.target.value);
                                    } }, { children: months
                                        .filter(function (el) {
                                        if (Number(selectedYear) === Number(new Date().getFullYear())) {
                                            if (Number(el.val) - 1 <= new Date().getMonth()) {
                                                return el;
                                            }
                                        }
                                        else if (Number(selectedYear) !== Number(new Date().getFullYear())) {
                                            return el;
                                        }
                                    })
                                        .map(function (el, i) {
                                        return (_jsx("option", __assign({ value: i }, { children: el.name }), "months-select-".concat(i)));
                                    }) })) })), _jsx("div", __assign({ className: "col-4" }, { children: _jsx("select", __assign({ style: {
                                        paddingLeft: "5px",
                                        paddingRight: "5px",
                                        fontSize: "12px",
                                    }, className: "form-control text-center", onChange: function (e) {
                                        if (e.target.value !== "-1") {
                                            setSelectedDate(e.target.value);
                                        }
                                        else {
                                            setSelectedDate(null);
                                        }
                                    } }, { children: availableWeeks && availableWeeks.length > 0 ? (availableWeeks.map(function (el, i) {
                                        return (_jsx("option", __assign({ value: el, placeholder: "Tarih Se\u00E7iniz" }, { children: dateFormat(el + "T00:00:00") }), "date-select-".concat(i)));
                                    })) : (_jsx("option", __assign({ placeholder: "Tarih Se\u00E7iniz" }, { children: "Tarih Se\u00E7iniz" }), "date-select")) })) }))] })))] })), _jsx("div", __assign({ className: " my-raffle-order-item" }, { children: dataList.map(function (el, key) {
                    return !isMobile ? (_jsxs("div", __assign({ className: "row item py-2" }, { children: [_jsx("div", __assign({ className: "col-auto" }, { children: _jsx("img", { className: "img-thumbnail", src: "".concat(env.cdn_host, "/media/").concat(el.DrawPicture) }) })), _jsxs("div", __assign({ className: "col-4" }, { children: [_jsx("h2", { children: el.DrawName }), _jsxs("span", __assign({ className: "d-block" }, { children: ["\u00C7ekili\u015F Tarihi: ", dateFormat(el.DrawDate)] })), _jsxs("span", __assign({ className: "d-block" }, { children: ["\u00C7ekili\u015F No: ", el.DrawNo] }))] })), _jsxs("div", __assign({ className: "col-4" }, { children: [_jsx("span", __assign({ className: "d-block result-header fw-bold" }, { children: "Kazanan Biletler" })), _jsx("div", __assign({ className: "row mx-0 justify-content-end" }, { children: el.Winner.map(function (winner, wkey) {
                                            return (_jsx("div", __assign({ className: "col-4 text-center " + (wkey === 2 || wkey === 5 ? "pe-0 ps-1" : "px-1") }, { children: _jsx("div", __assign({ className: "border p-1 mb-2 rounded-2 bg-success-subtle border-success" }, { children: winner.winner_id })) }), "winner-".concat(wkey)));
                                        }) })), el.Winner.length > 6 && (_jsx("span", __assign({ className: "d-block text-end link-primary pointer", onClick: function () {
                                            showWinners(el);
                                        } }, { children: "T\u00FCm Kazanan Biletleri G\u00F6r" })))] }))] }), "raffle-result-".concat(key))) : (_jsxs("div", __assign({ className: "row item py-2" }, { children: [_jsx("div", __assign({ className: "col-4 column-center" }, { children: _jsx("img", { className: "img-thumbnail", src: "".concat(env.cdn_host, "/media/").concat(el.DrawPicture) }) })), _jsxs("div", __assign({ className: "col-8" }, { children: [_jsx("span", __assign({ style: { fontSize: "16px", fontWeight: "700" } }, { children: el.DrawTitle })), _jsxs("span", __assign({ className: "d-block mt-2" }, { children: [_jsx("span", __assign({ className: "text-black-50" }, { children: "\u00C7ekili\u015F Tarihi:" })), " ", _jsx("span", __assign({ className: "fw-bold text-primary" }, { children: dateFormat(el.DrawDate) }))] })), _jsxs("span", __assign({ className: "d-block" }, { children: [_jsx("span", __assign({ className: "text-black-50" }, { children: "\u00C7ekili\u015F No:" })), " ", _jsx("span", __assign({ className: "fw-bold text-primary" }, { children: el.DrawNo }))] })), _jsxs("div", __assign({ className: "d-flex justify-content-between mt-2 align-items-center" }, { children: [_jsx("span", __assign({ className: "d-block text-decoration-underline text-info ", onClick: function () {
                                                    setOpenRaffleResultDetail(el);
                                                } }, { children: "\u00C7ekili\u015F Detaylar\u0131" })), el.Winner.length > 0 && (_jsx("span", __assign({ className: "d-block text-end link-primary pointer", onClick: function () {
                                                    showWinners(el);
                                                } }, { children: "Kazanan Biletleri G\u00F6r" })))] }))] }))] }), "raffle-result-".concat(key)));
                }) })), dataList && dataList.length === 0 && !loading && (_jsx(_Fragment, { children: _jsx("div", __assign({ className: "text-center py-5" }, { children: "Girdi\u011Finiz kriterlerde kay\u0131t bulunamad\u0131." })) })), _jsxs("div", __assign({ className: "text-center" }, { children: [_jsx("div", { id: "id_loaderAnimation", className: "loader" }), _jsx("button", __assign({ id: "id_datalist_loadmore", onClick: loadMore, type: "button", className: "btn rounded-pill btn-outline-primary" }, { children: "Devam\u0131n\u0131 Y\u00FCkle" }))] })), _jsxs(Modal, __assign({ isOpen: openRaffleResultDetail !== null, id: "id_detail_wrapper", className: "default draw-detail-result-modal", onRequestClose: function () {
                    setOpenRaffleResultDetail(null);
                }, contentLabel: "" }, { children: [!isMobile && (_jsx("div", __assign({ className: "modal-close" }, { children: _jsx("img", { src: iconClose, width: 24, className: "float-right cursor-p", onClick: function () { return setOpenRaffleResultDetail(null); } }) }))), _jsxs("div", __assign({ className: "draw-detail-wrapper" }, { children: [_jsx("img", { className: "".concat(openRaffleResultDetail ? "btnBackFixed" : "", " btnBack"), src: iconBackArrow, onClick: function () {
                                    setOpenRaffleResultDetail(null);
                                } }), openRaffleResultDetail && (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "body" }, { children: [_jsx("div", __assign({ className: "photos" }, { children: _jsx(Swiper, __assign({ spaceBetween: 0, slidesPerView: 1, autoplay: { delay: 3000 }, modules: [Autoplay, Pagination, Navigation] }, { children: _jsx(SwiperSlide, { children: _jsx("img", { src: "".concat(env.cdn_host, "/media/").concat(openRaffleResultDetail.DrawPicture) }) }) })) })), _jsx("h2", __assign({ className: "draw-title" }, { children: openRaffleResultDetail.DrawName })), _jsx("p", __assign({ className: "draw-name mb-2 mt-2" }, { children: openRaffleResultDetail.DrawTitle })), _jsx("div", __assign({ className: "row mx-0" }, { children: openRaffleResultDetail.Winner.map(function (c) {
                                                return "<div class=\"col-3 p-1 text-center\"><div class=\"border  rounded-2 bg-success-subtle  border-success\">".concat(c.winner_id, "</div></div>");
                                            }).join("") }))] })) }))] }))] }))] }));
}
