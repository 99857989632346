var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import "./games.css";
import { Link } from "react-router-dom";
import paths from "../../paths";
export function GameLogin() {
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "modal show  success", style: {
                position: 'unset',
                transform: 'none',
                width: '50%',
                maxWidth: 'none',
                margin: "1rem auto"
            } }, { children: [_jsx("div", __assign({ className: "header" }, { children: "\u00DCye Giri\u015Fi" })), _jsx("div", __assign({ className: "body text-center" }, { children: _jsxs("p", { children: ["Bu i\u015Flemi yapabilmeniz i\u00E7in \u00F6nce oturum a\u00E7man\u0131z gerekli.", _jsx("br", {}), "\u00DCye de\u011Filseniz hemen \u00FCye olabilir ya da giri\u015F yaparak al\u0131\u015Fveri\u015Finizi tamamlayabilirsiniz."] }) })), _jsxs("div", __assign({ className: "footer p-2 text-end" }, { children: [_jsx(Link, __assign({ className: "btn mb-2 w-75 btn btn-success", to: paths.login.url }, { children: "Giri\u015F Yap" })), _jsx(Link, __assign({ className: "btn mb-2 w-75 btn btn-info", to: paths.register.url }, { children: "\u00DCye Ol" }))] }))] })) }));
}
