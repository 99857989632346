var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { env } from "../../constants/global.constants";
export function ProductDetail(props) {
    var product = props.product;
    // console.log(product)
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "card product-detail-widget" }, { children: [_jsx("div", __assign({ className: "card-header" }, { children: _jsx("h2", { children: product.name }) })), _jsxs("div", __assign({ className: "card-body" }, { children: [_jsx("img", { className: "picture", src: env.cdn_host + '/media/' + product.ProductPicture, alt: product.code }), _jsx("div", { className: "info", dangerouslySetInnerHTML: { __html: product.description.replaceAll('\n', '<br/>') } })] }))] })) }));
}
