import { Subject } from 'rxjs';
export var auth = {
    member: null,
    token: null,
    authentication: false,
    userValidation: false,
    mobileValidation: false,
    remaining_games: []
};
export var authSubject = new Subject();
export var loginModalSubject = new Subject();
export var sideMenuSubject = new Subject();
