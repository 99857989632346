var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { api } from "../../services/http.service";
import { env } from "../../constants/global.constants";
import { auth } from "../../store/auth.store";
import { dateTimeFormat, loadingAnimation, useForceUpdate } from "../../services/misc.functions";
import { useNavigate, useParams } from "react-router";
import { notifications } from "../../store/misc.store";
import iconTrash from "../../assets/icons/feather/trash-2-red.svg";
import "./notifications.css";
import { modalServiceSubject } from "../../services/modal.service";
import { isMobile } from "../../App";
import { wssProvider } from "../../services/websocket.service";
import paths from "../../paths";
var showNotification = null;
var loading = false;
export function Notifications() {
    var _this = this;
    var params = useParams();
    var navigate = useNavigate();
    var forceUpdate = useForceUpdate();
    var _a = useState(0), page = _a[0], setPage = _a[1];
    var _b = useState(1), selectedTab = _b[0], setSelectedTab = _b[1];
    //fixme pagination eklenmeli
    useEffect(function () {
        setPage(0);
        loadData(0);
    }, [params === null || params === void 0 ? void 0 : params.id]);
    useEffect(function () {
        setPage(0);
        loadData(0);
    }, [selectedTab]);
    var loadData = function (forcePage) {
        if (forcePage === void 0) { forcePage = null; }
        return __awaiter(_this, void 0, void 0, function () {
            var _page;
            return __generator(this, function (_a) {
                loadingAnimation(true);
                _page = forcePage != null ? forcePage : page;
                loading = true;
                api.start('post', env.accounting_api + '/misc/api/list-member-notification/', {
                    metadata: {
                        offset: _page,
                        limit: 20
                    }, type: selectedTab.toString()
                }, true)
                    .then(function (res) {
                    loadingAnimation(false);
                    // notifications.splice(0, notifications.length);
                    if (_page == 0) {
                        notifications.splice(0, notifications.length);
                        notifications.push.apply(notifications, res.data);
                    }
                    else {
                        notifications.push.apply(notifications, res.data);
                    }
                    forceUpdate();
                    if (res && res.metadata) {
                        var id_datalist_loadmore = document.getElementById('id_datalist_loadmore');
                        if (res.metadata.count === 0 || (res.metadata.count <= notifications.length)) {
                            id_datalist_loadmore.style.display = 'none';
                        }
                        else {
                            id_datalist_loadmore.style.display = 'unset';
                        }
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    var loadMore = function (e) {
        e.target.disabled = true;
        var nPage = page + 1;
        setPage(nPage);
        loadData(nPage).finally(function () {
            e.target.disabled = false;
        });
    };
    var deleteNotification = function (id) {
        var index = notifications.findIndex(function (el) { return el.id === id; });
        notifications.splice(index, 1);
        forceUpdate();
        if (Number(params.notification_id) === id) {
            showNotification = null;
            navigate(paths.notifications.url);
        }
        //fixme : silme aktif edilecek api bekliyor
        api.start('post', env.accounting_api + '/misc/api/delete-notification/', { notification_id: id }, true)
            .then(function (res) {
            // let index = notifications.findIndex((el:any)=>el.id === id);
            // notifications.splice(index,1);
            forceUpdate();
        });
    };
    var readAll = function () {
        api.start('post', env.accounting_api + '/misc/api/update-notification/', {
            "type": selectedTab
        }, true)
            .then(function (res) {
            wssProvider.checkauth();
            for (var _i = 0, notifications_1 = notifications; _i < notifications_1.length; _i++) {
                var notification = notifications_1[_i];
                notification.is_seen = true;
            }
            forceUpdate();
        });
    };
    // useEffect(()=>{
    //
    //     api.start('post', env.accounting_api + '/misc/api/update-notification/', {notification_id:id}, true)
    //       .then((res: any) => {
    //         let index = notifications.findIndex((el:any)=>el.id === id);
    //         notifications[index].is_seen = true;
    //
    //       })
    //
    // },[]);
    if (params.notification_id) {
        showNotification = notifications.find(function (el) { return Number(el.id) === Number(params.notification_id); });
        if (!showNotification) {
            // exceptionModal("Bildirim bulunamadı, lütfen tekrar deneyiniz.");
            //   return false;
        }
        if (showNotification && showNotification.is_seen === false) {
            showNotification.is_seen = true;
            api.start('post', env.accounting_api + '/misc/api/update-notification/', { notification_id: showNotification.id }, true)
                .then(function (res) {
                showNotification.is_seen = true;
                // auth.member.unread_notification_count--;
                // authSubject.next(true);
                wssProvider.checkauth();
            });
        }
    }
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "" }, { children: [isMobile && _jsx("div", __assign({ className: "row inner-page-top-tabs mb-4" }, { children: _jsxs("div", __assign({ className: "liner-tabs", style: { '--tab-count': '2' } }, { children: [_jsx("input", { type: "radio", value: "0", checked: selectedTab === 1, "data-rel": "tab1", id: "raffle-id_tab-1", onChange: function () {
                                    window.history.pushState(null, "", paths.notifications.url);
                                    setSelectedTab(1);
                                }, name: "tabs" }), _jsx("label", __assign({ className: "tab text-center", htmlFor: "raffle-id_tab-1" }, { children: "Bildirimler" })), _jsx("input", { type: "radio", value: "1", "data-rel": "tab2", checked: selectedTab === 0, id: "raffle-id_tab-2", onChange: function () {
                                    window.history.pushState(null, "", paths.notifications.url);
                                    setSelectedTab(0);
                                }, name: "tabs" }), _jsx("label", __assign({ className: "tab text-center", htmlFor: "raffle-id_tab-2" }, { children: "Duyurular" })), _jsx("span", { className: "glider" })] })) })), !isMobile && _jsx("div", __assign({ className: "row" }, { children: _jsx("div", __assign({ className: "mb-3" }, { children: _jsxs("div", __assign({ className: "slider-tabs", style: { '--tab-count': '2', border: "1px solid #EDF1F8" } }, { children: [_jsx("input", { type: "radio", value: "0", checked: selectedTab === 1, "data-rel": "tab1", id: "raffle-id_tab-1", onChange: function () {
                                        window.history.pushState(null, "", paths.notifications.url);
                                        setSelectedTab(1);
                                    }, name: "tabs" }), _jsx("label", __assign({ className: "tab", htmlFor: "raffle-id_tab-1" }, { children: "Bildirimler" })), _jsx("input", { type: "radio", checked: selectedTab === 0, value: "1", "data-rel": "tab2", id: "raffle-id_tab-2", onChange: function () {
                                        window.history.pushState(null, "", paths.notifications.url);
                                        setSelectedTab(0);
                                    }, name: "tabs" }), _jsx("label", __assign({ className: "tab", htmlFor: "raffle-id_tab-2" }, { children: "Duyurular" })), _jsx("span", { className: "glider" })] })) })) })), _jsxs("div", __assign({ className: "custom-row notification-message" }, { children: [_jsxs("p", __assign({ className: "px-3" }, { children: [auth.member.unread_notification_count > 0 ? auth.member.unread_notification_count + ' okunmamış mesajınız bulunmakta.' : '', " Toplam ", notifications.length, " adet ", selectedTab == 0 ? 'duyurunuz' : 'bildiriminiz', " var."] })), auth.member.unread_notification_count > 0 &&
                            _jsx("button", __assign({ className: "btn btn-primary btn-all-read float-end rounded-3 m-n2 btn-sm-mobile", onClick: function () {
                                    readAll();
                                } }, { children: "T\u00FCm\u00FCn\u00FC Oku" }))] })), _jsxs("div", __assign({ className: "row" }, { children: [notifications && notifications.length > 0 && _jsx("div", __assign({ className: showNotification ? "col-lg-7" : "col-lg-12" }, { children: _jsx("div", __assign({ className: "table-responsive" }, { children: _jsxs("table", __assign({ className: "table notifications table-striped" }, { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", __assign({ style: { width: 120 } }, { children: "Tarih" })), _jsx("th", { children: "Mesaj" }), _jsx("th", { style: { width: 40 } })] }) }), _jsx("tbody", { children: notifications.map(function (el, key) {
                                                return _jsxs("tr", __assign({ className: "".concat(el.is_seen === false ? 'unreaded' : '', " pointer") }, { children: [_jsx("td", __assign({ onClick: function () {
                                                                if (isMobile) {
                                                                    modalServiceSubject.next({
                                                                        case: 'info',
                                                                        width: '400px',
                                                                        title: el.title,
                                                                        content: "<p class=\"text-center\">".concat(el.content, "</p>"),
                                                                        confirm: {
                                                                            sure: {
                                                                                label: 'Sil',
                                                                                class: 'btn-secondary w-100',
                                                                                action: function () {
                                                                                    deleteNotification(el.id);
                                                                                }
                                                                            },
                                                                            cancel: {
                                                                                label: 'Kapat',
                                                                                class: 'btn-danger w-100',
                                                                            }
                                                                        }
                                                                    });
                                                                }
                                                                else {
                                                                    navigate("".concat(paths.notifications.url, "/").concat(el.id, "/"));
                                                                }
                                                            } }, { children: dateTimeFormat(el.create_date) })), _jsx("td", __assign({ className: "text-wrap", onClick: function () {
                                                                if (isMobile) {
                                                                    modalServiceSubject.next({
                                                                        case: 'info',
                                                                        width: '400px',
                                                                        title: el.title,
                                                                        content: "<p class=\"text-center\">".concat(el.content, "</p>"),
                                                                        confirm: {
                                                                            sure: {
                                                                                label: 'Sil',
                                                                                class: 'btn-secondary w-100',
                                                                                action: function () {
                                                                                    deleteNotification(el.id);
                                                                                }
                                                                            },
                                                                            cancel: {
                                                                                label: 'Kapat',
                                                                                class: 'btn-danger w-100',
                                                                            }
                                                                        }
                                                                    });
                                                                }
                                                                else {
                                                                    navigate("".concat(paths.notifications.url, "/").concat(el.id, "/"));
                                                                }
                                                            } }, { children: el.title })), _jsx("td", __assign({ className: "text-end" }, { children: selectedTab != 0 && selectedTab != 1 ||
                                                                _jsx("img", { src: iconTrash, className: "pointer", width: 18, onClick: function () {
                                                                        deleteNotification(el.id);
                                                                    } }) }))] }), key);
                                            }) })] })) })) })), showNotification && _jsxs("div", __assign({ className: "col-lg-5 mt-4" }, { children: [_jsxs("h1", __assign({ className: "text-wrap" }, { children: [_jsx("i", __assign({ style: {
                                                fontSize: 12,
                                                color: 'var(--bs-gray-500)'
                                            } }, { children: dateTimeFormat(showNotification.create_date) })), _jsx("br", {}), showNotification.title] })), _jsx("p", { className: "text-wrap", dangerouslySetInnerHTML: { __html: showNotification.content.replaceAll('\n', '<br/>') } }), selectedTab == 1 &&
                                    _jsx("button", __assign({ onClick: function () {
                                            deleteNotification(showNotification.id);
                                        }, className: "btn  btn-danger mt-3" }, { children: "Sil" }))] }))] })), _jsxs("div", __assign({ className: "text-center" }, { children: [_jsx("div", { id: "id_loaderAnimation", className: "loader" }), _jsx("button", __assign({ id: "id_datalist_loadmore", onClick: loadMore, type: "button", className: "btn rounded-pill btn-outline-primary" }, { children: "Devam\u0131n\u0131 Y\u00FCkle" }))] }))] })) }));
}
