var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import "./draw.css";
import { useEffect, useState } from "react";
import { raffleDraws, raffleDrawSubject } from "../../store/games.store";
import { useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
import iconTimer from "../../assets/icons/timer.png";
import { dateFormat, gtmViewItem, slugify, useForceUpdate } from "../../services/misc.functions";
import { cartStore, cartToggleSubject } from "../../store/cart.store";
import { addToCart, removeFromCart, updateCart } from "../../services/cart.service";
import { isMobile } from "../../App";
import iconBackArrow from "../../assets/icons/feather/arrow-left.svg";
import Modal from "react-modal";
import iconClose from "../../assets/icons/feather/x-circle.svg";
import paths from "../../paths";
import shareIcon from "../../assets/icons/feather/share-2.svg";
import { toast } from "react-toastify";
import { env } from "../../constants/global.constants";
var sidebar = null;
var detailBackdrop = null;
var ticket;
var prevUrl = "";
var prevTitle = "";
export function DrawDetail() {
    var _a;
    var pathname = useLocation().pathname;
    var drawPath = String(pathname);
    var _b = useState(null), showDetail = _b[0], setShowDetail = _b[1];
    var forceUpdate = useForceUpdate();
    var _c = useState(true), hidden = _c[0], setHidden = _c[1];
    if (showDetail) {
        ticket = showDetail;
    }
    useEffect(function () {
        var raffleDrawSubject_subscriber = raffleDrawSubject.subscribe(function (res) {
            sidebar = document.getElementById("id_detail_wrapper");
            if (res && res.action === "open") {
                var draw = raffleDraws.find(function (el) { return el.id === res.id; });
                if (draw) {
                    openDetail(draw);
                }
            }
            else if (res && res.action === "history") {
                openDetail(res.draw);
            }
            else {
                closeDetail();
            }
        });
        return function () {
            raffleDrawSubject_subscriber.unsubscribe();
        };
    }, []);
    useEffect(function () {
        var cartToggleSubject_subscriber = cartToggleSubject.subscribe(function (res) {
            if ((res && res.item && res.item.item && res.item.item.id === (ticket === null || ticket === void 0 ? void 0 : ticket.id)) ||
                (res.list && res.list.includes(ticket === null || ticket === void 0 ? void 0 : ticket.id))) {
                forceUpdate();
            }
        });
        return function () {
            cartToggleSubject_subscriber.unsubscribe();
        };
    }, []);
    var openDetail = function (draw) {
        // console.log("openDetail", draw)
        // if(sidebar){
        //   sidebar.style.bottom = "0";
        //   sidebar.style.visibility = "visible";
        //
        // }
        // detailBackdrop = document.getElementById("detail-backdrop");
        // if (detailBackdrop) {
        //   detailBackdrop.classList.add('show');
        // }
        prevUrl = window.location.pathname;
        prevTitle = document.title;
        document.title = draw.title_abbreviated;
        window.history.pushState(null, "", "/esya-piyangosu/" + draw.id + "/" + slugify(draw.name) + "/?n=" + prevUrl);
        setShowDetail(draw);
    };
    var closeDetail = function () {
        // if(sidebar){
        //   sidebar.style.bottom = "-600px";
        //   sidebar.style.visibility = "hidden";
        // }
        if (showDetail) {
            document.title = prevTitle;
            window.history.pushState(null, "", prevUrl);
        }
        setShowDetail(null);
    };
    if (showDetail) {
        document.body.style.overflow = "hidden";
        try {
            // @ts-ignore
            fbq('track', 'ViewContent', {
                content_name: showDetail.name,
                content_category: showDetail.draw_categories_id,
                content_ids: showDetail.id,
                content_type: 'product',
                value: Number(showDetail.ticket_price).toFixed(2),
                currency: 'TRY',
                event_source_url: env.web_link + "/esya-piyangosu/" + showDetail.id + "/" + slugify(showDetail.name)
            });
        }
        catch (e) {
            console.log(e);
        }
    }
    else {
        document.body.style.overflow = "unset";
    }
    /*
      return (<>
        <div  className={"draw-detail-wrapper"}>
  
          <img className={`${showDetail ? 'btnBackFixed' : ''} btnBack`} src={iconBackArrow} onClick={()=>{
            closeDetail();
          }} />
  
          {showDetail && <div className="body">
            <div className="photos">
              <Swiper
                spaceBetween={0}
                slidesPerView={1}
                autoplay={{delay:3000}}
                modules={[Autoplay, Pagination, Navigation]}
              >
                <SwiperSlide><img src={ticket.detail_photo} /></SwiperSlide>
              </Swiper>
            </div>
  
            <div className="timer">
              <img src={iconTimer} />
              <span>{ticket.leftDays()} gün</span>
            </div>
  
            <h2 className="draw-title">{ticket.title_abbreviated}</h2>
            <p className="draw-name mb-2">{ticket.name}</p>
  
            <div className="flags mb-4">
              {ticket.is_new && <span className="px-2 ms-0 translate-middle text-white rounded-pill bg-primary">Yeni</span>}
              {ticket.is_popular && <span className="px-2 translate-middle text-white rounded-pill bg-warning">Çok Satan</span>}
              {ticket.second_hand && <span className="px-2 translate-middle text-white rounded-pill bg-secondary">2.El</span>}
              {ticket.is_running_out && <span className="px-2 translate-middle text-white rounded-pill bg-success">Kuponlu</span>}
            </div>
  
  
            <div className="desciption" dangerouslySetInnerHTML={{__html:ticket.detail}}></div>
  
  
            <div className="footer">
  
              <div className="cutEffect">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
  
              <div className="row">
                {!isMobile && <div className="col-6">
                  <div className="detail">
                    <div>Çekiliş Tarihi: <span className="text-dark">{dateFormat(ticket.draw_date)}</span></div>
                    <div>Toplam Bilet: <span className="text-dark">{ticket.total_tickets} adet</span></div>
                  </div>
                </div>}
                <div className={isMobile?"col-12 px-4 py-3":"col-6 px-4 py-3"}>
  
                  {ticket.is_added && <div className="add">
                    <div className="input w-60">
                      <i onClick={()=>{
                        if(cartStore.items[ticket.cartIndex] && cartStore.items[ticket.cartIndex]?.quantity>0) {
                          updateCart(cartStore.items[ticket.cartIndex], ticket.cartIndex, Number((cartStore.items[ticket.cartIndex]?.quantity)) - 1)
                        }
                      }}>-</i>
                      <input className="no-arrows" type="number" value={cartStore.items[ticket.cartIndex]?.quantity || ''} onChange={(e:any)=>{
                        updateCart(cartStore.items[ticket.cartIndex], ticket.cartIndex, e.target.value)
                      }} onBlur={(e:any)=>{
                        if(e.target.value==='' || e.target.value==='0'){
                          removeFromCart(cartStore.items[ticket.cartIndex], ticket.cartIndex);
                        }
                      }}  max={100} step={1} min={1} />
                      <i onClick={()=> {
                        if (cartStore.items[ticket.cartIndex] && cartStore.items[ticket.cartIndex]?.quantity < 100 && cartStore.items[ticket.cartIndex]?.quantity < ticket.remaining_tickets) {
                          updateCart(cartStore.items[ticket.cartIndex], ticket.cartIndex, Number(cartStore.items[ticket.cartIndex].quantity) + 1)
                        }
                      }}>+</i>
                    </div>
                    <div className="price w-40"><span>{Number(ticket.ticket_price).toFixed(2)}₺</span></div>
                  </div>}
  
                  {ticket.is_added !== true && ticket.remaining_tickets > 0 && <button  onClick={()=>{
                    addToCart(ticket, 1, 'raffle')
                  }} disabled={![true, 1].includes(ticket.status)} className={"btn btn-add-to-cart w-100  rounded-pill " + (ticket.status===1?'btn-success':'btn-secondary')}>
                    {ticket.status===true || ticket.status === 1 ? <>Bilet Al</>: <>Çekilişe Kapalı</>}
                    <span className="price">{Number(ticket.ticket_price).toFixed(2)}₺</span>
                  </button>}
  
  
                  {ticket.remaining_tickets === 0 && <button className="btn fw-bold w-100 text-center btn-secondary rounded-pill">
                    Tükendi
                  </button>}
  
                </div>
              </div>
  
            </div>
  
          </div>}
        </div>
        {showDetail && <div onClick={()=>{
          closeDetail();
        }} className="modal-backdrop show" id="detail-backdrop"></div>}
      </>)
  
      */
    return (_jsxs(Modal, __assign({ isOpen: showDetail !== null, id: "id_detail_wrapper", className: "default draw-detail-modal", onRequestClose: function () {
            closeDetail();
        }, onAfterOpen: function () {
            gtmViewItem([ticket], "Çekiliş");
        }, contentLabel: "" }, { children: [!isMobile && (_jsx("div", __assign({ className: "modal-close" }, { children: _jsx("img", { src: iconClose, width: 24, className: "float-right cursor-p", onClick: function () { return closeDetail(); } }) }))), _jsxs("div", __assign({ className: "draw-detail-wrapper" }, { children: [_jsx("img", { className: "".concat(showDetail ? "btnBackFixed" : "", " btnBack"), src: iconBackArrow, onClick: function () {
                            closeDetail();
                        } }), showDetail && (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "body" }, { children: [_jsx("div", __assign({ className: "photos" }, { children: _jsx(Swiper, __assign({ spaceBetween: 0, slidesPerView: 1, autoplay: { delay: 3000 }, modules: [Autoplay, Pagination, Navigation] }, { children: _jsx(SwiperSlide, { children: _jsx("img", { src: ticket.detail_photo }) }) })) })), _jsxs("div", __assign({ className: "share", style: { cursor: "pointer" }, onClick: function () {
                                        navigator.clipboard.writeText(window.location.href).then(function () {
                                            toast.success("Bağlantı kopyalandı.", {
                                                position: "top-right",
                                                autoClose: 5000,
                                                hideProgressBar: false,
                                                closeOnClick: true,
                                                pauseOnHover: false,
                                                draggable: true,
                                                progress: undefined,
                                            });
                                        });
                                    } }, { children: [_jsx("img", { src: shareIcon }), " Payla\u015F"] })), _jsxs("div", __assign({ className: "timer" }, { children: [_jsx("img", { src: iconTimer }), _jsxs("span", { children: [ticket.leftDays(), " g\u00FCn"] })] })), _jsx("h1", __assign({ className: "draw-title" }, { children: ticket.title_abbreviated })), _jsx("h2", __assign({ style: { fontSize: "16px" }, className: "draw-name mb-2 mt-2" }, { children: ticket.name })), _jsxs("div", __assign({ className: "flags mb-4" }, { children: [ticket.is_new && (_jsx("span", __assign({ className: "px-2 ms-0 translate-middle text-white rounded-pill bg-primary" }, { children: "Yeni" }))), ticket.is_popular && (_jsx("span", __assign({ className: "px-2 translate-middle text-white rounded-pill bg-warning" }, { children: "\u00C7ok Satan" }))), ticket.second_hand && (_jsx("span", __assign({ className: "px-2 translate-middle text-white rounded-pill bg-secondary" }, { children: "2.El" }))), ticket.is_running_out && (_jsx("span", __assign({ className: "px-2 translate-middle text-white rounded-pill bg-success" }, { children: "Kuponlu" })))] })), _jsx("div", { className: "desciption", dangerouslySetInnerHTML: { __html: ticket.detail.replaceAll("\n", "<br/>") } }), _jsxs("div", __assign({ className: "footer" }, { children: [_jsxs("div", __assign({ className: "cutEffect" }, { children: [_jsx("span", {}), _jsx("span", {}), _jsx("span", {}), _jsx("span", {}), _jsx("span", {}), _jsx("span", {}), _jsx("span", {}), _jsx("span", {}), _jsx("span", {}), _jsx("span", {}), _jsx("span", {}), _jsx("span", {}), _jsx("span", {}), _jsx("span", {}), _jsx("span", {}), _jsx("span", {})] })), _jsxs("div", __assign({ className: drawPath !== paths.raffleTickets.url && !isMobile
                                                ? "row product-foot "
                                                : "row product-foot column-gap-xxl-5" }, { children: [!isMobile && (_jsx("div", __assign({ className: "col-5" }, { children: _jsxs("div", __assign({ className: "detail" }, { children: [_jsxs("div", { children: ["\u00C7ekili\u015F Tarihi:", _jsx("span", __assign({ className: "text-dark" }, { children: dateFormat(ticket.draw_date) }))] }), _jsxs("div", { children: ["Toplam Bilet:", _jsxs("span", __assign({ className: "text-dark" }, { children: [ticket.total_tickets, " adet"] }))] })] })) }))), _jsxs("div", __assign({ className: isMobile ? "col-12 px-4 py-3 d-flex justify-content-around" : "col-4 " }, { children: [ticket.is_added && (_jsxs("div", __assign({ className: !isMobile ? "add" : " add" }, { children: [_jsxs("div", __assign({ className: "input w-60" }, { children: [_jsx("i", __assign({ onClick: function () {
                                                                                var _a, _b;
                                                                                if (cartStore.items[ticket.cartIndex] &&
                                                                                    ((_a = cartStore.items[ticket.cartIndex]) === null || _a === void 0 ? void 0 : _a.quantity) > 0) {
                                                                                    updateCart(cartStore.items[ticket.cartIndex], ticket.cartIndex, Number((_b = cartStore.items[ticket.cartIndex]) === null || _b === void 0 ? void 0 : _b.quantity) - 1);
                                                                                }
                                                                            } }, { children: "-" })), _jsx("input", { className: "no-arrows", type: "number", value: ((_a = cartStore.items[ticket.cartIndex]) === null || _a === void 0 ? void 0 : _a.quantity) || "", onChange: function (e) {
                                                                                updateCart(cartStore.items[ticket.cartIndex], ticket.cartIndex, e.target.value);
                                                                            }, onBlur: function (e) {
                                                                                if (e.target.value === "" || e.target.value === "0") {
                                                                                    removeFromCart(cartStore.items[ticket.cartIndex], ticket.cartIndex);
                                                                                }
                                                                            }, max: 100, step: 1, min: 1 }), _jsx("i", __assign({ onClick: function () {
                                                                                var _a, _b;
                                                                                if (cartStore.items[ticket.cartIndex] &&
                                                                                    ((_a = cartStore.items[ticket.cartIndex]) === null || _a === void 0 ? void 0 : _a.quantity) < 100 &&
                                                                                    ((_b = cartStore.items[ticket.cartIndex]) === null || _b === void 0 ? void 0 : _b.quantity) < ticket.remaining_tickets) {
                                                                                    updateCart(cartStore.items[ticket.cartIndex], ticket.cartIndex, Number(cartStore.items[ticket.cartIndex].quantity) + 1);
                                                                                }
                                                                            } }, { children: "+" }))] })), _jsx("div", __assign({ className: "price w-40" }, { children: _jsxs("span", { children: [Number(ticket.ticket_price).toFixed(2), "\u20BA"] }) }))] }))), ticket.is_added !== true && ticket.remaining_tickets > 0 && (_jsxs("button", __assign({ onClick: function () {
                                                                addToCart(ticket, 1, "raffle");
                                                            }, disabled: ![true, 1].includes(ticket.status), className: "btn btn-add-to-cart w-100  rounded-pill " +
                                                                (ticket.status === 1 ? "btn-success" : "btn-secondary") }, { children: [ticket.status === true || ticket.status === 1 ? _jsx(_Fragment, { children: "Bilet Al" }) : _jsx(_Fragment, { children: "\u00C7ekili\u015Fe Kapal\u0131" }), _jsxs("span", __assign({ className: "price" }, { children: [Number(ticket.ticket_price).toFixed(2), "\u20BA"] }))] }))), ticket.remaining_tickets === 0 && (_jsx("button", __assign({ className: "btn fw-bold w-100 text-center btn-secondary rounded-pill" }, { children: "T\u00FCkendi" })))] })), _jsx("div", __assign({ className: isMobile ? "col-6 px-5" : "col-3 px-4" }, { children: drawPath !== paths.raffleTickets.url && !isMobile && (_jsx("button", __assign({ onClick: function () {
                                                            closeDetail();
                                                            cartToggleSubject.next({
                                                                action: "visibleToggleCart",
                                                                show: hidden,
                                                            });
                                                        }, className: "mx-1 px-2 text-white bg-primary basket-btn" }, { children: "Sepete Git" }))) }))] }))] }))] })) }))] }))] })));
}
