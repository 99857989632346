var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import upward from "../../assets/icons/upward.svg";
import './ScrollToTop.css';
var ScrollToTop = function () {
    var _a = useState(false), isVisible = _a[0], setIsVisible = _a[1];
    useEffect(function () {
        window.addEventListener('scroll', function () {
            if (window.scrollY > 100) {
                setIsVisible(true);
            }
            else {
                setIsVisible(false);
            }
        });
    }, []);
    var goTop = function () {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
    return (_jsx("button", __assign({ className: "btn-scrollTop", style: { display: isVisible ? 'block' : 'none' }, onClick: goTop }, { children: _jsx("img", { src: upward }) })));
};
export default ScrollToTop;
