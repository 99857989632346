var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { api } from "../../services/http.service";
import { env } from "../../constants/global.constants";
import { exceptionModal } from "../../services/misc.functions";
import { AccountInfoMenu } from "./account";
import _ from "lodash";
export function AccountActivities() {
    var _a = useState(null), activityList = _a[0], setActivityList = _a[1];
    useEffect(function () {
        api.start('get', env.accounting_api + '/member/api/list/activities/', null, true)
            .then(function (res) {
            // console.log(res)
            if (res && res.status) {
                setActivityList(_.orderBy(res.data, ['id'], ['desc']));
            }
            else {
                setActivityList([]);
            }
        }).catch(function (e) {
            exceptionModal(e);
            setActivityList([]);
        });
    }, []);
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "row" }, { children: [_jsx("div", __assign({ className: "col-3 col-mobile-12" }, { children: _jsx(AccountInfoMenu, {}) })), _jsxs("div", __assign({ className: "col-9 col-mobile-12" }, { children: [_jsxs("div", __assign({ className: "card rounded-4 mb-4" }, { children: [_jsx("div", __assign({ className: "card-header" }, { children: _jsx("h2", { children: "Giri\u015F Denemeleri" }) })), _jsxs("div", __assign({ className: "card-body mobile-scroll" }, { children: [_jsx("p", __assign({ className: "white-space-normal" }, { children: "TC Kimlik Numaran\u0131z, YKN veya e-posta adresiniz ile hesab\u0131n\u0131za ba\u015Far\u0131l\u0131 ve ba\u015Far\u0131s\u0131z son be\u015F giri\u015F i\u015Fleminiz listelenmektedir." })), !activityList && _jsx("div", { className: "loader" }), activityList && _jsx("table", __assign({ className: "table table-bordered table-striped" }, { children: _jsx("thead", { children: activityList.map(function (el, key) {
                                                    return (_jsxs("tr", { children: [_jsxs("td", __assign({ className: "nowrap" }, { children: [_jsx("i", __assign({ className: "".concat(el.flag === 'info' ? 'badge bg-info me-1' : '') }, { children: "i" })), el.message, _jsx("span", __assign({ className: "d-block fs-7" }, { children: el.create_date }))] })), _jsxs("td", { children: [el.ip, _jsx("span", __assign({ className: "d-block fs-7" }, { children: el.agent }))] })] }, "account-activity-".concat(key)));
                                                }) }) }))] }))] })), _jsxs("div", __assign({ className: "card rounded-4 mt-4" }, { children: [_jsx("div", __assign({ className: "card-header" }, { children: _jsx("h2", { children: "Hesap G\u00FCvenli\u011Fi" }) })), _jsx("div", __assign({ className: "card-body" }, { children: _jsxs("p", __assign({ className: "white-space-normal" }, { children: ["Hesab\u0131n\u0131z\u0131n g\u00FCvenli\u011Fi bizim i\u00E7in \u00F6nemlidir. E\u011Fer hesab\u0131n\u0131z ile ilgili \u015F\u00FCpheli bir durum varsa bize ", _jsx("b", { children: "0850 241 90 99 " }), " numaral\u0131 telefon numaras\u0131ndan ula\u015Fabilirsiniz."] })) }))] }))] }))] })) }));
}
