var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { env } from "../../constants/global.constants";
import Modal from "react-modal";
import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { api } from "../../services/http.service";
import { exceptionModal } from "../../services/misc.functions";
import { modalServiceSubject } from "../../services/modal.service";
import { useNavigate } from "react-router";
import iconClose from "../../assets/icons/feather/x-circle.svg";
import paths from "../../paths";
export function ResetPassword() {
    var pathname = useLocation().pathname;
    var navigate = useNavigate();
    var _a = useSearchParams(), searchParams = _a[0], setSearchParams = _a[1];
    var _b = useState(null), errorMessage = _b[0], setErrorMessage = _b[1];
    var _c = useState(pathname === paths.resetPassword), showPasswordResetModal = _c[0], setShowPasswordResetModal = _c[1];
    var _d = useForm(), register = _d.register, handleSubmit = _d.handleSubmit, reset = _d.reset, errors = _d.formState.errors;
    // console.log()
    useEffect(function () {
        if (pathname === paths.resetPassword.url) {
            setShowPasswordResetModal(true);
        }
    }, [pathname]);
    var onSubmit = function (payload) {
        payload['hash'] = searchParams.get('hash');
        // console.log(payload)
        if (payload.password !== payload.password2) {
            return;
        }
        api.start('post', env.accounting_api + '/member/api/password-renew/', payload, false)
            .then(function (res) {
            // console.log(res)
            if (res.status) {
                setShowPasswordResetModal(false);
                modalServiceSubject.next({
                    case: 'success',
                    title: 'Parola sıfırlandı',
                    content: "<p>Yeni parolan\u0131z\u0131n tan\u0131mlanmas\u0131 yap\u0131ld\u0131. art\u0131k g\u00FCncel parolan\u0131z ile giri\u015F yapabilirsiniz.</p>",
                    confirm: {
                        cancel: {
                            label: 'Kapat',
                        },
                        sure: {
                            label: 'Giriş Yap',
                            action: function () {
                                navigate(paths.login.url);
                            }
                        }
                    }
                });
            }
            else {
                setErrorMessage(res.message);
            }
        })
            .catch(function (e) {
            exceptionModal(e);
        });
    };
    return (_jsx(_Fragment, { children: _jsxs(Modal, __assign({ isOpen: showPasswordResetModal, onRequestClose: function () {
            }, style: {
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    maxWidth: 'calc(100vw - 80px)',
                    width: '460px',
                    padding: '1.5rem',
                    maxHeight: 'calc(100vh - 100px)',
                    transform: 'translate(-50%, -50%)',
                    background: 'var(--bs-white)',
                },
            }, contentLabel: "" }, { children: [_jsx("div", __assign({ className: "modal-close" }, { children: _jsx("img", { src: iconClose, width: 24, className: "float-right cursor-p ", onClick: function () { return setShowPasswordResetModal(false); } }) })), _jsxs("div", __assign({ className: "forget-password-modal" }, { children: [_jsx("h2", { children: "Parola S\u0131f\u0131rla" }), _jsx("p", { children: "\u015Eifreniz en az 6 karakterden olu\u015Fmal\u0131 ve bir k\u00FC\u00E7\u00FCk harf, bir b\u00FCy\u00FCk harf, bir say\u0131 ve bir \u00F6zel karakter i\u00E7ermelidir." }), errorMessage && _jsx("p", __assign({ className: "alert text-center alert-danger" }, { children: errorMessage })), _jsxs("form", __assign({ onSubmit: handleSubmit(onSubmit) }, { children: [_jsxs("div", __assign({ className: "mt-2" }, { children: [_jsx("label", { children: "Parola" }), _jsx("input", __assign({ type: "password", className: "form-control" }, register('password', { required: true }))), errors.password && _jsx("div", __assign({ className: "invalid-feedback d-block" }, { children: "Parola girmeniz gerekli." }))] })), _jsxs("div", __assign({ className: "mt-2" }, { children: [_jsx("label", { children: "Parola (Tekrar)" }), _jsx("input", __assign({ type: "password", className: "form-control" }, register('password2', { required: true }))), errors.password2 && _jsx("div", __assign({ className: "invalid-feedback d-block" }, { children: "Parolan\u0131z\u0131 tekrar girmeniz gerekli." }))] })), _jsxs("div", __assign({ className: "mt-3 text-end" }, { children: [_jsx("button", __assign({ type: "button", onClick: function () {
                                                setShowPasswordResetModal(false);
                                                navigate(paths.home.url);
                                            }, className: "btn btn-secondary" }, { children: "Vazge\u00E7" })), _jsx("button", __assign({ type: "submit", className: "btn ms-2 btn-primary" }, { children: "Parolay\u0131 Kaydet" }))] }))] }))] }))] })) }));
}
