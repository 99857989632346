var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { cartStore, cartToggleSubject } from "../../store/cart.store";
import { CouponCodeWidget } from "./coupon.code";
import { isMobile } from "../../App";
import { modalServiceSubject } from "../../services/modal.service";
import { openRaffleDetail } from "../../services/game.service";
import { updateCart, removeFromCart, orderComplete, clearCart } from "../../services/cart.service";
import { useNavigate } from "react-router";
import { auth, loginModalSubject } from "../../store/auth.store";
import iconCartbasket from "../../assets/icons/feather/shopping-cart.svg";
import iconPercent from "../../assets/icons/feather/percent-circle.svg";
import iconPlusCircle from "../../assets/icons/feather/plus-circle.svg";
import iconTrash from "../../assets/icons/feather/trash-2-red.svg";
import "./cart.mobile.dropdown.css";
import { raffleDraws } from "../../store/games.store";
import Modal from "react-modal";
import { useEffect, useState } from "react";
import { CouponMobileCodeWidget } from "./coupon.mobile.code";
import { updateOrderSubject } from "../../store/misc.store";
import { gtmBeginCheckout, gtmViewCart } from "../../services/misc.functions";
import paths from "../../paths";
import { agreements } from "../../constants/global.constants";
var CartMobileDropdown = function (_a) {
    var cartItemCountDropDown = _a.cartItemCountDropDown, hideCart = _a.hideCart, cartTotalPriceDropDown = _a.cartTotalPriceDropDown, cartTotalPriceWithDiscountDropDown = _a.cartTotalPriceWithDiscountDropDown, setShowCCForm = _a.setShowCCForm;
    var navigate = useNavigate();
    // const forceUpdate = useForceUpdate();
    var _b = useState(false), completePurchaseModal = _b[0], setCompletePurchaseModal = _b[1];
    useEffect(function () {
        var updateOrder_subscriber = updateOrderSubject.subscribe(function (res) {
            setCompletePurchaseModal(false);
        });
        return function () {
            updateOrder_subscriber.unsubscribe();
        };
    }, []);
    var setPointUsage = function () {
        cartStore.pointUsage = !cartStore.pointUsage;
        cartToggleSubject.next({ action: "update" });
        // forceUpdate();
    };
    var setAgreement = function () {
        cartStore.agreement = !cartStore.agreement;
        cartToggleSubject.next({ action: "update" });
        // forceUpdate();
    };
    var completeThePurchase = function () {
        if (!cartStore.pointUsage && cartTotalPriceWithDiscountDropDown > auth.member.balance_debit) {
            if (cartTotalPriceWithDiscountDropDown < Number(auth.member.balance_debit) + Number(auth.member.balance_point)) {
                modalServiceSubject.next({
                    case: "danger",
                    title: "Bakiyeniz Yetersiz",
                    width: "400px",
                    content: "<p class=\"text-center\">\n                    \u015EP Kullanabilir veya Kredi Kart\u0131 ile \u00F6demeye devam edebilirsiniz.\n                   </p>\n                   ",
                    confirm: {
                        sure: {
                            label: "ŞP Kullan",
                            class: "btn btn-success",
                            action: function () {
                                setPointUsage();
                                completeThePurchase();
                            },
                        },
                        cancel: {
                            label: "Kredi Kartı İle Öde",
                            class: "btn-warning w-100",
                            action: function () {
                                setShowCCForm(true);
                            },
                        },
                    },
                });
            }
            else if (cartStore.couponCode) {
                modalServiceSubject.next({
                    case: "danger",
                    title: "Bakiyeniz Yetersiz",
                    width: "400px",
                    content: "<p class=\"text-center\">\n                    Hesab\u0131n\u0131za bakiye y\u00FCkleyebilirsiniz.\n                   </p>\n                   ",
                    confirm: {
                        cancel: {
                            label: "Diğer Ödeme Yöntemleri",
                            class: "btn-info  w-100",
                            action: function () {
                                navigate(paths.deposit.url);
                            },
                        },
                    },
                });
            }
            else {
                modalServiceSubject.next({
                    case: "danger",
                    title: "Bakiyeniz Yetersiz",
                    width: "400px",
                    content: "<p class=\"text-center\">\n                    Kredi Kart\u0131 ile \u00F6demeye devam edebilir veya hesab\u0131n\u0131za bakiye y\u00FCkleyebilirsiniz.\n                   </p>\n                   ",
                    confirm: {
                        sure: {
                            label: "Kredi Kartı İle Öde",
                            class: "btn-warning w-100",
                            action: function () {
                                if (cartStore.pointUsage) {
                                    modalServiceSubject.next({
                                        case: "warning",
                                        title: "",
                                        width: "400px",
                                        content: "<p class=\"text-center\">\n                    Kredi Kart\u0131 \u00F6demelerinde \u015Eans Puan bakiyesi kullan\u0131lamaz.\n                   </p>\n                   ",
                                        confirm: {
                                            sure: {
                                                label: "Kredi Kartı İle Öde",
                                                class: "btn-warning w-100",
                                                action: function () {
                                                    setPointUsage();
                                                    setShowCCForm(true);
                                                },
                                            },
                                            cancel: {
                                                label: "Diğer Ödeme Yöntemleri",
                                                class: "btn-info  w-100",
                                                action: function () {
                                                    navigate(paths.deposit.url);
                                                },
                                            },
                                        },
                                    });
                                }
                                else {
                                    setShowCCForm(true);
                                }
                            },
                        },
                        cancel: {
                            label: "Diğer Ödeme Yöntemleri",
                            class: "btn-info  w-100",
                            action: function () {
                                navigate(paths.deposit.url);
                            },
                        },
                    },
                });
            }
            // modalServiceSubject.next({
            //   case: "danger",
            //   title: "Bakiyeniz Yetersiz",
            //   width: "400px",
            //   content: `<p class="text-center">
            //               Kredi Kartı ile ödemeye devam edebilir veya hesabınıza bakiye yükleyebilirsiniz.
            //              </p>
            //              `,
            //   confirm: {
            //     sure: {
            //       label: "Kredi Kartı İle Öde",
            //       class: "btn-warning w-100",
            //       action: () => {
            //         if (cartStore.pointUsage) {
            //           modalServiceSubject.next({
            //             case: "warning",
            //             title: "",
            //             width: "400px",
            //             content: `<p class="text-center">
            //               Kredi Kartı ödemelerinde Şans Puan bakiyesi kullanılamaz.
            //              </p>
            //              `,
            //             confirm: {
            //               sure: {
            //                 label: "Kredi Kartı İle Öde",
            //                 class: "btn-warning w-100",
            //                 action: () => {
            //                   setPointUsage();
            //                   setShowCCForm(true);
            //                 },
            //               },
            //               cancel: {
            //                 label: "Diğer Ödeme Yöntemleri",
            //                 class: "btn-info  w-100",
            //                 action: () => {
            //                   navigate(paths.deposit.url);
            //                 },
            //               },
            //             },
            //           });
            //         } else {
            //           setShowCCForm(true);
            //         }
            //       },
            //     },
            //     cancel: {
            //       label: "Diğer Ödeme Yöntemleri",
            //       class: "btn-info  w-100",
            //       action: () => {
            //         navigate(paths.deposit.url);
            //       },
            //     },
            //   },
            // });
            // doneButton.disabled = false;
        }
        else if (cartStore.pointUsage &&
            cartTotalPriceWithDiscountDropDown > Number(auth.member.balance_debit) + Number(auth.member.balance_point) &&
            !cartStore.couponCode) {
            modalServiceSubject.next({
                case: "danger",
                title: "Bakiyeniz Yetersiz",
                width: "400px",
                content: "<p class=\"text-center\">\n                    Kredi Kart\u0131 ile \u00F6demeye devam edebilir veya hesab\u0131n\u0131za bakiye y\u00FCkleyebilirsiniz.\n                   </p>\n                   ",
                confirm: {
                    sure: {
                        label: "Kredi Kartı İle Öde",
                        class: "btn-warning w-100",
                        action: function () {
                            if (cartStore.pointUsage) {
                                modalServiceSubject.next({
                                    case: "warning",
                                    title: "",
                                    width: "400px",
                                    content: "<p class=\"text-center\">\n                    Kredi Kart\u0131 \u00F6demelerinde \u015Eans Puan bakiyesi kullan\u0131lamaz.\n                   </p>\n                   ",
                                    confirm: {
                                        sure: {
                                            label: "Kredi Kartı İle Öde",
                                            class: "btn-warning w-100",
                                            action: function () {
                                                setShowCCForm(true);
                                                setPointUsage();
                                            },
                                        },
                                        cancel: {
                                            label: "Diğer Ödeme Yöntemleri",
                                            class: "btn-info  w-100",
                                            action: function () {
                                                navigate(paths.deposit.url);
                                            },
                                        },
                                    },
                                });
                            }
                            else {
                                setShowCCForm(true);
                            }
                        },
                    },
                    cancel: {
                        label: "Diğer Ödeme Yöntemleri",
                        class: "btn-info  w-100",
                        action: function () {
                            navigate(paths.deposit.url);
                        },
                    },
                },
            });
            // doneButton.disabled = false;
        }
        else if (cartStore.pointUsage &&
            cartTotalPriceWithDiscountDropDown > Number(auth.member.balance_debit) + Number(auth.member.balance_point) &&
            cartStore.couponCode) {
            modalServiceSubject.next({
                case: "danger",
                title: "Bakiyeniz Yetersiz",
                width: "400px",
                content: "<p class=\"text-center\">\n                    Hesab\u0131n\u0131za bakiye y\u00FCkleyebilirsiniz.\n                   </p>\n                   ",
                confirm: {
                    cancel: {
                        label: "Diğer Ödeme Yöntemleri",
                        class: "btn-info  w-100",
                        action: function () {
                            navigate(paths.deposit.url);
                        },
                    },
                },
            });
            // doneButton.disabled = false;
        }
        else {
            orderComplete()
                .then(function (res) {
                // setCartStep(0);
                if (res.status === false && res.error === "re-login") {
                    modalServiceSubject.next({
                        case: "danger",
                        width: "400px",
                        title: "Oturumunuz Sonlandı",
                        content: "<p class=\"text-center\">L\u00FCtfen tekrar giri\u015F yap\u0131n\u0131z</p>",
                        confirm: {
                            sure: {
                                label: "Giriş Yap",
                                class: "btn-danger w-100",
                                action: function () {
                                    loginModalSubject.next("show");
                                },
                            },
                            cancel: {
                                label: "İptal",
                                class: "btn-secondary w-100",
                            },
                        },
                    });
                }
                if (res.status === false && res.error === "timeout") {
                    modalServiceSubject.next({
                        case: "danger",
                        width: "400px",
                        title: "İşleminiz Zaman Aşımına Uğradı",
                        content: "<p class=\"text-center\">Bu durum, ba\u015Far\u0131l\u0131 yada ba\u015Far\u0131s\u0131z anlam\u0131na gelmez. L\u00FCtfen bilet sat\u0131n alma durumunuzu biletlerim sayfas\u0131nda tekrar kontrol ediniz.</p>",
                        confirm: {
                            sure: {
                                label: "Biletlerim Sayfasına Git",
                                class: "btn-secondary w-100",
                                action: function () {
                                    navigate(paths.raffleTickets.url + "/biletlerim");
                                },
                            },
                            cancel: {
                                label: "Kapat",
                                class: "btn-secondary w-100",
                            },
                        },
                    });
                }
                if (res.status === false && res.error === "ticket_count_error") {
                    modalServiceSubject.next({
                        case: "danger",
                        width: "400px",
                        title: "Hata!",
                        content: "<p class=\"text-center\">".concat(res.message, "</p>"),
                        confirm: {
                            cancel: {
                                label: "Kapat",
                                class: "btn-secondary w-100",
                            },
                        },
                    });
                }
                if (res.status === true) {
                    clearCart();
                    modalServiceSubject.next({
                        case: "success",
                        title: "Ödemeniz Başarılı",
                        width: "400px",
                        content: "<p class=\"text-center\">\n                    Biletleriniz ba\u015Far\u0131yla sat\u0131n al\u0131nm\u0131\u015Ft\u0131r. Bilet numaralar\u0131n\u0131z\u0131 ve detaylar\u0131n\u0131 g\u00F6rmek i\u00E7in Biletlerim sayfas\u0131na gidebilirsiniz.\n                   </p>",
                        confirm: {
                            sure: {
                                label: "Biletlerimi Gör",
                                class: "btn-success w-100",
                                action: function () {
                                    navigate(paths.raffleTickets.url + "/biletlerim");
                                },
                            },
                            cancel: {
                                label: "Yeni Bilet Al",
                                class: "btn-secondary w-100",
                                action: function () {
                                    // get current path
                                    var currentPath = window.location.pathname;
                                    if (currentPath == paths.raffleTickets.url) {
                                        // if current path is raffle tickets, then we need to reload the page
                                        window.location.reload();
                                    }
                                    else {
                                        // if current path is not raffle tickets, then we need to navigate to raffle tickets
                                        navigate(paths.raffleTickets.url);
                                    }
                                },
                            },
                        },
                    });
                }
                if (res.status === false) {
                    if (res.success_count > 0) {
                        clearCart();
                    }
                    modalServiceSubject.next({
                        case: res.error_count > 0 && res.success_count > 0 ? "warning" : "danger",
                        title: res.success_count === 0 ? "Biletler Alınamadı" : "Bazı Biletler Alınamadı",
                        width: "400px",
                        content: "<p class=\"text-center\">\n                    Bilet al\u0131m i\u015Flemi ba\u015Far\u0131s\u0131z olan bilet tutar\u0131 e-\u015Eans hesab\u0131n\u0131za iade edildi.\n                   </p>\n                   <div class=\"prize-request mx-n10px px-4 rounded-4\" style=\"height: 300px; overflow: hidden; overflow-y: auto\">\n                   ".concat(Object.entries(res.data)
                            .map(function (_a, k) {
                            var d = _a[0], v = _a[1];
                            var dr = raffleDraws.find(function (el) { return el.no === Number(d); });
                            // let stat = v.data.success_count > 0 && v.data.error_count === 0 ? 'success': (v.data.success_count > 0 ? 'warning' : 'danger');
                            return "<div class=\"row item px-1 py-3 pb-0 rounded-2\">\n                        <div class=\"col-auto\">\n                            <img class=\"img-thumbnail\" src=\"".concat(dr.photo, "\" />\n                        </div>\n                        <div class=\"col-8\">\n                            <h3 class=\"nowrap\">").concat(dr.title_abbreviated, "</h3>\n                            ").concat(v.data.success_count > 0
                                ? "<span class=\"d-block text-success fw-bold\">Ba\u015Far\u0131l\u0131 al\u0131nan bilet: ".concat(v.data.success_count, " adet</span>")
                                : "", "\n                            ").concat(v.data.error_count > 0
                                ? "<span class=\"d-block text-danger fw-bold\">Hatal\u0131 bilet: ".concat(v.data.error_count, " adet</span><span class=\"d-block text-danger\">").concat(v.data.message, "</span>")
                                : "", "\n                        </div>\n                      </div>");
                        })
                            .join(""), " </div>"),
                        confirm: {
                            sure: res.success_count > 0
                                ? {
                                    label: "Biletlerimi Gör",
                                    class: "btn-warning w-100",
                                    action: function () {
                                        navigate(paths.raffleTickets.url + "/biletlerim");
                                    },
                                }
                                : null,
                            cancel: res.success_count === 0
                                ? {
                                    label: "Tamam",
                                    class: "btn-danger  w-100",
                                }
                                : {
                                    label: "Yeni Bilet Al",
                                    class: "btn-default  w-100",
                                    action: function () {
                                        // get current path
                                        var currentPath = window.location.pathname;
                                        if (currentPath == paths.raffleTickets.url) {
                                            // if current path is raffle tickets, then we need to reload the page
                                            window.location.reload();
                                        }
                                        else {
                                            // if current path is not raffle tickets, then we need to navigate to raffle tickets
                                            navigate(paths.raffleTickets.url);
                                        }
                                    },
                                },
                        },
                    });
                }
            })
                .finally(function () {
                // doneButton.disabled = false;
            });
        }
    };
    useEffect(function () {
        gtmViewCart(cartStore.items, "Çekiliş");
    }, []);
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "p-2" }, { children: [_jsx("div", __assign({ className: "card-header" }, { children: _jsxs("div", __assign({ className: "cart-item-title" }, { children: [_jsxs("p", { children: [_jsx("span", { children: "Sepetim " }), "( ", cartItemCountDropDown, " Bilet )"] }), _jsx("div", __assign({ className: "basket-info-content" }, { children: _jsx("ul", __assign({ className: "auth-widget float-end basket-info mt-0" }, { children: auth.authentication && auth.member && auth.member.id ? (_jsx(_Fragment, { children: _jsxs("li", __assign({ className: "balance pointer basket-balance-content", onClick: function () {
                                                    var currentPath = window.location.pathname;
                                                    if (currentPath == paths.deposit.url) {
                                                        hideCart();
                                                    }
                                                    else {
                                                        navigate(paths.deposit.url);
                                                    }
                                                } }, { children: [_jsx("img", { className: "icon-plus-circle ms-1", src: iconPlusCircle }), _jsx("div", __assign({ className: "label balance-label" }, { children: "Bakiye:" })), _jsxs("div", __assign({ className: "lines all-balance p-0" }, { children: [_jsxs("span", __assign({ className: "tl-balance" }, { children: [auth.member.balance_debit, "\u20BA"] })), _jsxs("span", __assign({ className: "ep-balance" }, { children: [Number(auth.member.balance_point).toFixed(2), " \u015EP"] }))] }))] })) })) : (_jsx(_Fragment, { children: _jsxs("li", { children: [_jsx("button", __assign({ onClick: function () {
                                                            // showLoginModal(true);
                                                            navigate(paths.login.url);
                                                        }, className: "btn header-auth-btn cart-auth-btn" }, { children: "Giri\u015F Yap" })), _jsx("button", __assign({ onClick: function () {
                                                            navigate(paths.register.url);
                                                            hideCart();
                                                        }, className: "btn header-auth-btn cart-auth-btn" }, { children: "\u00DCye Ol" }))] }) })) })) }))] })) })), _jsxs("div", __assign({ className: "".concat(cartStore.items.length > 0 ? "cart-dropdown-height" : "", " body-mobile") }, { children: [cartStore.items.length === 0 ? (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "no-data-prag" }, { children: [_jsx("img", { src: iconCartbasket }), _jsx("p", { children: " Sepetinizde Bilet Bulunmamaktad\u0131r." }), isMobile && (_jsx("button", __assign({ className: "btn w-80 btn-primary fw-bolder", onClick: function () {
                                                var currentPath = window.location.pathname;
                                                if (currentPath == paths.raffleTickets.url) {
                                                    window.location.reload();
                                                }
                                                else {
                                                    navigate(paths.raffleTickets.url);
                                                }
                                            }, id: "btn_submit" }, { children: "\u00C7ekili\u015Fleri \u0130ncele" })))] })) })) : (_jsx(_Fragment, {})), cartStore.items.map(function (item, key) {
                                return (_jsxs("div", __assign({ className: "cart-item " }, { children: [_jsx("div", __assign({ className: "img-wrapper" }, { children: _jsx("img", { className: " cart-item-img", src: item.item.photo, onClick: function () {
                                                    openRaffleDetail(item.item);
                                                } }) })), _jsxs("div", __assign({ className: "cart-item-detail" }, { children: [_jsx("h3", __assign({ title: item.item.title_abbreviated, onClick: function () {
                                                        openRaffleDetail(item.item);
                                                    } }, { children: item.item.title_abbreviated })), _jsxs("div", __assign({ className: "cart-item-action-wrapper d-flex" }, { children: [_jsx("div", __assign({ className: "cart-item-action" }, { children: _jsxs("div", __assign({ className: "cart-item-action-content" }, { children: [_jsx("i", __assign({ onClick: function () {
                                                                            if (item && (item === null || item === void 0 ? void 0 : item.quantity) > 1) {
                                                                                updateCart(item, key, Number(item === null || item === void 0 ? void 0 : item.quantity) - 1);
                                                                            }
                                                                        } }, { children: "-" })), _jsx("input", { type: "number", className: "no-arrows", min: 1, onChange: function (e) {
                                                                            updateCart(item, key, e.target.value);
                                                                        }, onBlur: function () {
                                                                            if (item.quantity === "" || item.quantity === "0") {
                                                                                updateCart(item, key, 1);
                                                                            }
                                                                        }, value: item.quantity || "" }), _jsx("i", __assign({ onClick: function () {
                                                                            if (item && (item === null || item === void 0 ? void 0 : item.quantity) < 100) {
                                                                                updateCart(item, key, Number(item.quantity) + 1);
                                                                            }
                                                                        } }, { children: "+" }))] })) })), _jsx("div", __assign({ className: "cart-item-price" }, { children: _jsxs("span", { children: [item.item.ticket_price * (item.quantity || 0), "\u20BA"] }) }))] })), _jsxs("div", __assign({ className: "d-flex d-80 cart-item-bottom" }, { children: [_jsxs("p", __assign({ className: "nowrap unit-price m-0", onClick: function () {
                                                                openRaffleDetail(item.item);
                                                            } }, { children: ["Bilet fiyat\u0131: ", item.item.ticket_price, "\u20BA"] })), _jsx("img", { className: "trash", src: iconTrash, onClick: function () {
                                                                removeFromCart(item, key);
                                                            } })] }))] }))] }), "item-my-raffle-order-".concat(key)));
                            })] }))] })), cartStore.items.length > 0 && (_jsx("div", __assign({ className: "basket-summary deposit-page" }, { children: _jsx("div", __assign({ className: "options " }, { children: _jsxs("div", __assign({ className: "" }, { children: [_jsxs("div", __assign({ className: "basket-summary-header container d-flex justify-content-between" }, { children: [_jsxs("h3", { children: [_jsx("img", { className: "icon-percent", src: iconPercent }), " \u0130ndirim Kuponlar\u0131m"] }), _jsxs("h3", { children: ["Kampanyalar\u0131 G\u00F6r", _jsx("button", __assign({ className: "arrow-right-icon", onClick: function () {
                                                    var currentPath = window.location.pathname;
                                                    if (currentPath == paths.campaign.url) {
                                                        hideCart();
                                                    }
                                                    else {
                                                        navigate(paths.campaign.url);
                                                    }
                                                }, id: "btn_submit" }, { children: ">" }))] })] })), _jsxs("div", __assign({ className: "basket-summary-info" }, { children: [_jsx("div", __assign({ className: "basket-summary-info-left" }, { children: cartItemCountDropDown > 0 && (_jsxs("div", __assign({ className: "basket-summary-info-btn" }, { children: [_jsx("label", { children: _jsx(CouponCodeWidget, { buttonText: "\u0130ndirim Kodu Giriniz" }) }), cartStore.couponCode && cartStore.couponCode.minCartCond > cartTotalPriceDropDown ? (_jsxs(_Fragment, { children: [_jsxs("p", __assign({ className: "text-danger text-end", style: { fontSize: 13 } }, { children: ["Bu kupon", Number(cartStore.couponCode.minCartCond).toFixed(2), "\u20BA ve \u00FCzeri sepette ge\u00E7erlidir."] })), _jsx("label", __assign({ className: "col-6" }, { children: "\u0130ndirim Kuponu:" }))] })) : (_jsx(_Fragment, {}))] }))) })), _jsx("div", __assign({ className: cartStore.items.length === 0
                                            ? "basket-summary-info-right"
                                            : "alert-link basket-summary-info-right coupon-code d-inline" }, { children: cartStore.items.length === 0 ? (_jsx(_Fragment, { children: _jsx("p", __assign({ style: { marginLeft: "10px" } }, { children: "Hesab\u0131n\u0131za tan\u0131ml\u0131 kupon bulunmamaktad\u0131r." })) })) : (_jsx(_Fragment, { children: _jsx(CouponMobileCodeWidget, {}) })) }))] })), _jsxs("div", __assign({ className: "mobile-basket-foot" }, { children: [_jsxs("div", __assign({ className: "mobile-basket-price" }, { children: [_jsx("p", { children: "Toplam \u00DCcret" }), _jsx("div", { className: "line" }), _jsxs("span", __assign({ className: "mobile-price" }, { children: [cartTotalPriceWithDiscountDropDown.toFixed(2), "\u20BA"] }))] })), _jsxs("button", __assign({ disabled: cartItemCountDropDown === 0, className: "shopping-cost-button ", onClick: function () {
                                            gtmBeginCheckout();
                                            if (auth.member && auth.member.id) {
                                                setCompletePurchaseModal(true);
                                            }
                                            else {
                                                if (isMobile) {
                                                    navigate(paths.landing.url);
                                                }
                                                else {
                                                    modalServiceSubject.next({
                                                        class: "success",
                                                        title: "Üye Girişi",
                                                        content: "<p>Sat\u0131nalma yapabilmeniz i\u00E7in \u00F6nce oturum a\u00E7man\u0131z gerekli. \u00DCye de\u011Filseniz hemen \u00FCye olabilir yada giri\u015F yaparak al\u0131\u015Fveri\u015Finizi tamamlaya bilirsiniz.</p>",
                                                        confirm: {
                                                            sure: {
                                                                label: "Giriş Yap",
                                                                class: "btn btn-success",
                                                                action: function () {
                                                                    navigate(paths.login.url);
                                                                },
                                                            },
                                                            cancel: {
                                                                label: "Üye Ol",
                                                                class: "btn btn-info",
                                                                action: function () {
                                                                    navigate(paths.register.url);
                                                                },
                                                            },
                                                        },
                                                    });
                                                }
                                            }
                                        } }, { children: ["Al\u0131\u015Fveri\u015Fi Tamamla", _jsx("span", { className: "price" })] }))] }))] })) })) }))), _jsxs(Modal, __assign({ isOpen: completePurchaseModal, className: "default", onRequestClose: function () {
                    setCompletePurchaseModal(false);
                }, contentLabel: "" }, { children: [_jsx("div", __assign({ className: "modal-body pb-5 fs-7 nowrap" }, { children: _jsxs("div", { children: [_jsxs("h2", __assign({ className: "mt-3", style: { color: "var(--bs-blue)" } }, { children: [cartItemCountDropDown, " Bilet tutar\u0131", " ", cartStore.couponCode && (_jsxs("span", __assign({ className: "text-decoration-line-through fs-6" }, { children: [cartTotalPriceDropDown.toFixed(2), "\u20BA"] }))), " ", cartTotalPriceWithDiscountDropDown.toFixed(2), "\u20BA"] })), _jsxs("p", __assign({ style: { letterSpacing: "-0.7px" } }, { children: ["Harcama sonras\u0131nda ", cartTotalPriceWithDiscountDropDown.toFixed(2), " ", cartStore.pointUsage ? "ŞP" : "₺", " ", "bakiyenizden d\u00FC\u015Fecektir."] })), cartStore.couponCode && (_jsxs("p", __assign({ className: "fst-italic fw-bold" }, { children: [cartStore.couponCode.amount.toFixed(2), "\u20BA kupon indirimi uygulanm\u0131\u015Ft\u0131r."] }))), auth.member && (_jsxs("div", __assign({ className: "form-check" }, { children: [_jsx("input", { className: "form-check-input", type: "checkbox", value: cartStore.pointUsage, checked: cartStore.pointUsage, id: "id_use_ps_point", onChange: setPointUsage, disabled: auth.member.balance_point <= 0 }), _jsxs("label", __assign({ className: "form-check-label", htmlFor: "id_use_ps_point" }, { children: ["\u015EP Puan Harca (Kullan\u0131labilir: ", auth.member.balance_point, " \u015EP)"] }))] }))), auth.member && (_jsxs("div", __assign({ className: "form-check" }, { children: [_jsx("input", { className: "form-check-input", type: "checkbox", value: cartStore.agreement, checked: cartStore.agreement, id: "id_check_agreement", onChange: function () {
                                                setAgreement();
                                            } }), _jsxs("label", __assign({ className: "form-check-label", htmlFor: "id_check_agreement", onClick: function () {
                                                // showAgreementModal("on-bilgilendirme-formu");
                                                setCompletePurchaseModal(false);
                                                modalServiceSubject.next({
                                                    title: "",
                                                    width: "400px",
                                                    content: agreements["on-bilgilendirme-formu"].content,
                                                    confirm: {
                                                        sure: {
                                                            label: "Kapat",
                                                            class: "btn-warning w-100",
                                                            action: function () {
                                                                setCompletePurchaseModal(true);
                                                            },
                                                        },
                                                    },
                                                });
                                            } }, { children: [_jsx("a", __assign({ className: "fw-bold pointer" }, { children: "\u00D6n Bilgilendirme Formu" })), "'nu okudum, onayl\u0131yorum."] }))] }))), _jsx("p", __assign({ className: "text-warning my-2", style: { fontSize: "12px", paddingBottom: "30px" } }, { children: "Sat\u0131lan biletler iadesi ger\u00E7ekle\u015Ftirilemez" }))] }) })), _jsxs("div", __assign({ className: "modal-ticket-footer text-end" }, { children: [_jsx("div", { className: "wave" }), _jsxs("div", __assign({ className: "body-row row" }, { children: [_jsx("div", __assign({ className: "col-12" }, { children: _jsx("button", __assign({ onClick: function () {
                                                if (!cartStore.agreement)
                                                    return;
                                                completeThePurchase();
                                            }, className: "btn w-100 btn-success ".concat(!cartStore.agreement && "opacity-50") }, { children: "\u00D6demeyi Tamamla" })) })), !cartStore.couponCode && (_jsx("div", __assign({ className: "col-12" }, { children: _jsx("button", __assign({ onClick: function () {
                                                if (!cartStore.agreement)
                                                    return;
                                                if (cartStore.pointUsage) {
                                                    modalServiceSubject.next({
                                                        case: "warning",
                                                        title: "",
                                                        width: "400px",
                                                        content: "<p class=\"text-center\">\n                    Kredi Kart\u0131 \u00F6demelerinde \u015Eans Puan bakiyesi kullan\u0131lamaz.\n                   </p>\n                   ",
                                                        confirm: {
                                                            sure: {
                                                                label: "Kredi Kartı İle Öde",
                                                                class: "btn-warning w-100",
                                                                action: function () {
                                                                    setPointUsage();
                                                                    setShowCCForm(true);
                                                                },
                                                            },
                                                            cancel: {
                                                                label: "Diğer Ödeme Yöntemleri",
                                                                class: "btn-info  w-100",
                                                                action: function () {
                                                                    navigate(paths.deposit.url);
                                                                },
                                                            },
                                                        },
                                                    });
                                                }
                                                else {
                                                    setShowCCForm(true);
                                                }
                                            }, className: "btn w-100 btn-warning me-2 ".concat(!cartStore.agreement && "opacity-50") }, { children: "Kredi Kart\u0131 ile \u00D6deme Yap" })) })))] }))] }))] }))] }));
};
export default CartMobileDropdown;
