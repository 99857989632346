var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { SupportMenu } from "./support";
import React, { useEffect } from "react";
import { api } from "../../services/http.service";
import { env } from "../../constants/global.constants";
import { useNavigate, useParams } from "react-router";
import iconBackArrow from "../../assets/icons/feather/arrow-left.svg";
import paths from "../../paths";
export function SupportFAQ() {
    var _this = this;
    var _a = React.useState([]), subjects = _a[0], setSubjects = _a[1];
    var _b = React.useState([]), issues = _b[0], setIssues = _b[1];
    var _c = React.useState(false), ticketForm = _c[0], showTicketForm = _c[1];
    var _d = React.useState(null), hint = _d[0], setHint = _d[1];
    var navigate = useNavigate();
    var params = useParams();
    var pathname = useNavigate();
    useEffect(function () {
        api
            .start("get", env.accounting_api + "/misc/api/list-questions/", null, false)
            .then(function (res) {
            if (params.id) {
                getIssues(Number(params.id)).finally(function () {
                    setSubjects(res.data);
                });
            }
            else {
                setSubjects(res.data);
            }
        });
    }, []);
    useEffect(function () {
        if (!params.id) {
            setIssues([]);
        }
    }, [pathname]);
    var getIssues = function (e) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (Number(e) === -1) {
                        setIssues([]);
                        setHint(null);
                        return [2 /*return*/, null];
                    }
                    return [4 /*yield*/, api
                            .start("post", env.accounting_api + "/misc/api/list-questions/", { question_type: e }, false)
                            .then(function (res) {
                            setIssues(res.data);
                            navigate("".concat(paths.support.url, "/sikca-sorulan-sorular/").concat(e, "/"));
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "row" }, { children: [_jsx("div", __assign({ className: "col-3 col-mobile-12" }, { children: _jsx(SupportMenu, {}) })), _jsx("div", __assign({ className: "col-9 col-mobile-12" }, { children: _jsxs("div", __assign({ className: "card support-faq" }, { children: [_jsxs("div", __assign({ className: "card-header d-flex align-items-center" }, { children: [issues.length !== 0 && (_jsx("img", { className: "btnBack", src: iconBackArrow, onClick: function () {
                                            getIssues(-1);
                                        } })), _jsx("h2", __assign({ className: "mb-0 mx-2" }, { children: "S\u0131k\u00E7a Sorulan Sorular" }))] })), _jsxs("div", __assign({ className: "card-body" }, { children: [issues.length === 0 && subjects && (_jsx("ul", __assign({ className: "faq-list white-space-normal " }, { children: subjects.map(function (item, key) {
                                            return (_jsx("li", __assign({ id: "subjects-faq-".concat(item.question_type) }, { children: _jsx("a", __assign({ onClick: function () {
                                                        getIssues(item.question_type);
                                                    } }, { children: _jsxs("div", __assign({ className: "d-flex justify-content-between" }, { children: [_jsxs("span", { children: [" ", item.question_type_display] }), _jsx("span", __assign({ className: "ms-3" }, { children: _jsx("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", width: "20", height: "20", fill: "currentColor", className: "bi bi-arrow-right-circle", viewBox: "0 0 16 16" }, { children: _jsx("path", { "fill-rule": "evenodd", d: "M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" }) })) }))] })) })) }), "subjects-faq-".concat(key)));
                                        }) }))), _jsx("ul", __assign({ className: "faq-list white-space-normal" }, { children: issues &&
                                            issues.map(function (item, key) {
                                                return (_jsxs("li", __assign({ id: "subjects-faq-".concat(item.question_type) }, { children: [_jsxs("a", __assign({ className: hint === item.id
                                                                ? "active d-flex justify-content-between"
                                                                : "d-flex justify-content-between", onClick: function () {
                                                                setHint(item.id);
                                                            } }, { children: [item.title, _jsx("span", __assign({ className: "ms-3" }, { children: _jsxs("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", width: "20", height: "20", fill: "currentColor", className: "bi bi-plus-circle", viewBox: "0 0 16 16" }, { children: [_jsx("path", { d: "M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" }), _jsx("path", { d: "M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" })] })) }))] })), hint === item.id && (_jsx("p", __assign({ className: "p-3 white-space-normal", style: { background: "rgba(13, 110, 253, 0.2)" } }, { children: item.content })))] }), "subjects-faq-".concat(key)));
                                            }) }))] }))] })) }))] })) }));
}
