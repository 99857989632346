var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./qr-card.css";
import iconIOS from "../../assets/images/app-store-badge.svg";
import iconAndroid from "../../assets/images/google-play-badge.png";
import androidQr from "../../assets/images/android-qr.png";
import iosQr from "../../assets/images/apple-qr.png";
import eSansLogo from "../../assets/images/esans.png";
var QrCard = function () {
    return (_jsxs("div", __assign({ className: 'qr-code-card' }, { children: [_jsxs("div", __assign({ className: "text-center" }, { children: [_jsx("img", { src: eSansLogo, alt: "", height: 142 }), _jsx("h1", __assign({ className: 'qr-code-msg ' }, { children: "e-\u015Eans ile Art\u0131k \u015Eans Cebinizde!" })), _jsx("h3", __assign({ className: 'qr-code-msg-dark' }, { children: "Qr kodu okutarak hemen indirin." })), _jsx("h3", __assign({ className: 'qr-code-msg-dark' }, { children: "Uygulamaya \u00F6zel f\u0131rsatlardan yararlanmaya ba\u015Flay\u0131n." }))] })), _jsxs("div", __assign({ className: 'qr-icon' }, { children: [_jsx("a", __assign({ href: "https://play.google.com/store/apps/details?id=com.dijital.esans", target: '_blank' }, { children: _jsxs("div", __assign({ className: "icon-android" }, { children: [_jsx("img", { src: iconAndroid, className: "me-1", width: 120, height: 42 }), _jsx("div", __assign({ className: "qr-box" }, { children: _jsx("img", { src: androidQr }) }))] })) })), _jsx("a", __assign({ href: "https://apps.apple.com/tr/app/e-%C5%9Fans/id6470998189?l=tr", target: '_blank' }, { children: _jsxs("div", __assign({ className: "icon-ios" }, { children: [_jsx("img", { src: iconIOS, className: "me-1", width: 120, height: 42 }), _jsx("div", __assign({ className: "qr-box" }, { children: _jsx("img", { src: iosQr }) }))] })) }))] }))] })));
};
export default QrCard;
