var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./slide.css";
import { useEffect } from "react";
import { openRaffleDetail } from "../../services/game.service";
import { useForceUpdate } from "../../services/misc.functions";
import { cartToggleSubject } from "../../store/cart.store";
export function TicketSlide(props) {
    var forceUpdate = useForceUpdate();
    useEffect(function () {
        var cartToggleSubject_subscriber = cartToggleSubject.subscribe(function (res) {
            if (res && (res.item && res.item.item && res.item.item.id === props.item.id) || (res.list && res.list.includes(props.item.id))) {
                forceUpdate();
            }
        });
        return function () {
            cartToggleSubject_subscriber.unsubscribe();
        };
    }, []);
    var ticket = props.item;
    return (_jsxs("div", __assign({ className: "ticket-slide", onClick: function () {
            openRaffleDetail(ticket);
        } }, { children: [_jsx("div", __assign({ className: " pe-0 justify-content-center align-middle d-flex " }, { children: _jsx("img", { className: "photo", src: ticket.photo }) })), _jsxs("div", __assign({ className: "ps-0" }, { children: [_jsxs("div", __assign({ className: "flags py-1" }, { children: [ticket.is_new && _jsx("span", __assign({ className: "mx-1 px-2 translate-middle text-white rounded bg-primary" }, { children: "\u2B50 Yeni" })), ticket.is_popular &&
                                _jsx("span", __assign({ className: "mx-1 px-2 translate-middle text-white rounded bg-warning" }, { children: "\uD83D\uDD25 Pop\u00FCler" })), ticket.best_selling &&
                                _jsx("span", __assign({ className: "mx-1 px-2 translate-middle text-white rounded bg-warning" }, { children: "\u00C7ok Satan" })), ticket.second_hand &&
                                _jsx("span", __assign({ className: "mx-1 px-2 translate-middle text-white rounded bg-secondary" }, { children: "2.El" })), ticket.is_running_out &&
                                _jsx("span", __assign({ className: "mx-1 px-2 translate-middle text-white rounded bg-success" }, { children: "\uD83D\uDCA5 T\u00FCkeniyor" }))] })), _jsx("div", { className: "clearfix" }), _jsxs("div", __assign({ className: "row justify-content-center align-items-center d-flex  " }, { children: [_jsxs("div", __assign({ className: "col-8" }, { children: [_jsx("h2", __assign({ className: "nowrap" }, { children: ticket.title_abbreviated })), _jsx("p", { children: ticket.name })] })), _jsx("div", __assign({ className: "col-3" }, { children: _jsx("div", __assign({ className: "foot" }, { children: _jsx("button", __assign({ className: "btn btn-primary fw-bolder rounded-3" }, { children: "\u0130ncele" })) })) }))] }))] }))] })));
    // return (<div className="ticket-slide">
    //   <div className="row" onClick={() => {
    //     openRaffleDetail(ticket)
    //   }}>
    //     <div className="col-5 pe-0 justify-content-center align-middle d-flex ">
    //       <img className="photo" src={ticket.photo} />
    //     </div>
    //     <div className="col-7 ps-0">
    //       <div className="flags py-1">
    //         {ticket.is_new && <span className="px-2 translate-middle text-white rounded bg-primary">Yeni</span>}
    //         {ticket.is_popular && <span className="px-2 translate-middle text-white rounded bg-warning">Popüler</span>}
    //         {ticket.best_selling && <span className="px-2 translate-middle text-white rounded bg-warning">Çok Satan</span>}
    //         {ticket.second_hand && <span className="px-2 translate-middle text-white rounded bg-secondary">2.El</span>}
    //         {ticket.is_running_out &&
    //         <span className="px-2 translate-middle text-white rounded bg-success">Tükeniyor</span>}
    //       </div>
    //       <div className="clearfix"></div>
    //       <h2 className="nowrap">{ticket.title_abbreviated}</h2>
    //       <p>
    //         {ticket.name} {ticket.draw_price && ticket.draw_price.length > 0 ? <>, Yaklaşık değeri: <span
    //         className="fw-bold">{CurrencyFormat(ticket.draw_price[0].approximate_price, 2)}₺</span></> : <></>}
    //       </p>
    //       <div className="foot">
    //
    //         <button className="btn btn-primary fw-bolder rounded-3">Çekilişi İncele</button>
    //
    //       </div>
    //     </div>
    //   </div>
    // </div>)
}
