var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { NotificationMenu } from "./account";
import { useEffect } from "react";
import { auth, authSubject } from "../../store/auth.store";
import { api } from "../../services/http.service";
import { env } from "../../constants/global.constants";
import { useForceUpdate } from "../../services/misc.functions";
import { wssProvider } from "../../services/websocket.service";
export function NotificationSettings() {
    var forceUpdate = useForceUpdate();
    useEffect(function () {
        var auth_subscriber = authSubject.subscribe(function () {
            // console.log("refresh")
            forceUpdate();
        });
        return function () {
            auth_subscriber.unsubscribe();
        };
    }, []);
    var updateNotificationSettings = function (payload, obj) {
        // for (const [k, v] of Object.entries(payload)) {
        //   auth.member[k] = v;
        // }
        // forceUpdate();
        obj.target.disabled = true;
        api
            .start("post", env.accounting_api + "/member/api/update-account-info/", payload, true)
            .then(function (res) {
            wssProvider.checkauth();
            forceUpdate();
        })
            .finally(function () {
            obj.target.disabled = false;
        });
    };
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "row" }, { children: [_jsx("div", __assign({ className: "col-3 col-mobile-12" }, { children: _jsx(NotificationMenu, {}) })), _jsxs("div", __assign({ className: "col-9 col-mobile-12" }, { children: [_jsxs("div", __assign({ className: "card  mb-4 rounded-4" }, { children: [_jsx("div", __assign({ className: "card-header text-start text-primary" }, { children: _jsx("h2", { children: "Bildirim Tercihlerim" }) })), _jsx("div", __assign({ className: "card-body fs-7", style: { whiteSpace: "normal" } }, { children: "R\u0131za Metni kapsam\u0131nda, mobil bildirimler arac\u0131l\u0131\u011F\u0131 ile sizi kampanya ve f\u0131rsatlar\u0131m\u0131zdan haberdar etmek istiyoruz." }))] })), _jsx("div", __assign({ className: "card mb-4 rounded-4 notification-settings" }, { children: _jsxs("div", __assign({ className: "card-body pt-3" }, { children: [_jsxs("div", __assign({ className: "form-check form-switch" }, { children: [_jsxs("label", __assign({ className: "form-check-label", htmlFor: "id_switch_campaign_agreement" }, { children: [_jsx("h3", { children: "Kampanya" }), _jsx("span", __assign({ style: { whiteSpace: "normal" } }, { children: "\u0130lgimi \u00E7ekebilecek kampanyalarla ilgili bildirim almak istiyorum." }))] })), _jsx("input", { checked: auth.member.campaign_agreement, className: "form-check-input float-end mt-3", type: "checkbox", onChange: function (e) {
                                                    updateNotificationSettings({ campaign_agreement: !auth.member.campaign_agreement }, e);
                                                }, role: "switch", id: "id_switch_campaign_agreement" })] })), _jsxs("div", __assign({ className: "form-check form-switch" }, { children: [_jsxs("label", __assign({ className: "form-check-label", htmlFor: "id_switch_live_draw_notification" }, { children: [_jsx("h3", { children: "Canl\u0131 \u00C7ekili\u015F" }), _jsx("span", __assign({ style: { whiteSpace: "normal" } }, { children: "Zaman\u0131 yakla\u015Fan ve ba\u015Flam\u0131\u015F olan Canl\u0131 \u00C7ekili\u015Flerle ilgili bildirim almak istiyorum." }))] })), _jsx("input", { checked: auth.member.live_draw_notification, className: "form-check-input float-end mt-3", type: "checkbox", onChange: function (e) {
                                                    updateNotificationSettings({
                                                        live_draw_notification: !auth.member.live_draw_notification,
                                                    }, e);
                                                }, role: "switch", id: "id_switch_live_draw_notification" })] })), _jsxs("div", __assign({ className: "form-check form-switch" }, { children: [_jsxs("label", __assign({ className: "form-check-label", htmlFor: "is_switch_game_start_notification" }, { children: [_jsx("h3", { children: "Oyun Ba\u015Flang\u0131\u00E7" }), _jsx("span", __assign({ style: { whiteSpace: "normal" } }, { children: "Ba\u015Flam\u0131\u015F olan oyunlarla ilgili bildirim almak istiyorum." }))] })), _jsx("input", { checked: auth.member.game_start_notification, className: "form-check-input float-end mt-3", type: "checkbox", onChange: function (e) {
                                                    updateNotificationSettings({
                                                        game_start_notification: !auth.member.game_start_notification,
                                                    }, e);
                                                }, role: "switch", id: "is_switch_game_start_notification" })] })), _jsxs("div", __assign({ className: "form-check form-switch" }, { children: [_jsxs("label", __assign({ className: "form-check-label", htmlFor: "id_switch_new_draw_notification" }, { children: [_jsx("h3", { children: "Yeni E\u015Fya \u00C7ekili\u015Fleri" }), _jsx("span", __assign({ style: { whiteSpace: "normal" } }, { children: "Yeni eklenmi\u015F olan e\u015Fya \u00E7ekili\u015Fleriyle ilgili bildirim almak istiyorum." }))] })), _jsx("input", { checked: auth.member.new_draw_notification, className: "form-check-input float-end mt-3", type: "checkbox", onChange: function (e) {
                                                    updateNotificationSettings({
                                                        new_draw_notification: !auth.member.new_draw_notification,
                                                    }, e);
                                                }, role: "switch", id: "id_switch_new_draw_notification" })] })), _jsxs("div", __assign({ className: "form-check form-switch" }, { children: [_jsxs("label", __assign({ className: "form-check-label", htmlFor: "is_switch_new_game_notification" }, { children: [_jsx("h3", { children: "Yeni Oyunlar" }), _jsx("span", __assign({ style: { whiteSpace: "normal" } }, { children: "Yeni eklenmi\u015F olan oyunlarla ilgili bildirim almak istiyorum." }))] })), _jsx("input", { checked: auth.member.new_game_notification, className: "form-check-input float-end mt-3", type: "checkbox", onChange: function (e) {
                                                    updateNotificationSettings({
                                                        new_game_notification: !auth.member.new_game_notification,
                                                    }, e);
                                                }, role: "switch", id: "is_switch_new_game_notification" })] }))] })) }))] }))] })) }));
}
