var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect } from "react";
import { SupportMenu } from "./support";
import { api, ApiService } from "../../services/http.service";
import { env } from "../../constants/global.constants";
import { modalServiceSubject } from "../../services/modal.service";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { exceptionModal } from "../../services/misc.functions";
import { useNavigate } from "react-router";
import { auth } from "../../store/auth.store";
import paths from "../../paths";
export function SupportTicket() {
    var _a = React.useState([]), subjects = _a[0], setSubjects = _a[1];
    var _b = React.useState([]), issues = _b[0], setIssues = _b[1];
    var _c = React.useState(false), ticketForm = _c[0], showTicketForm = _c[1];
    var _d = React.useState(null), hint = _d[0], setHint = _d[1];
    useEffect(function () {
        api
            .start("get", env.accounting_api + "/misc/api/list-questions/", null, false)
            .then(function (res) {
            setSubjects(res.data);
        });
    }, []);
    useEffect(function () {
        if (ticketForm) {
            var textarea = document.getElementById("id_support_textArea");
            if (textarea) {
                textarea.focus();
            }
        }
    }, [ticketForm]);
    var getIssues = function (e) {
        if (Number(e.target.value) === -1) {
            setIssues([]);
            setHint(null);
            return null;
        }
        api
            .start("post", env.accounting_api + "/misc/api/list-questions/", { question_type: e.target.value }, false)
            .then(function (res) {
            setIssues(res.data);
        });
    };
    var showHint = function (e) {
        if (Number(e.target.value) === -1) {
            setIssues([]);
            setHint(null);
            return null;
        }
        showTicketForm(false);
        var hint = issues.find(function (el) { return Number(el.id) === Number(e.target.value); });
        setHint(hint);
    };
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "row" }, { children: [_jsx("div", __assign({ className: "col-3 col-mobile-12" }, { children: _jsx(SupportMenu, {}) })), _jsxs("div", __assign({ className: "col-9 col-mobile-12" }, { children: [_jsxs("div", __assign({ className: "card  mb-4 rounded-4" }, { children: [_jsx("div", __assign({ className: "card-header text-start fw-bold text-primary" }, { children: _jsx("h2", { children: "Size hangi konuda yard\u0131mc\u0131 olabiliriz?" }) })), _jsx("div", __assign({ className: "card-body" }, { children: _jsxs("form", { children: [_jsxs("div", __assign({ className: "mb-3" }, { children: [_jsxs("select", __assign({ id: "id_support_ticket_subject", className: "form-select", onChange: getIssues }, { children: [_jsx("option", __assign({ value: -1 }, { children: "Konu Se\u00E7iniz" })), subjects &&
                                                                subjects.length > 0 &&
                                                                subjects.map(function (el, key) {
                                                                    return (_jsx("option", __assign({ value: el.question_type }, { children: el.question_type_display }), "subject-option-".concat(key)));
                                                                })] })), _jsx("div", __assign({ id: "emailHelp", className: "form-text" }, { children: "Yard\u0131m ihtiyac\u0131n\u0131z olan konuyu se\u00E7iniz." }))] })), _jsx("div", __assign({ className: "mb-3" }, { children: _jsxs("select", __assign({ id: "id_support_ticket_subject", className: "form-select", onChange: showHint }, { children: [_jsx("option", __assign({ value: -1 }, { children: "Sorunu Se\u00E7iniz" })), issues &&
                                                            issues.length > 0 &&
                                                            issues.map(function (issue, ikey) {
                                                                return (_jsx("option", __assign({ value: issue.id }, { children: issue.title }), "issues-option-".concat(ikey)));
                                                            })] })) }))] }) }))] })), hint && (_jsxs("div", __assign({ className: "card mb-4 rounded-4 support-hints" }, { children: [_jsx("div", __assign({ className: "card-header" }, { children: _jsx("h2", __assign({ className: "text-wrap" }, { children: hint.title })) })), _jsxs("div", __assign({ className: "card-body" }, { children: [hint.image && (_jsx("img", { src: hint.image, alt: hint.title, className: "img-fluid mb-3" })), _jsx("p", __assign({ className: "content text-wrap text-center" }, { children: hint.content })), !ticketForm && auth.member && auth.member.id && (_jsxs("div", __assign({ className: "text-center" }, { children: [_jsx("p", __assign({ className: "my-4" }, { children: "Bu i\u00E7erik size yard\u0131mc\u0131 oldu mu?" })), _jsx("button", __assign({ className: "btn rounded-pill btn-success me-1", onClick: function () {
                                                        modalServiceSubject.next({
                                                            case: "success",
                                                            title: "Teşekkürler",
                                                            content: "<p>Geri bildirim için teşekkür ederiz. Sizlere daha iyi hizmet verebilmek için biz buradayız.</p>",
                                                            confirm: {
                                                                cancel: {
                                                                    class: "btn btn-success",
                                                                    label: "Kapat",
                                                                    action: function () {
                                                                        setHint(null);
                                                                    },
                                                                },
                                                            },
                                                        });
                                                    } }, { children: "Evet" })), _jsx("button", __assign({ className: "btn rounded-pill btn-secondary ms-1", onClick: function () {
                                                        showTicketForm(true);
                                                    } }, { children: "Hay\u0131r" }))] })))] }))] }))), ticketForm && (_jsx(SupportTicketForm, { hintId: hint === null || hint === void 0 ? void 0 : hint.id }))] }))] })) }));
}
export function SupportTicketForm(props) {
    var navigate = useNavigate();
    var hintId = props.hintId;
    var _a = useForm(), register = _a.register, handleSubmit = _a.handleSubmit, reset = _a.reset, errors = _a.formState.errors;
    var onSubmit = function (payload) {
        var acceptedFiles = [];
        for (var _i = 0, _a = payload.file; _i < _a.length; _i++) {
            var f = _a[_i];
            if ((f.type.includes("image") || f.type.includes("pdf")) &&
                f.size < 1024 * 1024 * 5) {
                acceptedFiles.push(f);
            }
            else {
                toast.error("Lütfen 5MB dan küçük ve resim/pdf formatında dosya yükleyiniz.");
                return null;
            }
        }
        var formData = new FormData();
        Object.entries(payload).forEach(function (_a) {
            var key = _a[0], value = _a[1];
            if (key !== "file") {
                formData.append(key, value);
            }
        });
        formData.append("file", payload.file[0]);
        formData.append("subject", hintId);
        var post = new ApiService();
        post.setHeaders({ "Content-Type": "multipart/form-data" });
        post
            .start("post", env.accounting_api + "/support/api/create-ticket/", formData, true)
            .then(function (res) {
            reset({ message: "", file: null });
            modalServiceSubject.next({
                case: "success",
                title: "Talebiniz Alındı",
                content: "<p>Destek talebiniz başarıyla oluşturuldu. En kısa sürede size dönüş yapılacaktır.</p>",
                confirm: {
                    cancel: {
                        class: "btn btn-success",
                        label: "Tamam",
                        action: function () {
                            navigate(paths.support.url + "/destek-taleplerim/");
                        },
                    },
                },
            });
        })
            .catch(function (err) {
            exceptionModal(err);
        });
    };
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "card mb-4 rounded-4 support-ticket-form" }, { children: [_jsx("div", __assign({ className: "card-header" }, { children: _jsx("h2", { children: "Sorununuzu bize bildirin" }) })), _jsx("div", __assign({ className: "card-body" }, { children: _jsxs("form", __assign({ onSubmit: handleSubmit(onSubmit) }, { children: [_jsx("textarea", __assign({ id: "id_support_textArea" }, register("message", {
                                required: true,
                                minLength: 10,
                                maxLength: 600,
                            }), { placeholder: "Mesajınızı giriniz" })), _jsx("br", {}), errors.message && (_jsx("span", __assign({ className: "text-danger" }, { children: "Bu alan zorunludur. En az 40, en \u00E7ok 600 karakter girmelisiniz." }))), _jsxs("div", __assign({ className: "my-3" }, { children: [_jsx("label", __assign({ htmlFor: "id_supportTicketFile", className: "form-label" }, { children: "Dosya veya Foto\u011Fraf (max:5mb)" })), _jsx("input", __assign({ type: "file" }, register("file", { required: false }), { className: "form-control", id: "id_supportTicketFile" }))] })), _jsx("button", __assign({ className: "btn btn-success" }, { children: "G\u00F6nder" }))] })) }))] })) }));
}
