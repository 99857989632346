var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { cartStore, cartToggleSubject } from "../store/cart.store";
import { Cart } from "../models/cart.model";
import { Storage } from "./localstorege.service";
import { raffleDraws, raffleDrawSubject } from "../store/games.store";
import { api } from "./http.service";
import { env } from "../constants/global.constants";
import { gtmAddToCart, gtmRemoveFromCart, slugify } from "./misc.functions";
import { toast } from "react-toastify";
import { auth } from "../store/auth.store";
export function addToCart(item, quantity, itemType, showToast) {
    if (itemType === void 0) { itemType = "raffle"; }
    if (showToast === void 0) { showToast = true; }
    if (![true, 1].includes(item.status)) {
        toast.warning("Çekiliş Satışa kapalı");
        return null;
    }
    if (quantity > 100) {
        quantity = 100;
        toast.warning("Tek seferde en fazla 100 bilet alabilirsiniz");
    }
    if (cartStore.items.length >= 10) {
        toast.warning("Tek seferde en fazla 10 çekiliş ekliyebilirsiniz.");
        return false;
    }
    var obj;
    obj = cartStore.items.find(function (el) { return el.itemType === itemType && el.item.id === item.id; });
    if (obj) {
        obj.quantity = Number(obj.quantity) + Number(quantity);
        if (obj.quantity > 100) {
            obj.quantity = 100;
            toast.warning("Tek seferde en fazla 100 bilet alabilirsiniz");
        }
    }
    else {
        obj = new Cart(item, quantity, itemType);
        cartStore.items.push(obj);
    }
    item.is_added = true;
    item.cartIndex = cartStore.items.findIndex(function (el) { return el.item.id === obj.item.id; });
    Storage.set("cartStore", cartStore);
    cartToggleSubject.next({ action: "add", item: obj });
    if (showToast) {
        toast.success("Çekiliş Sepetinize Eklendi.");
        gtmAddToCart([obj], "Çekiliş");
        try {
            console.log({
                content_name: item.name,
                content_category: item.draw_categories_id.toString(),
                content_ids: item.id,
                content_type: "product",
                value: Number(item.ticket_price).toFixed(2),
                currency: "TRY",
                event_source_url: env.web_link + "/esya-piyangosu/" + item.id + "/" + slugify(item.name),
            });
            // @ts-ignore
            fbq("track", "AddToCart", {
                content_name: item.name,
                content_ids: item.id,
                content_type: "product",
                value: Number(item.ticket_price).toFixed(2),
                currency: "TRY",
                event_source_url: env.web_link + "/esya-piyangosu/" + item.id + "/" + slugify(item.name),
            });
        }
        catch (e) {
            console.log(e);
        }
    }
}
export function removeFromCart(item, index) {
    cartStore.items.splice(index, 1);
    Storage.set("cartStore", cartStore);
    raffleDraws.filter(function (el) {
        if (el.id === item.item.id) {
            el.is_added = false;
            el.cartIndex = null;
        }
        return item;
    });
    updateIndexes();
    cartToggleSubject.next({ action: "remove", list: [item.item.id] });
    gtmRemoveFromCart([item], "Çekiliş");
}
export function updateIndexes() {
    var _loop_1 = function (cartItem) {
        cartItem.item.cartIndex = cartStore.items.findIndex(function (m) { return m.item.id === cartItem.item.id; });
    };
    for (var _i = 0, _a = cartStore.items; _i < _a.length; _i++) {
        var cartItem = _a[_i];
        _loop_1(cartItem);
    }
    var _loop_2 = function (item) {
        _index = cartStore.items.findIndex(function (m) { return m.item.id === item.id; });
        if (_index == -1) {
            _index = null;
        }
        if (item.cartIndex != _index) {
            item.cartIndex = _index;
            raffleDrawSubject.next({ action: "update", id: item.id });
        }
    };
    var _index;
    for (var _b = 0, raffleDraws_1 = raffleDraws; _b < raffleDraws_1.length; _b++) {
        var item = raffleDraws_1[_b];
        _loop_2(item);
    }
}
export function updateCart(item, index, quantity) {
    if (quantity) {
        quantity = parseInt(quantity.toString());
    }
    index = cartStore.items.findIndex(function (el) { return el.item.id === item.item.id; });
    if (quantity <= 0 && quantity.toString() != "") {
        removeFromCart(item, index);
        gtmRemoveFromCart([item], "Çekiliş");
        return null;
    }
    if (quantity > 100) {
        quantity = 100;
        toast.warning("Tek seferde en fazla 100 bilet alabilirsiniz");
    }
    cartStore.items[index].quantity = quantity;
    cartStore.items[index].item.cartIndex = index;
    Storage.set("cartStore", cartStore);
    gtmAddToCart(cartStore.items, "Çekiliş");
    cartToggleSubject.next({ action: "update", item: item });
}
export function clearCart() {
    var listTrigger = __spreadArray([], cartStore.items.map(function (el) { return el.item.id; }), true);
    gtmRemoveFromCart(cartStore.items, "Çekiliş");
    cartStore.items = [];
    cartStore.couponCode = null;
    // cartStore.pointUsage = false;
    Storage.set("cartStore", cartStore);
    raffleDraws.filter(function (item) {
        item.is_added = false;
        item.cartIndex = null;
        return item;
    });
    setTimeout(function () {
        cartToggleSubject.next({ action: "clear", list: listTrigger });
    }, 500);
}
export function recoverCart() {
    var cart = Storage.get("cartStore");
    if (cart) {
        var raffle_ids_1 = cart.items.filter(function (el) { return el.itemType === "raffle"; }).map(function (el) { return el.item.id; });
        var availableDraws = raffleDraws.filter(function (el) { return raffle_ids_1.includes(el.id) && el.status == 1 && el.remaining_tickets > 0; });
        var _loop_3 = function (item) {
            var quantity = cart.items.find(function (el) { return el.itemType === "raffle" && el.item.id === item.id; }).quantity;
            addToCart(item, quantity, "raffle", false);
        };
        for (var _i = 0, availableDraws_1 = availableDraws; _i < availableDraws_1.length; _i++) {
            var item = availableDraws_1[_i];
            _loop_3(item);
        }
    }
    setTimeout(function () {
        cartToggleSubject.next({ action: "recover", list: cartStore.items.map(function (el) { return el.item.id; }) });
    }, 100);
}
export function orderComplete() {
    return __awaiter(this, void 0, void 0, function () {
        var payload;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    payload = {
                        unit: !cartStore.pointUsage ? "0" : "2",
                        discount_coupon_code: cartStore.couponCode ? cartStore.couponCode.code : null,
                        agreement: cartStore.agreement === true ? 'true' : 'false',
                        ticket: cartStore.items.map(function (el) {
                            return { draw_no: el.item.no.toString(), multiply: el.quantity.toString() };
                        }),
                    };
                    return [4 /*yield*/, api
                            .start("post", env.accounting_api + "/somos/api/esya-piyangosu/kupon-olustur/", payload, true)
                            .then(function (res) {
                            // console.log(res);
                            if (res.status === true) {
                                try {
                                    var items = [];
                                    // var totalPrice = 0;
                                    for (var _i = 0, _a = cartStore.items; _i < _a.length; _i++) {
                                        var item = _a[_i];
                                        items.push({
                                            item_id: item.item.draw_id,
                                            item_name: item.item.title_abbreviated,
                                            item_category: "{{Çekiliş}}",
                                            price: item.item.draw_price,
                                            quantity: item.quantity,
                                        });
                                        // totalPrice += item.item.draw_price * item.quantity;
                                    }
                                    // @ts-ignore
                                    fbq("track", "Purchase", { value: res.total_coupon_price, currency: "TRY" });
                                    // @ts-ignore
                                    dataLayer.push({ ecommerce: null });
                                    // @ts-ignore
                                    // dataLayer.push({
                                    //   event: "purchase",
                                    //   ecommerce: {
                                    //     value: res.total_coupon_price, //kullanıcının son ödediği ücret
                                    //     transaction_id: Object.keys(res.data)[0], //sipariş idsi
                                    //     currency: "TRY",
                                    //     coupon: cartStore.couponCode, //Eğer kullanıcı kupon kullandı ise kuponun ismi basılmalıdır.
                                    //     payment_type: !cartStore.pointUsage ? "Balance" : "ŞP", //Ödeme yöntemi. Kredi Kartı, ŞP veya Balance
                                    //     purchase_type: "{{Çekiliş}}",
                                    //     items: items,
                                    //   },
                                    // });
                                    // @ts-ignore
                                    dataLayer.push({
                                        event: "purchase",
                                        ecommerce: {
                                            transaction_id: Object.keys(res.data)[0],
                                            currency: "TRY",
                                            value: res.total_coupon_price,
                                            coupon: cartStore.couponCode,
                                            payment_type: !cartStore.pointUsage ? "Balance" : "ŞP",
                                            userData: {
                                                email_address: auth.member.email,
                                                phone_number: auth.member.mobile,
                                                address: {
                                                    first_name: auth.member.first_name,
                                                    last_name: auth.member.last_name,
                                                    //   city: 'menlopark',
                                                    //   region: 'ca',
                                                    //   postal_code: '94025',
                                                    //   country: 'usa',
                                                },
                                            },
                                            purchase_type: "{{Çekiliş}}",
                                            items: items,
                                        },
                                    });
                                }
                                catch (e) {
                                    console.log(e);
                                }
                            }
                            return res;
                        })
                            .catch(function (err) {
                            return { status: false, error: "timeout", message: err, data: null };
                        })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
