import { successLogStyle, warninLogStyle, wsFunctionTypes } from "../constants/global.constants";
import { Storage } from "./localstorege.service";
import { auth, authSubject, loginModalSubject } from "../store/auth.store";
import { toast } from "react-toastify";
import { homeSliderSubject, homeSlides, updateOrderSubject, updateTicketSubject } from "../store/misc.store";
import { creditCartSubject } from "../store/cart.store";
import { userLogout } from "./auth.service";
import { modalServiceSubject } from "./modal.service";
import { clearCart } from "./cart.service";
import { raffleDraws } from "../store/games.store";
import paths from "../paths";
export var openCreditCartModal = function () {
    setTimeout(function () {
        modalServiceSubject.next({
            creditCard: true,
        });
    }, 100);
};
var WebSocketService = /** @class */ (function () {
    function WebSocketService() {
    }
    WebSocketService.prototype.user = function (token) {
        if (token === void 0) { token = null; }
        if (token) {
            try {
                var _message = {
                    $type: 'setUser',
                    data: token,
                };
                this.wsHandler.send(JSON.stringify({ type: _message }));
            }
            catch (e) {
                // console.log('web-socket service send Message ', e)
            }
        }
    };
    WebSocketService.prototype.checkauth = function () {
        try {
            var _message = {
                $type: 'checkAuth',
            };
            this.wsHandler.send(JSON.stringify({ type: _message }));
        }
        catch (e) {
            // console.log('web-socket service send Message ', e)
        }
    };
    WebSocketService.prototype.connect = function (url) {
        var _this = this;
        this.wsUrl = url;
        this.wsHandler = new WebSocket(this.wsUrl);
        this.wsHandler.onopen = function () {
            console.log("%c* websocket servis connected", successLogStyle);
            _this.user(Storage.get('token'));
        };
        this.wsHandler.onmessage = function (e) {
            _this.handleMessage(JSON.parse(e.data));
        };
        this.wsHandler.onclose = function () {
            console.log("%c* websocket servis connection down", warninLogStyle);
            _this.wsHandler = null;
            setTimeout(function () {
                _this.connect(_this.wsUrl);
            }, 2000);
        };
        return this.wsHandler;
    };
    WebSocketService.prototype.handleMessage = function (message) {
        // console.log(message)
        if (wsFunctionTypes.includes(message.type)) {
            eval("this.".concat(message.type))(message.data || message);
        }
        else if (message.type == 'logoutAccount' || message.type == 'memberLogout' || message.type == 'accountDeclined') {
            userLogout();
        }
    };
    WebSocketService.prototype.checkAuth = function (data) {
        if (auth && auth.member && auth.member.id) {
            for (var _i = 0, _a = Object.entries(data); _i < _a.length; _i++) {
                var _b = _a[_i], k = _b[0], v = _b[1];
                if (k == 'mobile_validation') {
                    auth.mobileValidation = v;
                }
                auth.member[k] = v;
            }
            Storage.set('member', auth.member);
            authSubject.next({ action: 'checkAuth' });
        }
    };
    WebSocketService.prototype.newNotification = function (data) {
        toast.info(data.header);
        auth.member.unread_notification_count = data.total;
        authSubject.next({ action: 'newNotification' });
    };
    WebSocketService.prototype.updateSlide = function (data) {
        homeSlides.splice(0, homeSlides.length);
        homeSlides.push.apply(homeSlides, data);
        homeSliderSubject.next();
    };
    WebSocketService.prototype.accountApproved = function (data) {
        // console.log('accountApproved', data)
    };
    WebSocketService.prototype.updatePos = function (data) {
        // console.log('updatePos', data)
        creditCartSubject.next(data);
    };
    WebSocketService.prototype.balanceError = function (data) {
        // if (!window.location.pathname.includes('super-carkifelek')) {
        modalServiceSubject.next({
            case: "danger",
            title: "Bakiyeniz Yetersiz",
            width: "400px",
            content: "<p class=\"text-center\">Kredi Kart\u0131 ile \u00F6demeye devam edebilir veya hesab\u0131n\u0131za bakiye y\u00FCkleyebilirsiniz.</p>",
            confirm: {
                sure: {
                    label: "Kredi Kartı İle Öde",
                    class: "btn-warning w-100",
                    action: function () {
                        openCreditCartModal();
                    },
                },
                cancel: {
                    label: "Diğer Ödeme Yöntemleri",
                    class: "btn-info  w-100",
                    action: function () {
                        window.location.href = paths.deposit.url;
                    },
                },
            },
        });
        // }
    };
    WebSocketService.prototype.updateBalance = function (data) {
        auth.member.balance_point = data.balance_point;
        auth.member.balance_debit = data.balance_debit;
        auth.member.balance_point_available = data.balance_point_available;
        auth.member.balance_debit_available = data.balance_debit_available;
        Storage.set('member', auth.member);
        authSubject.next({ action: 'checkAuth' });
    };
    WebSocketService.prototype.deleteNotification = function (data) {
        try {
            auth.member.unread_notification_count = data.total;
            authSubject.next({ action: 'checkAuth' });
        }
        catch (e) {
            // console.log('web-socket deleteNotification ', e)
        }
    };
    WebSocketService.prototype.updateTicket = function (data) {
        updateTicketSubject.next(true);
    };
    WebSocketService.prototype.orderStatus = function (res) {
        updateOrderSubject.next(true);
        if (res.status === false && res.error === "re-login") {
            modalServiceSubject.next({
                case: "danger",
                width: "400px",
                title: "Oturumunuz Sonlandı",
                content: "<p class=\"text-center\">L\u00FCtfen tekrar giri\u015F yap\u0131n\u0131z</p>",
                confirm: {
                    sure: {
                        label: "Giriş Yap",
                        class: "btn-danger w-100",
                        action: function () {
                            loginModalSubject.next("show");
                        },
                    },
                    cancel: {
                        label: "İptal",
                        class: "btn-secondary w-100",
                    },
                },
            });
        }
        if (res.status === false && res.error === "timeout") {
            modalServiceSubject.next({
                case: "danger",
                width: "400px",
                title: "İşleminiz Zaman Aşımına Uğradı",
                content: "<p class=\"text-center\">Bu durum, ba\u015Far\u0131l\u0131 yada ba\u015Far\u0131s\u0131z anlam\u0131na gelmez. L\u00FCtfen bilet sat\u0131n alma durumunuzu biletlerim sayfas\u0131nda tekrar kontrol ediniz.</p>",
                confirm: {
                    sure: {
                        label: "Biletlerim Sayfasına Git",
                        class: "btn-secondary w-100",
                        action: function () {
                            window.location.href = paths.raffleTickets.url + "/biletlerim";
                        },
                    },
                    cancel: {
                        label: "Kapat",
                        class: "btn-secondary w-100",
                    },
                },
            });
        }
        if (res.status === false && res.error === "ticket_count_error") {
            modalServiceSubject.next({
                case: "danger",
                width: "400px",
                title: "Hata!",
                content: "<p class=\"text-center\">".concat(res.message, "</p>"),
                confirm: {
                    cancel: {
                        label: "Kapat",
                        class: "btn-secondary w-100",
                    },
                },
            });
        }
        if (res.status === true) {
            clearCart();
            modalServiceSubject.next({
                case: "success",
                title: "Ödemeniz Başarılı",
                width: "400px",
                content: "<p class=\"text-center\">\n                    Biletleriniz ba\u015Far\u0131yla sat\u0131n al\u0131nm\u0131\u015Ft\u0131r. Bilet numaralar\u0131n\u0131z\u0131 ve detaylar\u0131n\u0131 g\u00F6rmek i\u00E7in Biletlerim sayfas\u0131na gidebilirsiniz.\n                   </p>",
                confirm: {
                    sure: {
                        label: "Biletlerimi Gör",
                        class: "btn-success w-100",
                        action: function () {
                            window.location.href = paths.raffleTickets.url + "/biletlerim";
                        },
                    },
                    cancel: {
                        label: "Yeni Bilet Al",
                        class: "btn-secondary w-100",
                        action: function () {
                            // get current path
                            var currentPath = window.location.pathname;
                            if (currentPath == paths.raffleTickets.url) {
                                // if current path is raffle tickets, then we need to reload the page
                                window.location.reload();
                            }
                            else {
                                // if current path is not raffle tickets, then we need to navigate to raffle tickets
                                window.location.href = paths.raffleTickets.url;
                            }
                        },
                    },
                },
            });
        }
        if (res.status === false) {
            if (res.success_count > 0) {
                clearCart();
            }
            modalServiceSubject.next({
                case: res.error_count > 0 && res.success_count > 0
                    ? "warning"
                    : "danger",
                title: res.success_count === 0
                    ? "Biletler Alınamadı"
                    : "Bazı Biletler Alınamadı",
                width: "400px",
                content: "<p class=\"text-center\">\n                    Bilet al\u0131m i\u015Flemi ba\u015Far\u0131s\u0131z olan bilet tutar\u0131 e-\u015Eans hesab\u0131n\u0131za iade edildi.\n                   </p>\n                   <div class=\"prize-request mx-n10px px-4 rounded-4\" style=\"height: 300px; overflow: hidden; overflow-y: auto\">\n                   ".concat(Object.entries(res.data)
                    .map(function (_a, k) {
                    var d = _a[0], v = _a[1];
                    var dr = raffleDraws.find(function (el) { return el.no === Number(d); });
                    // let stat = v.data.success_count > 0 && v.data.error_count === 0 ? 'success': (v.data.success_count > 0 ? 'warning' : 'danger');
                    return "<div class=\"row item px-1 py-3 pb-0 rounded-2\">\n                        <div class=\"col-auto\">\n                            <img class=\"img-thumbnail\" src=\"".concat(dr.photo, "\" />\n                        </div>\n                        <div class=\"col-8\">\n                            <h3 class=\"nowrap\">").concat(dr.title_abbreviated, "</h3>\n                            ").concat(v.data.success_count > 0
                        ? "<span class=\"d-block text-success fw-bold\">Ba\u015Far\u0131l\u0131 al\u0131nan bilet: ".concat(v.data.success_count, " adet</span>")
                        : "", "\n                            ").concat(v.data.error_count > 0
                        ? "<span class=\"d-block text-danger fw-bold\">Hatal\u0131 bilet: ".concat(v.data.error_count, " adet</span><span class=\"d-block text-danger\">").concat(v.data.message, "</span>")
                        : "", "\n                        </div>\n                      </div>");
                })
                    .join(""), " </div>"),
                confirm: {
                    sure: res.success_count > 0
                        ? {
                            label: "Biletlerimi Gör",
                            class: "btn-warning w-100",
                            action: function () {
                                window.location.href = paths.raffleTickets.url + "/biletlerim";
                            },
                        }
                        : null,
                    cancel: res.success_count === 0
                        ? {
                            label: "Tamam",
                            class: "btn-danger  w-100",
                        }
                        : {
                            label: "Yeni Bilet Al",
                            class: "btn-default  w-100",
                            action: function () {
                                // get current path
                                var currentPath = window.location.pathname;
                                if (currentPath == paths.raffleTickets.url) {
                                    // if current path is raffle tickets, then we need to reload the page
                                    window.location.reload();
                                }
                                else {
                                    // if current path is not raffle tickets, then we need to navigate to raffle tickets
                                    window.location.href = paths.raffleTickets.url;
                                }
                            },
                        },
                },
            });
        }
    };
    return WebSocketService;
}());
export { WebSocketService };
export var wssProvider = new WebSocketService();
