var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Storage } from "../../services/localstorege.service";
import { api } from "../../services/http.service";
import { env } from "../../constants/global.constants";
import _ from "lodash";
import { useEffect, useState } from "react";
import { gameNavigationMiddleware, useForceUpdate } from "../../services/misc.functions";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import { isMobile } from "../../App";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useNavigate } from "react-router";
import { auth, authSubject } from "../../store/auth.store";
export function HomeGamesWidget() {
    var _this = this;
    var _a = useState([]), gamesList = _a[0], setGamesList = _a[1];
    var _b = useState(0), gamesGroup = _b[0], setGamesGroup = _b[1];
    var navigate = useNavigate();
    var forceUpdate = useForceUpdate();
    useEffect(function () {
        getGames().then(function (res) {
            if (gamesGroup === 0) {
                setGamesList(res);
            }
            if (gamesGroup === 1) {
                setGamesList(res.filter(function (game) {
                    return game.is_active === true;
                }));
            }
            if (gamesGroup === 2) {
                setGamesList(res.filter(function (game) {
                    return game.is_active === false;
                }));
            }
        });
    }, [gamesGroup]);
    useEffect(function () {
        var auth_subscriber = authSubject.subscribe(function (res) {
            forceUpdate();
        });
        return function () {
            auth_subscriber.unsubscribe();
        };
    }, []);
    var getGames = function () { return __awaiter(_this, void 0, void 0, function () {
        var games;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    games = Storage.get("games");
                    if (games) {
                        return [2 /*return*/, games];
                    }
                    return [4 /*yield*/, api.start("get", env.accounting_api + "/games/api/list-game/", null, false).then(function (res) {
                            if (res && res.status) {
                                var d = _.orderBy(res.data, ["ordering"], "asc");
                                Storage.set("games", d, 60 * 60 * 5);
                                return d;
                            }
                            else {
                                return [];
                            }
                            return [];
                        })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    }); };
    return (_jsx("div", __assign({ className: "games-slider-container" }, { children: _jsxs("div", __assign({ className: "home-games-slider" }, { children: [_jsx("div", { className: "clearfix" }), _jsxs("div", __assign({ className: "slider-container" }, { children: [_jsx("h2", __assign({ className: "home-heading d-none d-xl-block d-lg-block ps-3" }, { children: "Oyunlar" })), _jsx("hr", { className: "d-none d-xl-block d-lg-block" }), !isMobile && (_jsxs("div", __assign({ className: "home-game-tabs" }, { children: [_jsx("h2", __assign({ className: "pointer ".concat(gamesGroup === 0 && "active", " "), onClick: function () {
                                        setGamesGroup(0);
                                    } }, { children: "\uD83C\uDFAE Dijital Oyunlar" })), _jsx("h2", __assign({ className: "pointer ".concat(gamesGroup === 1 && "active", " "), onClick: function () {
                                        setGamesGroup(1);
                                    } }, { children: "\u2B50 Yeni Oyunlar" })), _jsx("h2", __assign({ className: "pointer ".concat(gamesGroup === 2 && "active", " "), onClick: function () {
                                        setGamesGroup(2);
                                    } }, { children: "\u23F3 Gelecek Oyunlar" }))] }))), _jsx(Swiper, __assign({ spaceBetween: 0, breakpoints: {
                                0: {
                                    slidesPerView: 3,
                                    spaceBetween: 0,
                                },
                                948: {
                                    slidesPerView: 4,
                                    spaceBetween: 0,
                                },
                                1400: {
                                    slidesPerView: 4,
                                    spaceBetween: 0,
                                },
                            }, navigation: true, autoplay: { delay: 3000 }, modules: [Autoplay, Navigation] }, { children: gamesList.map(function (game, key) {
                                var _a, _b;
                                return (_jsx(SwiperSlide, { children: _jsxs("div", __assign({ className: "game-widget pointer" }, { children: [_jsx("img", { src: env.cdn_host + "/media/" + game.image, onClick: function () {
                                                    gameNavigationMiddleware(game, navigate);
                                                } }), game.is_active && isMobile && (_jsx("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", width: "20", height: "20", fill: "#02d54a", className: "game-play-icon bi bi-play-circle-fill", viewBox: "0 0 16 16" }, { children: _jsx("path", { d: "M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814z" }) }))), !game.is_active && isMobile && (_jsx("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", width: "20", height: "20", fill: "rgb(236, 188, 42)", className: "game-play-icon bi bi-hourglass-split", viewBox: "0 0 16 16" }, { children: _jsx("path", { d: "M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z" }) }))), !isMobile && (_jsx("div", __assign({ className: "".concat(game.is_active === true ? "playable" : "", " ").concat(game.is_active === false && ((_a = auth.member) === null || _a === void 0 ? void 0 : _a.id) && auth.remaining_games.includes(game.id)
                                                    ? "remanded"
                                                    : "", " body  mt-2") }, { children: _jsxs("span", __assign({ className: "label text-center", onClick: function () {
                                                        gameNavigationMiddleware(game, navigate);
                                                    } }, { children: [game.is_active === true && _jsx(_Fragment, { children: "Oyna" }), game.is_active !== true && game.reminer !== true && (_jsx(_Fragment, { children: ((_b = auth.member) === null || _b === void 0 ? void 0 : _b.id) && auth.remaining_games.includes(game.id)
                                                                ? "✅ Hatırlatıcı kuruldu"
                                                                : "⏰ Hatırlat" }))] })) })))] })) }, "home-games-widget-".concat(key)));
                                // "id": 3,
                                // "name": "Oyna Kazan",
                                // "ordering": 1,
                                // "create_date": "2023-06-02T11:02:33.939",
                                // "image": "misc_games/oyun-logolar_fhWAUhK.png",
                                // "status": true,
                                // "is_active": true
                            }) }))] }))] })) })));
}
