import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import "./turbo-mines-card.css";
import { api } from "../../../services/http.service";
import { env } from "../../../constants/global.constants";
import { auth, authSubject } from "../../../store/auth.store";
import { GameInfo } from "../game.info";
import { gtmViewItem, useForceUpdate } from "../../../services/misc.functions";
import { GameLogin } from "../game.login";
function TurboMinesCard() {
    var turboMinesIframeRef = useRef(null);
    var _a = useState(null), config = _a[0], setConfig = _a[1];
    var forceUpdate = useForceUpdate();
    useEffect(function () {
        var auth_subscriber = authSubject.subscribe(function () {
            forceUpdate();
        });
        return function () {
            auth_subscriber.unsubscribe();
        };
    }, []);
    useEffect(function () {
        if (auth.authentication && auth.member && auth.member.id) {
            gtmViewItem([{ code: "9050", name: "Işıltılı Taşlar" }], "Oyunlar");
            var script = document.createElement("script");
            script.src = "https://static.aleaplay.com/js/launch/bundle.js";
            script.async = true;
            document.head.appendChild(script);
            script.onload = function () {
                api.start("get", env.accounting_api + "/games/aleaplay/api/get_member_token/", null, true).then(function (res) {
                    if (res && res.status) {
                        var turboMinesConfig = {
                            environmentCredential: res.data.environmentCredential,
                            signature: res.data.signature,
                            container: "#turboMinesIframeContainer",
                            casinoId: "1322",
                            casinoPlayerId: auth.member.id,
                            casinoSessionId: res.data.casinoSessionId,
                            gameId: "9050",
                            country: "TR",
                            currency: "TRY",
                            locale: "tr-TR",
                            lobbyUrl: "https://e-sans.com.tr",
                            // isTest: true,
                        };
                        // @ts-ignore
                        setConfig(turboMinesConfig);
                    }
                });
            };
        }
    }, [auth === null || auth === void 0 ? void 0 : auth.authentication]);
    useEffect(function () {
        if (config) {
            // @ts-ignore
            var gameLauncher = new APGameLauncher(config);
            gameLauncher.play();
        }
    }, [config]);
    return (_jsxs("div", { children: [auth.authentication && auth.member && auth.member.id ? (_jsx("div", { id: "turboMinesIframeContainer", ref: turboMinesIframeRef })) : (_jsx(GameLogin, {})), _jsx(GameInfo, { code: "9050" })] }));
}
export default TurboMinesCard;
