var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { raffleCategories, raffleDraws, raffleDrawSubject, raffleSubject } from "../store/games.store";
import { api } from "./http.service";
import { env } from "../constants/global.constants";
import { Raffle } from "../models/game.model";
import { recoveredCartItems } from "../App";
import { cartStore } from "../store/cart.store";
import { Storage } from "./localstorege.service";
import _ from "lodash";
import { gtmSelectItem } from "./misc.functions";
export function getRaffleDraws() {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.start('post', env.accounting_api + '/somos/api/draw/', {}, false)
                        .then(function (res) {
                        raffleDraws.splice(0, raffleDraws.length);
                        var _loop_1 = function (item) {
                            if (recoveredCartItems.includes(item.id)) {
                                _index = cartStore.items.findIndex(function (el) { return el.item.id === item.id; });
                                if (_index > -1) {
                                    item.is_added = true;
                                    item.cartIndex = _index;
                                }
                                else {
                                    item.is_added = false;
                                    item.cartIndex = null;
                                }
                            }
                            else {
                                item.is_added = false;
                                item.cartIndex = null;
                            }
                            raffleDraws.push(new Raffle(item));
                        };
                        var _index;
                        for (var _i = 0, _a = res.data; _i < _a.length; _i++) {
                            var item = _a[_i];
                            _loop_1(item);
                        }
                        raffleSubject.next({ action: 'loaded' });
                        return res;
                    })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
export function openRaffleDetail(raffle) {
    gtmSelectItem([raffle], 'Çekiliş');
    raffleDrawSubject.next({ action: 'open', id: raffle.id });
}
export function openRaffleDetailById(id) {
    raffleDrawSubject.next({ action: 'open', id: id });
}
export function getRaffleCategories() {
    return __awaiter(this, void 0, void 0, function () {
        var categories;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    categories = Storage.get('raffleCategories');
                    if (!!categories) return [3 /*break*/, 2];
                    return [4 /*yield*/, api.start('get', env.accounting_api + '/somos/api/draw-categories/', null, false)
                            .then(function (res) {
                            if (res && res.status) {
                                raffleCategories.splice(0, raffleCategories.length);
                                raffleCategories.push.apply(raffleCategories, _.orderBy(res.data, ['sort'], ['asc']));
                                Storage.set('raffleCategories', raffleCategories, 3600);
                            }
                            return [];
                        }).catch(function (err) {
                            // fixme log error
                            // console.log(err)
                            return [];
                        })];
                case 1: return [2 /*return*/, _a.sent()];
                case 2:
                    raffleCategories.splice(0, categories.length);
                    raffleCategories.push.apply(raffleCategories, categories);
                    return [2 /*return*/, []];
            }
        });
    });
}
