var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate, useParams } from "react-router";
import { agreements } from "../../constants/global.constants";
import { useEffect } from "react";
import { agreementSubject } from "../../store/misc.store";
import { useForceUpdate } from "../../services/misc.functions";
import "./agreement.css";
import paths from "../../paths";
export function Agreement() {
    var param = useParams();
    var navigate = useNavigate();
    var forceUpdate = useForceUpdate();
    useEffect(function () {
        var agreementSubject_subsciber = agreementSubject.subscribe(function () {
            forceUpdate();
        });
        return function () {
            agreementSubject_subsciber.unsubscribe();
        };
    }, []);
    if (!param.slug || !agreements[param.slug]) {
        setTimeout(function () {
            navigate(paths.home.url);
        }, 100);
        return _jsx(_Fragment, {});
    }
    var content = agreements[param.slug];
    return (_jsxs("div", __assign({ className: "agreement-page overflow-scroll py-3 w-100" }, { children: [_jsx("h1", { children: content.title }), _jsx("p", { dangerouslySetInnerHTML: { __html: content.content.replaceAll('\n\r\n', '<br/>') } })] })));
}
